var material = {
	data() {
		return {
			listMaterials: [],
			loadingMaterials: false,
			paginationListMaterials: [],
			objectMaterial: {
				exists: 0,
				id: 0,
				name: '',
				description: '',
				material_type_id: null,
				material_type: null,
				material_type_classification_id: null,
				material_type_classification_: null,
				material_type_sub_classification_id: null,
				material_type_sub_classification: null,
				width: null,
				height: null,
				thickness: null,
				measurement_unit_id: null,
				measurement_unit: null,
				account_Code: null,
				account: null,
				sub_account_Code: null,
				sub_account: null,
				created_at: null,
				updated_at: null
			},
			searchMaterials: '',
			searchingMaterial: false,
			savingMaterial: false
		}
	},
	methods: {
		addItemListMaterials(m) {
			this.listMaterials.push(m)
		},
		clearObjectMaterial() {
			this.objectMaterial.exists = 0
			this.objectMaterial.id = 0
			this.objectMaterial.name = ''
			this.objectMaterial.description = ''
			this.objectMaterial.material_type_id = null
			this.objectMaterial.material_type = null
			this.objectMaterial.material_type_classification_id = null
			this.objectMaterial.material_type_classification = null
			this.objectMaterial.material_type_sub_classification_id = null
			this.objectMaterial.material_type_sub_classification = null
			this.objectMaterial.width = null
			this.objectMaterial.height = null
			this.objectMaterial.thickness = null
			this.objectMaterial.measurement_unit_id = null
			this.objectMaterial.measurement_unit = null
			this.objectMaterial.account_Code = null
			this.objectMaterial.account = null
			this.objectMaterial.sub_account_Code = null
			this.objectMaterial.sub_account = null
			this.objectMaterial.created_at = null
			this.objectMaterial.updated_at = null
		},
		getMaterials(page = 1) {
			this.loadingMaterials = true
			let param = '?page=' + page + '&search=' + this.searchMaterials
			let url = this.getApiBackendNew + 'materials' + param
			this.axiosActionIS(url, 'GET', 'getMaterialsResult')
		},
		getMaterialsResult(response) {
			this.loadingMaterials = false
			if (response.data.success == true) {
				this.listMaterials = response.data.data
				this.paginationListMaterials = response.data.paginate
			}
			this.getMaterialsResultLocal(response)
		},
		getMaterialsResultLocal(response) {
			console.log('getMaterialsResultLocal no desarrollado', response)
		},
		setItemListMaterials(m) {
			let i = this.listMaterials.map(lm => parseInt(lm.id)).indexOf(parseInt(m.id))
			if (i > -1) {
				for (const property in this.listMaterials[i]) {
					this.listMaterials[i][property] = m[property]
				}
			}
		},
		setObjectMaterial(m) {
			this.clearObjectMaterial()
			for (const property in m) {
				this.objectMaterial[property] = m[property]
			}
		},
		saveMaterial(m) {
			this.savingMaterial = true
			let method = (m.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'material/'
			url += (method == 'POST') ? 'register' : m.id + '/update'
			console.log(url, m)
			this.axiosActionIS(url, method, 'saveMaterialResult', m)
		},
		saveMaterialResult(response) {
			this.savingMaterial = false
			if (response.data.success == true) {
				this.setObjectMaterial(response.data.data)
				if (response.data.message == 'UPDATED') {
					this.setItemListMaterials(response.data.data)
				}
			}
			this.saveMaterialResultLocal(response)
		},
		saveMaterialResultLocal(response) {
			console.log('saveMaterialResultLocal no desarrollado', response)
		},
		showMaterial(id, resource = 'default') {
			this.searchingMaterial = true
			let param = '?resource=' + resource
			let url = this.getApiBackendNew + 'material/' + id + param
			this.axiosActionIS(url, 'GET', 'showMaterialResult')
		},
		showMaterialResult(response) {
			this.searchingMaterial = false
			if (response.data.success == true) {
				this.setObjectMaterial(response.data.data)
			}
			this.showMaterialResultLocal(response)
		},
		showMaterialResultLocal(response) {
			console.log('showMaterialResultLocal no desarrollado', response)
		},
	},
};

export default material;