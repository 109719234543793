<template>
	<div id="PurchaseOrderListComponent">
		<mensagge-loading-component class="bg-aasinet text-white" :text="messageLoading">
		</mensagge-loading-component>

		<div v-show="purchaseOrderIdSelected == -1" class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Ordenes de Compras</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchPurchaseOrders"
										v-model="searchPurchaseOrders" @focus="$event.target.select()">
									<div class="input-group-text bg-transparent pointer"
										@click="getPurchaseOrdersLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showPurchaseOrderLocal(0)" ref="btnShowPurchaseOrder">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nueva</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900">Proveedor</th>
								<th class="text-900 text-center">Contacto</th>
								<th class="text-900 text-center">Departamento</th>
								<th class="text-900 text-center">Total</th>
								<th class="text-900 text-center">Estado</th>
								<th class="text-900 text-center"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(po, i) in listPurchaseOrdersFilter" :key="i">
								<td class="align-middle white-space-nowrap border-bottom border-muted">{{ po.id }}</td>

								<td class="align-middle white-space-nowrap border-bottom border-muted">{{
									po.supplier.name }}</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<span>{{ po.contact }}</span>
									<br />
									<small class="text-muted">
										<i class="far fa-envelope text-secondary"></i>
										<a :href="'mailto:' + po.email" class="text-muted ms-1">{{ po.email }}</a>
										|<i class="fas fa-phone-alt ms-1 text-secondary"></i>
										<span class="ms-1">{{ po.phone }}</span>
									</small>
								</td>
								<td class="text-center align-middle white-space-nowrap border-bottom border-muted">{{
									po.departament.name }}</td>
								<td class="text-end align-middle white-space-nowrap border-bottom border-muted">{{
									methodFormatNumber(po.total) }}</td>
								<td class="text-center align-middle white-space-nowrap border-bottom border-muted">{{
									po.status.name }}</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListPurchaseOrders" @getAllList="getPurchaseOrdersLocal">
					</pagination>
				</div>
			</div>
		</div>

		<div v-show="purchaseOrderIdSelected > -1">
			<purchase-order-component ref="purchaseOrderComponent" :id="purchaseOrderIdSelected"
				@savePurchaseOrderResult="savePurchaseOrderResultLocal"></purchase-order-component>
			<hr>
		</div>

		<div v-show="purchaseOrderIdSelected > -1" class="text-end fs--1 pe-2">
			<span class="text-primary pointer" @click="purchaseOrderIdSelected = -1">Volver a la lista</span>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import purchaseOrder from '@/mixins/purchaseOrders'

import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import Pagination from '@/components/Pagination.vue'
import PurchaseOrderComponent from '@/components/PurchaseOrderComponent.vue'

export default {
	name: 'PurchaseOrderListComponent',
	mixins: [mixin, axiosAction, errors, purchaseOrder],
	props: [],
	data() {
		return {
			purchaseOrderIdSelected: -1,
			messageLoading: ''
		}
	},
	components: {
		'mensagge-loading-component': MessageLoadingComponent,
		'pagination': Pagination,
		'purchase-order-component': PurchaseOrderComponent
	},
	computed: {
		listPurchaseOrdersFilter() {
			return this.listPurchaseOrders
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getPurchaseOrdersResult': this.getPurchaseOrdersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getPurchaseOrdersLocal(page = 1) {
			this.messageLoading = 'Buscando Ordenes de Compras . . .'
			this.getPurchaseOrders(page)
		},
		getPurchaseOrdersResultLocal(response) {
			if (response.data.success == true) {
				this.messageLoading = 'Ordenes recuperadas!!!'
			} else {
				this.messageLoading = 'Ha ocurrido un error!'
			}
			setTimeout(() => { this.messageLoading = '' }, 1000)
		},
		initModuleLocal() {
			this.setPurchaseOrderInit()
		},
		savePurchaseOrderResultLocal(po) {
			if (po != null) {
				this.addItemListPurchaseOrders(po)
				this.purchaseOrderIdSelected = -1
			}
		},
		setPurchaseOrderInit() {
			this.getPurchaseOrdersLocal(1)
		},
		showMsgErrorHandlingLocal() {
			this.messageLoading = 'No se puede acceder a lo que solicita.'
			setTimeout(() => { this.messageLoading = '' }, 1000)
		},
		showPurchaseOrderLocal(id) {
			this.purchaseOrderIdSelected = id
			setTimeout(() => { this.$refs.purchaseOrderComponent.$refs.btnShowPurchaseOrder.click() }, 50)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>