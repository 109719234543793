var storeRack = {
	data() {
		return {
			listStoreRacks: [],
			paginationStoreRacks: [],
			loadingStoreRacks: false
		}
	},
	methods: {
		getStoreRacksByStoreId(storeId) {
			this.loadingStoreRacks = true
			this.listStoreRacks = []
			this.paginationStoreRacks = []
			let url = this.getApiBackendNew + 'store/' + storeId + '/racks'
			this.axiosActionIS(url, 'GET', 'getStoreRacksResult')
		},
		getStoreRacksResult(response) {
			this.loadingStoreRacks = false
			if (response.data.success == true) {
				this.listStoreRacks = response.data.data
				this.paginationStoreRacks = response.data.paginate
			}
			this.getStoreRacksResultLocal(response)
		},
		getStoreRacksResultLocal(response) {
			console.log('getStoresResultLocal no desarrollado', response)
		}
	},
};

export default storeRack;