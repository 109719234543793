<template>
	<div id="messageLoadingComponent">
		<div class="flex-center"
			style="position: fixed; top: 0; z-index: 10100; left: 0px; width: 100%; min-height: 100vh;"
			v-show="mensaggeLoadingVisible == true">
			<div style="position: absolute; width: 100%; opacity: 0.9; min-height: 100vh;" :class="classBgTheme">
			</div>
			<div class="row flex-center min-vh-100 py-6 text-center">
				<div class="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
					<div class="d-flex justify-content-center py-2">
						<div class="card" v-show="listTasksLocal.length > 0">
							<div class="card-body">
								<div class="mb-2">
									<span>{{ textLocal }}</span>
								</div>
								<div class="text-start fs--1">
									<ul class="list-style-none px-0">
										<li v-for="(t, i) in listTasksLocal" :key="i"
											:class="(t.currentStatus == 0) ? 'text-300' : 'text-dark'" class="mb-2">
											<span>
												<span v-show="(t.currentStatus == 1 || t.currentStatus == 0)">
													{{ t.action.text }}
												</span>
												<span v-show="(t.currentStatus == 2)">{{ t.success.text }}</span>
												<span v-show="(t.currentStatus == -1)">{{ t.error.text }}</span>
											</span>
											<span class="float-end ms-5">
												<span v-show="t.currentStatus == 1">
													<div class="d-flex">
														<span class="fa-spin">
															<i :class="t.action.icon"></i>
														</span>
													</div>
												</span>
												<span v-show="t.currentStatus == 2">
													<i :class="t.success.icon"></i>
												</span>
												<span v-show="t.currentStatus == -1">
													<i :class="t.error.icon"></i>
												</span>
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="d-flex" style="z-index: 10101" :class="classTextTheme"
							v-if="listTasksLocal.length == 0">
							<span class="fa-spin">
								<i class="fas fa-spinner"></i>
								<i class="fas fa-circle-notch" v-show="false"></i>
							</span>
							<span class="ms-1">{{ text }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

export default {
	name: 'MessageLoadingComponent',
	mixins: [mixin, axiosAction, errors],
	props: ['class_', 'text', 'listTasks'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		mensaggeLoadingVisible() {
			if (this.listTasks != undefined) {
				if (this.listTasks != undefined && this.listTasksLocal.length == this.listTasks.length) {
					let count = 0;
					this.listTasks.forEach(t => {
						if (t.currentStatus == 2) {
							count++
						}
					})
					return count < this.listTasksLocal.length
				}
			}
			return this.text != undefined && this.text != ''
		},
		textLocal() {
			let t = 'Se están realizando las siguientes tareas:'
			if (this.text != undefined && this.text != '') {
				t = this.text
			}
			return t
		},
		classBgTheme() {
			let bg = (localStorage.getItem('theme') == 'dark') ? 'bg-100' : 'bg-200'
			return bg
		},
		classTextTheme() {
			let text = (localStorage.getItem('theme') == 'dark') ? 'text-secondary' : 'text-secondary'
			console.log(text)
			return text
		},
		listTasksLocal() {
			let l = []
			if (this.listTasks != undefined) {
				if (typeof this.listTasks == 'object') {
					this.listTasks.forEach((t, i) => {
						l.push({
							action: {
								text: (typeof t.action.text == 'string') ? t.action.text : 'Realizando acción ' + (i + 1),
								icon: (typeof t.action.icon == 'string') ? t.action.icon : 'fas fa-spinner',
								status: 1
							},
							success: {
								text: (typeof t.success.text == 'string') ? t.success.text : 'Realizando acción ' + (i + 1),
								icon: (typeof t.success.icon == 'string') ? t.success.icon : 'text-success fas fa-check',
								status: 2
							},
							error: {
								text: (typeof t.error.text == 'string') ? t.error.text : 'Realizando acción ' + (i + 1),
								icon: (typeof t.error.icon == 'string') ? t.error.icon : 'text-danger fas fa-exclamation-triangle',
								status: -1
							},
							currentStatus: (typeof t.currentStatus == 'number') ? t.currentStatus : 1
						})
					});
				}
			}
			return l
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setMessageLoadingComponentInit()
		},
		setMessageLoadingComponentInit() {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>