<template>
	<div id="juournalListComponent">
		<div v-show="loadingJournal == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Asientos">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Asientos</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="Buscar solicitudes" aria-label="search" ref="searchShippings"
										@focus="$event.target.select()">
									<div class="input-group-text bg-transparent pointer" @click="getJournalsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showJournalLocal(0)" ref="btnShowShippingComponent">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900">Descripción</th>
								<th class="text-900 text-center">Fecha</th>
								<th class="text-900 text-center">Periodo</th>
								<th class="text-900 text-center">Creado</th>
								<th class="text-900 text-center text-aasinet">
									Aasinet
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(j, i) in listJournalsFilter" :key="i">
								<td class="text-center">
									<span class="pointer" @click="showJournalLocal(j.id)">{{ j.id }}</span>
								</td>
								<td>{{ j.Description }}</td>
								<td class="text-center">{{ j.JournalDate }}</td>
								<td class="text-center">{{ j.PostedPeriod }}</td>
								<td class="text-center">{{ j.created_at }}</td>
								<td class="text-center">{{ j.JournalCode }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListJournals" @getAllList="getJournalsLocal">
					</pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import journal from '@/mixins/accounting/journal.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'JuournalListComponent',
	mixins: [mixin, axiosAction, errors, journal],
	props: [],
	data() {
		return {
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
		listJournalsFilter() {
			return this.listJournals
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getJournalsResult': this.getJournalsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setJuournalListComponentInit()
		},
		getJournalsLocal(page = 1) {
			this.getJournals(page)
		},
		getJournalsResultLocal(response) {
		},
		setJuournalListComponentInit() {
			this.getJournalsLocal()
		},
		showJournalLocal(id) {
			this.$emit('showJournal', id)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>