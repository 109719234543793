<template>
	<div id="receiptListComponent">
		<mensagge-loading-component class="bg-aasinet text-white" :text="messageLoading">
		</mensagge-loading-component>

		<div v-show="receiptIdSelected == -1" class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Recepciones de Compras</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchReceipts" v-model="searchReceipts"
										@focus="$event.target.select()" @keyup.enter="getReceiptsLocal(1)">
									<div class="input-group-text bg-transparent pointer" @click="getReceiptsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showReceiptLocal(0)" ref="btnShowReceipt">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nueva</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900">Documento</th>
								<th class="text-900">Proveedor</th>
								<th class="text-900">Contacto</th>
								<th class="text-900 text-end">Total</th>
								<th class="text-900 text-center">Estado</th>
								<th class="text-900 text-center"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(r, i) in listReceiptsFilter" :key="i">
								<td class="align-middle white-space-nowrap border-bottom border-muted text-center">
									{{ r.id }}
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									{{ r.legal_document_number }} -
									<span v-if="r.type_legal_document != null">
										{{ r.type_legal_document.name }}
									</span>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									{{ r.supplier.name }}
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<span>{{ r.supplier.contact }}</span>
									<br />
									<small class="text-muted">
										<i class="far fa-envelope text-secondary"></i>
										<a :href="'mailto:' + r.supplier.email" class="text-muted ms-1">{{
											r.supplier.email }}</a>
										|<i class="fas fa-phone-alt ms-1 text-secondary"></i>
										<span class="ms-1">{{ r.supplier.phone }}</span>
									</small>
								</td>
								<td class="text-end align-middle white-space-nowrap border-bottom border-muted">
									{{ methodFormatNumber(r.total) }}
								</td>
								<td class="text-center align-middle white-space-nowrap border-bottom border-muted">
									<span class="badge badge rounded-pill d-block"
										:class="'badge-soft-' + r.status.color">{{ r.status.name }}</span>
								</td>
								<td class="text-end align-middle white-space-nowrap border-bottom border-muted">
									<span class="">
										<span @click="deleteReceiptLocal(r.id)" class="pointer" title="Eliminar">
											<i class="far fa-trash-alt text-danger"></i>
										</span>
										<span @click="showReceiptLocal(r.id)" class="ms-1 pointer" title="Editar">
											<i class="far fa-eye text-primary"></i>
										</span>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListReceipts" @getAllList="getReceiptsLocal">
					</pagination>
				</div>
			</div>
		</div>

		<div v-show="receiptIdSelected > -1">
			<receipt-component ref="receiptComponent" :id="receiptIdSelected"
				@saveReceiptResult="saveReceiptResultLocal"></receipt-component>
			<hr>
		</div>

		<div v-show="receiptIdSelected > -1" class="text-end fs--1 pe-2">
			<span class="text-primary pointer" @click="receiptIdSelected = -1">Volver a la lista</span>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import receipts from '@/mixins/receipts'

import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import Pagination from '@/components/Pagination.vue'
import ReceiptComponent from '@/components/ReceiptComponent.vue'

export default {
	name: 'ReceiptListComponent',
	mixins: [mixin, axiosAction, errors, receipts],
	props: [],
	data() {
		return {
			receiptIdSelected: -1,
			messageLoading: ''
		}
	},
	components: {
		'mensagge-loading-component': MessageLoadingComponent,
		'pagination': Pagination,
		'receipt-component': ReceiptComponent
	},
	computed: {
		listReceiptsFilter() {
			return this.listReceipts
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getReceiptsResult': this.getReceiptsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		deleteReceiptLocal(id) {

		},
		getReceiptsLocal(page = 1) {
			this.messageLoading = 'Buscando Recepciones de Compras . . .'
			this.getReceipts(page)
		},
		getReceiptsResultLocal(response) {
			if (response.data.success == true) {
				this.messageLoading = 'Ordenes recuperadas!!!'
			} else {
				this.messageLoading = 'Ha ocurrido un error!'
			}
			setTimeout(() => { this.messageLoading = '' }, 1000)
		},
		initModuleLocal() {
			this.setReceiptInit()
		},
		saveReceiptResultLocal(po) {
			if (po != null) {
				this.addItemListReceipts(po)
				this.receiptIdSelected = -1
			}
		},
		setReceiptInit() {
			this.getReceiptsLocal(1)
		},
		showMsgErrorHandlingLocal() {
			this.messageLoading = 'No se puede acceder a lo que solicita.'
			setTimeout(() => { this.messageLoading = '' }, 1000)
		},
		showReceiptLocal(id) {
			this.receiptIdSelected = id
			setTimeout(() => { this.$refs.receiptComponent.$refs.btnShowReceipt.click() }, 50)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>