var inventoryTemplateEmployee = {
	data() {
		return {
			loadingInventoryTemplateEmployees: false,
			listInventoryTemplateEmployees: [],
			paginationListInventoryTemplateEmployees: [],
			savingInventoryTemplateEmployee: false,
			searchInventoryTemplateEmployee: '',
			searchingInventoryTemplateEmployee: false
		}
	},
	methods: {
		getInventoryTemplateEmployees(inventoryId, page = 1, resource = 'List') {
			this.listInventoryTemplateEmployees = []
			this.paginationListInventoryTemplateEmployees = []
			this.loadingInventoryTemplateEmployees = true
			let param = '?page=' + page + '&search=' + this.searchInventoryTemplateEmployee + '&resource=' + resource
			let url = this.getApiBackendNew + 'inventory/' + inventoryId + '/template/details' + param
			this.axiosActionIS(url, 'GET', 'getInventoryTemplateEmployeesResult')
		},
		getInventoryTemplateEmployeesResult(response) {
			this.loadingInventoryTemplateEmployees = false
			if (response.data.success == true) {
				this.listInventoryTemplateEmployees = response.data.data
				this.paginationListInventoryTemplateEmployees = response.data.paginate
			}
			this.getInventoryTemplateEmployeesResultLocal(response)
		},
		getInventoryTemplateEmployeesResultLocal(response) {
			console.log('getInventoryTemplateEmployeesResultLocal no desarrollado', response)
		},
		saveInventoryTemplateEmployee(ite) {
			this.savingInventoryTemplate = true
			let method = (ite.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'inventoryTemplate/' + ite.inventory_template_id
			url += (method == 'POST') ? '/employee/register' : ite.id + '/update'
			this.axiosActionIS(url, method, 'saveInventoryTemplateEmployeeResult', ite)
		},
		saveInventoryTemplateEmployeeResult(response) {
			this.saveInventoryTemplateEmployeeResultLocal(response)
		},
		saveInventoryTemplateEmployeeResultLocal(response) {
			console.log('saveInventoryTemplateEmployeeResultLocal no desarrollado', response)
		}
	},
};

export default inventoryTemplateEmployee;