var receipt = {
	data() {
		return {
			listReceipts: [],
			listSearchItemInTable: [],
			loadingReports: false,
			objectReceipt: {
				exists: 0,
				id: 0,
				date_received: null,
				supplier_id: null,
				supplier: {
					id: 0,
					name: '',
					document_number: ''
				},
				total: 0,
				type_legal_document_id: null,
				type_legal_document: null,
				date_legal_document: null,
				legal_document_number: null,
				note: null,
				status_id: null,
				status: null,
				created_at: null,
				updated_at: null,
				items: []
			},
			paginationListReceipts: [],
			savingReceipt: false,
			searchingReceipt: false,
			searchReceipts: '',
			searchingItemInTable: false
		}
	},
	methods: {
		addItemListReceipts(po) {
			this.listReceipts.unshift(po)
		},
		addItemEmptyObjectReceiptsItems() {
			let receptionRypeId = null
			if (this.objectReceipt.items.length > 0) {
				receptionRypeId = this.objectReceipt.items[this.objectReceipt.items.length - 1].reception_type_id
			}
			let item = {
				exists: 0,
				id: 0,
				receipt_id: 0,
				reception_type_id: receptionRypeId,
				reception_type: null,
				primary_key_id: null,
				primary_key_name: '',
				quantity: '',
				price: '',
				tax_percentage: null,
				tax_amount: null,
				created_at: null,
				updated_at: null
			}
			this.objectReceipt.items.push(item)
			this.addItemEmptyObjectReceiptsItemsLocal()
		},
		addItemEmptyObjectReceiptsItemsLocal() {
			console.log('addItemEmptyObjectReceiptsItemsLocal no desarrollado')
		},
		clearObjectReceipt() {
			this.objectReceipt.exists = 0
			this.objectReceipt.id = 0
			this.objectReceipt.date_received = this.currentDate()
			this.objectReceipt.supplier_id = null
			this.objectReceipt.supplier.id = 0
			this.objectReceipt.supplier.name = ''
			this.objectReceipt.supplier.document_number = ''
			this.objectReceipt.total = 0
			this.objectReceipt.type_legal_document_id = null
			this.objectReceipt.type_legal_document = null
			this.objectReceipt.date_legal_document = null
			this.objectReceipt.legal_document_number = null
			this.objectReceipt.note = null
			this.objectReceipt.status_id = null
			this.objectReceipt.status = null
			this.objectReceipt.created_at = null
			this.objectReceipt.updated_at = null
			this.objectReceipt.items = []
		},
		getReceipts(page = 1) {
			this.loadingReports = true
			let param = '?search=' + this.searchReceipts + '&page=' + page
			let url = this.getApiBackendNew + 'receipts' + param
			this.axiosActionIS(url, 'GET', 'getReceiptsResult')
		},
		getReceiptsResult(response) {
			this.loadingReports = false
			if (response.data.success == true) {
				this.listReceipts = response.data.data
				this.paginationListReceipts = response.data.paginate
			}
			this.getReceiptsResultLocal(response)
		},
		getReceiptsResultLocal(response) {
			console.log('getReceiptsResultLocal no desarrollado', response)
		},
		saveReceipt(r, trackingsReceiptId = null) {
			this.savingReceipt = true
			let method = (r.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'receipt/'
			url += (method == 'POST') ? 'register' : r.id + '/update'
			r.trackingsReceiptId = trackingsReceiptId
			this.axiosActionIS(url, method, 'saveReceiptResult', r)
		},
		saveReceiptResult(response) {
			this.savingReceipt = false
			if (response.data.success == true) {
				this.setObjectReceipt(response.data.data)
			}
			this.saveReceiptResultLocal(response)
		},
		saveReceiptResultLocal(response) {
			console.log('saveReceiptResultLocal no desarrollado', response)
		},
		showReceipt(id) {
			this.searchingReceipt = true
			let url = this.getApiBackendNew + 'receipt/' + id
			this.axiosActionIS(url, 'GET', 'showReceiptResult')
		},
		showReceiptResult(response) {
			this.searchingReceipt = false
			if (response.data.success == true) {
				this.setObjectReceipt(response.data.data)
			}
			this.showReceiptResultLocal(response)
		},
		showReceiptResultLocal(response) {
			console.log('showReceiptResultLocal no desarrollado', response)
		},
		setObjectReceipt(r) {
			this.clearObjectReceipt()
			for (const property in r) {
				this.objectReceipt[property] = r[property]
			}
		},
		searchItemInTable(search, table, types) {
			this.searchingItemInTable = true
			this.listSearchItemInTable = []
			let param = '?search=' + search + '&table=' + table + '&types=' + types
			let url = this.getApiBackendNew + 'receipt/item/seach' + param
			console.log(url)
			this.axiosActionIS(url, 'GET', 'searchItemInTableResult')
		},
		searchItemInTableResult(response) {
			this.searchingItemInTable = false
			if (response.data.success == true) {
				this.listSearchItemInTable = response.data.data
			}
			this.searchItemInTableResultLocal(response)
		},
		searchItemInTableResultLocal(response) {
			console.log('searchItemInTableResultLocal no desarrollado', response)
		}
	},
};

export default receipt;