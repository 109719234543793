var journal = {
	data() {
		return {
			dateFromLegalDocuments: this.currentDate(-30),
			dateToLegalDocuments: this.currentDate(),
			searchingJournalEntries: false,
			listJournals: [],
			paginationListJournals: [],
			objectJournal: {
				exists: 0,
				id: 0,
				ItemId: null,
				PostedPeriod: '',
				JournalDate: this.currentDate(),
				Description: '',
				created_at: null,
				updated_at: null,
				JournalCode: null,
				JournalGuid: '',
				JournalTypeCode: '',
				items: []
			},
			objectJournalItem: {
				exists: 0,
				id: 0,
				journal_id: 0,
				account_Code: null,
				account: null,
				sub_account_Code: null,
				sub_account: null,
				fund_Code: null,
				fund: null,
				function_Code: null,
				function: null,
				restriction_Code: null,
				restriction: null,
				entity_value: null,
				description: '',
				memo: false,
				send_memo: false,
				created_at: null,
				updated_at: null,
				extra: null
			},
			savingJournal: false,
			loadingJournal: false
		}
	},
	methods: {
		addObjectJournalItems(item) {
			this.objectJournal.items.push({
				account_Code: (item.account_Code == null) ? '' : item.account_Code,
				account: item.account,
				sub_account_Code: (item.sub_account_Code == null) ? '' : item.sub_account_Code,
				sub_account: item.sub_account,
				fund_Code: (item.fund_Code == null) ? '' : item.fund_Code,
				function_Code: (item.function_Code == null) ? '' : item.function_Code,
				restriction_Code: (item.restriction_Code == null) ? '' : item.restriction_Code,
				entity_value: (item.entity_value == null) ? 0 : item.entity_value,
				description: (item.description == null) ? '' : item.description,
				memo: (item.memo == null) ? '' : item.memo,
				send_memo: (item.send_memo == null) ? '' : item.send_memo,
				created_at: (item.created_at == null) ? '' : item.created_at,
				updated_at: (item.updated_at == null) ? '' : item.updated_at,
				extra: (item.extra == null) ? '' : item.extra,
			})
			return true
		},
		clearObjectJournal() {
			this.objectJournal.exists = 0
			this.objectJournal.id = 0
			this.objectJournal.ItemId = null
			this.objectJournal.PostedPeriod = ''
			this.objectJournal.JournalDate = this.currentDate()
			this.objectJournal.Description = ''
			this.objectJournal.created_at = null
			this.objectJournal.updated_at = null
			this.objectJournal.JournalCode = null
			this.objectJournal.JournalGuid = ''
			this.objectJournal.JournalTypeCode = ''
			this.objectJournal.items = []
		},
		clearObjectJournalItem() {
			this.objectJournalItem.exists = 0
			this.objectJournalItem.id = 0
			this.objectJournalItem.journal_id = 0
			this.objectJournalItem.account_Code = null
			this.objectJournalItem.account = null
			this.objectJournalItem.sub_account_Code = null
			this.objectJournalItem.sub_account = null
			this.objectJournalItem.fund_Code = null
			this.objectJournalItem.fund = null
			this.objectJournalItem.function_Code = null
			this.objectJournalItem.function = null
			this.objectJournalItem.restriction_Code = null
			this.objectJournalItem.restriction = null
			this.objectJournalItem.entity_value = null
			this.objectJournalItem.description = ''
			this.objectJournalItem.memo = false
			this.objectJournalItem.send_memo = false
			this.objectJournalItem.created_at = null
			this.objectJournalItem.updated_at = null
			this.objectJournalItem.extra = null
		},
		getJournals(page) {
			this.loadingJournal = true
			let param = '?page=' + page
			let url = this.getApiBackendNew + 'journals' + param
			this.axiosActionIS(url, 'GET', 'getJournalsResult')
		},
		getJournalsResult(response) {
			this.loadingJournal = false
			if (response.data.success == true) {
				this.listJournals = response.data.data
				this.paginationListJournals = response.data.paginate
			}
			this.getJournalsResultLocal(response)
		},
		getJournalsResultLocal(response) {
			console.log('getJournalsResultLocal no desarrollado', response)
		},
		saveJournal(j) {
			console.log('saveJournal', j)
			let method = (j.id == 0) ? 'POST' : 'PUT'
			this.savingJournal = true
			let url = this.getApiBackendNew
			url += (method == 'POST') ? 'journal/register' : 'journal/' + j.id + '/update'
			this.axiosActionIS(url, method, 'saveJournalResult', j)
		},
		saveJournalResult(response) {
			this.savingJournal = false
			if (response.data.success == true) {
				this.setObjectJournal(response.data.data)
			}
			this.saveJournalResultLocal(response)
		},
		saveJournalResultLocal(response) {
			console.log('saveJournalResultLocal no desarrollado', response)
		},
		setObjectJournal(j) {
			this.clearObjectJournal()
			let newJ = Object.create(j)
			for (const property in newJ) {
				this.objectJournal[property] = newJ[property]
			}
			this.objectJournal.items.forEach((item, i) => {
				this.objectJournal.items[i].memo = (item.memo == 0 || item.memo == 1) ? (item.memo == 1) : item.memo
				this.objectJournal.items[i].send_memo = (item.send_memo == 0 || item.send_memo == 1) ? (item.send_memo == 1) : item.send_memo
			});
		},
		showJournal(id) {
			this.loadingJournal = true
			let url = this.getApiBackendNew + 'journal/' + id
			this.axiosActionIS(url, 'GET', 'showJournalResult')
		},
		showJournalResult(response) {
			this.loadingJournal = false
			if (response.data.success == true) {
				this.setObjectJournal(response.data.data)
			}
			this.showJournalResultLocal(response)
		},
		showJournalResultLocal(response) {
			this.loadingJournal = true
		},
		unrecordedForJournalEntrySubscription() {
			this.searchingJournalEntries = true
			this.objectJournal.items = []
			let param = '?fromDate=' + this.dateFromLegalDocuments + '&toDate=' + this.dateToLegalDocuments
			let url = this.getApiBackendNew + 'payment/unrecordedForJournalEntrySubscription' + param
			this.axiosActionIS(url, 'GET', 'unrecordedForJournalEntrySubscriptionResult')
		},
		unrecordedForJournalEntrySubscriptionResult(response) {
			this.searchingJournalEntries = false
			if (response.data.success == true) {
				this.objectJournal.items = response.data.data
			}
			this.unrecordedForJournalEntrySubscriptionResultLocal(response)
		},
		unrecordedForJournalEntrySubscriptionResultLocal(response) {
			console.log('unrecordedForJournalEntrySubscriptionResultLocal no desarrollado', response)
		}
	},
};

export default journal;