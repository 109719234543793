<template>
	<div id="employeeListComponent">
		<div v-show="loadingEmployees == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Clientes">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header" v-show="!(showHead == false)">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Empleados</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchEmployee" v-model="searchEmployee"
										@focus="$event.target.select()" @keyup.enter="getEmployeesLocal(1)">
									<div class="input-group-text bg-transparent pointer" @click="getEmployeesLocal(1)"
										ref="btnGetEmployees">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<i>Doble click para seleccionar el empleado</i>
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showEmployeeLocal(0)" ref="btnShowEmployee"
								v-show="!(showNewEmployee == false)">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900">Nombre</th>
								<th class="text-900">Apellido</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(e, i) in listEmployeesFilter" :key="i" @dblclick="actionDblClick(e)"
								:class="(disabledInactive == true && e.status_id == 2) ? 'text-decoration-line-through text-400 no-drop' : ''"
								v-show="e.status_id == 1 || !(hideInactive == true)">
								<td class="text-center">{{ e.id }}</td>
								<td>{{ e.name }}</td>
								<td>{{ e.surname }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListEmployees" @getAllList="getEmployeesLocal">
					</pagination>
				</div>
				<div class="mt-2 text-end">
					<button class="btn btn-sm btn-secondary me-2" @click="closeEmployeeListComponent"
						v-show="showBtnClose == true">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import employee from '@/mixins/generals/employee.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'EmployeeListComponent',
	mixins: [mixin, axiosAction, errors, employee],
	props: ['showHead', 'showNewEmployee', 'actionDblClickParent', 'disabledInactive', 'hideInactive', 'showBtnClose'],
	data() {
		return {
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
		listEmployeesFilter() {
			return this.listEmployees
		}
	},
	methods: {
		actionDblClick(e) {
			if (this.actionDblClickParent == true) {
				if (this.disabledInactive != true || e.status_id == 1) {
					this.$emit('actionDblClick', e)
				}
			} else {
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getEmployeesResult': this.getEmployeesResult(response); break;
				case 'saveEmployeesResult': this.saveEmployeesResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		closeEmployeeListComponent() {
			this.$emit('closeEmployeeList')
		},
		getEmployeesLocal(page = 1) {
			this.getEmployees(page)
		},
		getEmployeesResultLocal(response) { },
		initModuleLocal() {
			this.setEmployeeListInit()
		},
		setEmployeeListInit() { },
		showEmployeeLocal(id) {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>