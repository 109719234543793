<template>
	<div id="inventoryTemplateDetailListComponent">
		<div v-show="loadingInventoryTemplateDetails == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando detalles...">
			</mensagge-loading-component>
		</div>
		<div>
			<inventory-template-detail-component></inventory-template-detail-component>
		</div>
		<div class="row flex-between-center mb-2">
			<div class="col-sm-auto d-flex align-items-center pe-0">
				<div class="row gy-2 gx-3 align-items-center">
					<div class="col-auto">
						<div class="input-group">
							<input class="form-control form-control-sm shadow-none search" type="search"
								placeholder="Buscar articulo" aria-label="search" ref="searchInventoryTemplateDetail"
								v-model="searchInventoryTemplateDetail" @focus="$event.target.select()"
								@keyup.enter="getInventoryTemplateDetailsLocal(1)">
							<div class="input-group-text bg-transparent pointer"
								@click="getInventoryTemplateDetailsLocal(1)">
								<span class="fa fa-search fs--1 text-600"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-8 col-sm-auto ms-auto text-end ps-0">
				<div id="orders-actions">
					<button class="btn btn-falcon-default btn-sm me-2" type="button"
						@click="showInventoryTemplateDetailLocal(0)" ref="btnShowInventoryTemplateDetail">
						<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
						<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
					</button>
				</div>
			</div>
		</div>
		<div class="table-responsive scrollbar">
			<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
				<thead class="bg-200">
					<tr>
						<th class="text-900">Producto</th>
						<th class="text-900">Planilla</th>
						<th class="text-900">Ubicación</th>
						<th class="text-900 text-center">Conteo 1</th>
						<th class="text-900 text-center">Conteo 2</th>
						<th class="text-900 text-center">Conteo 3</th>
						<th class="text-900 text-center">Conteo 4</th>
						<th class="text-900 text-center">Definitivo</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(itd, i) in listInventoryTemplateDetailsFilter" :key="i">
						<td class="align-middle ps-0 text-nowrap">
							<div class="d-flex position-relative align-items-center">
								<img class="d-flex align-self-center me-2" :src="itd.product.img" alt="" width="30" />
								<div class="flex-1">
									<a class="stretched-link" href="#!">
										<h6 class="mb-0">{{ itd.product.name }}</h6>
									</a>
									<p class="mb-0">
										<small>Cod: {{ itd.product_id }} | ISBN: {{ itd.product.isbn }}</small>
									</p>
								</div>
							</div>
						</td>
						<td class="align-middle ps-0 text-nowrap">
							{{ itd.inventory_template.name }}
						</td>
						<td class="align-middle ps-0 text-nowrap">
							{{ itd.store_rack_side_row_column.store.name }}
							{{ itd.store_rack_side_row_column.store_rack.name }}
							{{ itd.store_rack_side_row_column.store_rack_side.name }}
							{{ itd.store_rack_side_row_column.name + '-' +
								itd.store_rack_side_row_column.store_rack_side_row.name }}
						</td>
						<td class="align-middle ps-0 text-nowrap text-center">
							<input class="input-clear text-center fs--1" v-model="itd.count_1" disabled
								:title="'Actualizado el ' + itd.updated_at_1" />
						</td>
						<td class="align-middle ps-0 text-nowrap text-center">
							<input class="input-clear text-center fs--1" v-model="itd.count_2" disabled
								:title="'Actualizado el ' + itd.updated_at_1" />
						</td>
						<td class="align-middle ps-0 text-nowrap text-center">
							<input class="input-clear text-center fs--1" v-model="itd.count_3" disabled
								:title="'Actualizado el ' + itd.updated_at_1" />
						</td>
						<td class="align-middle ps-0 text-nowrap text-center">
							<input class="input-clear text-center fs--1" v-model="itd.count_4" disabled
								:title="'Actualizado el ' + itd.updated_at_1" />
						</td>
						<td class="align-middle ps-0 text-nowrap text-center">
							<input class="input-clear text-center fs--1" v-model="itd.count_end"
								:title="'Actualizado el ' + itd.updated_at_end" :disabled="disabledInputCountEnd" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="d-flex align-items-center justify-content-center">
			<pagination :pagination="paginationListInventoryTemplateDetails"
				@getAllList="getInventoryTemplateDetailsLocal">
			</pagination>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import inventoryTemplateDetail from '@/mixins/inventories/inventoryTemplateDetail.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import InventoryTemplateDetailComponent from '@/components/InventoryTemplateDetailComponent.vue'

export default {
	name: 'InventoryTemplateDetailListComponent',
	mixins: [mixin, axiosAction, errors, inventoryTemplateDetail],
	props: ['inventoryId', 'disabledInputCountEnd'],
	data() {
		return {
		}
	},
	components: {
		'inventory-template-detail-component': InventoryTemplateDetailComponent,
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
		listInventoryTemplateDetailsFilter() {
			return this.listInventoryTemplateDetails
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getInventoryTemplateDetailsResult': this.getInventoryTemplateDetailsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getInventoryTemplateDetailsLocal(page = 1) {
			this.getInventoryTemplateDetails(this.inventoryId, page)
		},
		getInventoryTemplateDetailsResultLocal(response) { console.log(response) },
		initModuleLocal() {
			this.setInventoryTemplateDetailInit()
		},
		setInventoryTemplateDetailInit() {
			this.getInventoryTemplateDetailsLocal()
		},
		showInventoryTemplateDetailLocal() { },
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>