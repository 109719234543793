<template>
	<div id="suppliersModule">
		<supplier-list-component></supplier-list-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import SupplierListComponent from '@/components/SupplierListComponent'
export default {
	name: 'SuppliersModule',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
		}
	},
	components: {
		'supplier-list-component': SupplierListComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
