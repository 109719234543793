<template>
	<div id="purchaseOrderComponent">
		<button ref="btnShowPurchaseOrder" @click="showPurchaseOrderLocal()" v-show="false">Show Purchase Order</button>
		<div class="col mb-2">
			<div class="card">
				<div class="bg-holder bg-card"
					style="background-image:url(/assets/img/icons/spot-illustrations/corner-1.png);">
				</div>
				<div class="card-body position-relative">
					<div class="row justify-content-between align-items-center">
						<div class="col-md">
							<h5 class="mb-2 mb-md-0">
								<span v-show="objectPurchaseOrder.id == 0">
									<span v-show="searchPurchaseOrders">Buscando Asiento Nº {{ this.id }}</span>
									<span v-show="!searchPurchaseOrders">Nueva Orden de Compra</span>
								</span>
								<span v-show="objectPurchaseOrder.id > 0">Order de Compra Nº {{ objectPurchaseOrder.id
									}}</span>
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col mb-2 fs--1">
			<div class="card">
				<div class="card-body">
					<div class="row mb-2">
						<div class="col-md-1">
							<span class="fw-bold">Proveedor:</span>
						</div>
						<div class="col-md-3 text-end">
							<input class="form-control form-control-sm fs--1"
								v-model="objectPurchaseOrder.supplier.name" placeholder="Ingrese código, nombre o RUT"
								@keyup="clearDataSupplierLocal(['document_number'])" @focus="$event.target.select()"
								@blur="getSupplierLocal(objectPurchaseOrder.supplier.name, 'NAME')"
								@keyup.enter="getSupplierLocal(objectPurchaseOrder.supplier.name, 'NAME')"
								:disabled="disableAll" ref="inputObjectPurchaseOrderSupplierName">
						</div>
						<div class="col-md-1 text-end">
							<span class="fw-bold">RUT:</span>
						</div>
						<div class="col-md-2 text-end">
							<input class="form-control form-control-sm fs--1"
								v-model="objectPurchaseOrder.supplier.document_number" @focus="$event.target.select()"
								@keyup="clearDataSupplierLocal(['name'])"
								@blur="getSupplierLocal(objectPurchaseOrder.supplier.document_number, 'DOCUMENTNUMBER')"
								@keyup.enter="getSupplierLocal(objectPurchaseOrder.supplier.document_number, 'DOCUMENTNUMBER')"
								:disabled="disableAll" ref="inputObjectPurchaseOrderSupplierDocumentNumber">
						</div>
						<div class="col-md-1 text-end">
							<span class="fw-bold">Contacto:</span>
						</div>
						<div class="col-md-3 text-end">
							<input class="form-control form-control-sm fs--1" v-model="objectPurchaseOrder.contact"
								@focus="$event.target.select()" :disabled="disableAll"
								ref="inputObjectPurchaseOrderContact">
						</div>
					</div>
					<div class="row mb-2">
						<div class="col-md-1">
							<span class="fw-bold">Telefono:</span>
						</div>
						<div class="col-md-2 text-end">
							<input class="form-control form-control-sm fs--1" v-model="objectPurchaseOrder.phone"
								@focus="$event.target.select()" :disabled="disableAll">
						</div>
						<div class="col-md-1 text-end">
							<span class="fw-bold">Email:</span>
						</div>
						<div class="col-md-3 text-end">
							<input class="form-control form-control-sm fs--1" v-model="objectPurchaseOrder.email"
								@focus="$event.target.select()" :disabled="disableAll">
						</div>
					</div>
					<div class="row mb-2">
						<div class="col-md-1">
							<span class="fw-bold">Dirección:</span>
						</div>
						<div class="col-md-4 text-end">
							<input class="form-control form-control-sm fs--1"
								v-model="objectPurchaseOrder.supplier.address" @focus="$event.target.select()"
								:disabled="disableAll">
						</div>
						<div class="col-md-2 text-end">
							<span class="fw-bold">Comuna:</span>
						</div>
						<div class="col-md-4 text-end">
							<select class="form-select form-select-sm fs--1"
								v-model="objectPurchaseOrder.supplier.comuna_id" :disabled="disableAll">
								<option v-for="(c, i) in listComunasFilter" :key="i" :value="c.id">{{ c.name }}</option>
							</select>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col-md-1">
							<span class="fw-bold">Departamento:</span>
						</div>
						<div class="col-md-2 text-end">
							<select class="form-select form-select-sm fs--1"
								v-model="objectPurchaseOrder.departament_id" :disabled="disableAll">
								<option v-for="(d, i) in listDeparmentsFilter" :key="i" :value="d.id">{{ d.name }}
								</option>
							</select>
						</div>
						<div class="col-md-2 text-end">
							<span class="fw-bold">Forma de Pago:</span>
						</div>
						<div class="col-md-2 text-end">
							<select class="form-select form-select-sm fs--1"
								v-model="objectPurchaseOrder.type_payment_id" :disabled="disableAll">
								<option v-for="(tp, i) in listTypePaymentsFilter" :key="i" :value="tp.id">{{ tp.name }}
								</option>
							</select>
						</div>
						<div class="col-md-2 text-end">
							<span class="fw-bold">Nº Cotización:</span>
						</div>
						<div class="col-md-2 text-end">
							<input class="form-control form-control-sm fs--1"
								v-model="objectPurchaseOrder.supplier.quotation_id" @focus="$event.target.select()"
								:disabled="disableAll">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col mb-2 fs--1">
			<div class="card">
				<div class="card-body p-0">
					<div class="table-responsive scrollbar" style="height: 37vh;">
						<table class="table table-sm">
							<thead>
								<tr class="border-bottom border-muted">
									<th class="text-center py-2 px-0">Item</th>
									<th class="text-center py-2 px-0">Código Int</th>
									<th class="text-center py-2 px-0">Código</th>
									<th class="text-center py-2 px-0">Descripción</th>
									<th class="text-center py-2 px-0">Cantidad</th>
									<th class="text-center py-2 px-0">Moneda</th>
									<th class="text-center py-2 px-0">Precio Unit</th>
									<th class="text-center py-2 px-0">Total</th>
									<th class="text-end">
										<span class="text-primary pointer"
											@click="addItemEmptyObjectPurchaseOrderDetailsLocal()">
											<i class="fas fa-plus-circle"></i>
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(pod, i) in objectPurchaseOrder.details" :key="i">
									<td class="text-center">{{ i + 1 }}</td>
									<td class="text-center">
										<input class="input-clear text-center" v-model="pod.internal_id">
									</td>
									<td class="text-center">
										<input class="input-clear text-center" v-model="pod.code">
									</td>
									<td>
										<input class="input-clear" v-model="pod.description">
									</td>
									<td class="text-end">
										<input class="input-clear text-end" v-model="pod.quantity">
									</td>
									<td class="text-center">{{ pod.currency_type.synthetic }} $</td>
									<td class="text-end">
										<input class="input-clear text-end" v-model="pod.price">
									</td>
									<td class="text-end">{{ methodFormatNumber(pod.price *
										pod.quantity) }}</td>
									<td class="text-end">
										<span class="text-warning">
											<i class="fas fa-minus-circle"></i>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="col mb-2 fs--1">
			<div class="card">
				<div class="card-body p-0">
					<div class="table-responsive mb-2">
						<table style="width: 100%;">
							<tfoot>
								<tr>
									<td></td>
									<td class="text-end">Neto:</td>
									<td class="text-end px-2 py-1" style="width: 150px;">{{
										methodFormatNumber(totalObjectPurchaseOrderDetails)
									}}</td>
								</tr>
								<tr>
									<td></td>
									<td class="text-end">IVA:</td>
									<td class="text-end px-2 py-1">{{
										methodFormatNumber(objectPurchaseOrder.total_tax) }}
									</td>
								</tr>
								<tr>
									<td></td>
									<td class="text-end fw-bold">TOTAL:</td>
									<td class="fw-bold text-end px-2 py-1">{{
										methodFormatNumber(objectPurchaseOrder.total) }}</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="text-end">
			<button class="btn btn-primary btn-sm" @click="savePurchaseOrderLocal"
				:disabled="disableBtnSave">Guardar</button>
		</div>

		<!-- Button trigger modal -->
		<button type="button" class="btn btn-primary" data-bs-toggle="modal"
			data-bs-target="#supplierListComponentModal" v-show="false" ref="btnShowSupplierListComponentModal">
			Show Supplier List Component Modal
		</button>

		<!-- Modal -->
		<div class="modal fade" id="supplierListComponentModal" tabindex="-1"
			aria-labelledby="supplierListComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header" v-show="false">
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
							ref="btnCloseSupplierListComponentModal"></button>
					</div>
					<supplier-list-component ref="supplierListComponent" :search="searchSupplier"
						@getSuppliersResult="getSuppliersResultLocal"></supplier-list-component>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import purchaseOrder from '@/mixins/purchaseOrders'
import comuna from '@/mixins/generals/comuna.js'
import departament from '@/mixins/generals/departament.js'
import typePayment from '@/mixins/generals/typePayment.js'

import SupplierListComponent from '@/components/SupplierListComponent.vue'

export default {
	name: 'PurchaseOrderComponent',
	mixins: [mixin, axiosAction, errors, purchaseOrder, comuna, departament, typePayment],
	props: ['id'],
	data() {
		return {
			searchingSupplier: false,
			searchSupplier: ''
		}
	},
	components: {
		'supplier-list-component': SupplierListComponent
	},
	computed: {
		disableAll() {
			if (this.searchingSupplier == true) {
				return true
			}
			return false
		},
		disableBtnSave() {
			if (this.disableAll == true) {
				return true
			}
			if (this.validateFormObjectPurchaseOrder == false) {
				return true
			}
			return false
		},
		listComunasFilter() {
			return this.listComunas
		},
		listDeparmentsFilter() {
			return this.listDepartaments
		},
		listTypePaymentsFilter() {
			return this.listTypePayments
		},
		totalObjectPurchaseOrderDetails() {
			this.objectPurchaseOrder.net = 0
			this.objectPurchaseOrder.total = 0
			this.objectPurchaseOrder.total_tax = 0
			let c = 0
			let c_aux = 0
			return this.objectPurchaseOrder.details.reduce((a, b) => {
				c = parseFloat(b.quantity) * parseFloat(b.price)
				c_aux = c
				if (!isNaN(c_aux)) {
					if (b.with_taxes_included == false) {
						c_aux = parseFloat(b.quantity) * (parseFloat(b.price) * (1 + parseFloat(b.tax)))
					} else {
						c = parseFloat(b.quantity) * (parseFloat(b.price) / (1 + parseFloat(b.tax)))
					}
					this.objectPurchaseOrder.net = parseFloat(this.objectPurchaseOrder.net) + parseFloat(c)
					this.objectPurchaseOrder.total = parseFloat(this.objectPurchaseOrder.total) + parseFloat(c_aux)
					this.objectPurchaseOrder.total_tax = this.objectPurchaseOrder.total - this.objectPurchaseOrder.net

					console.log(this.objectPurchaseOrder.total)
					this.objectPurchaseOrder.total = this.objectPurchaseOrder.total.toFixed(0)
				}
				return a + c
			}, 0);
		},
		validateFormObjectPurchaseOrder() {
			if (this.objectPurchaseOrder.supplier.name.trim().length < 5) {
				return false
			}
			if (!this.checkDigitoRUT(this.objectPurchaseOrder.supplier.document_number) && !this.checkDigitCUIT(this.objectPurchaseOrder.supplier.document_number)) {
				return false
			}
			this.objectPurchaseOrder.supplier.document_number = this.objectPurchaseOrder.supplier.document_number.replaceAll('.', '')
			if (this.objectPurchaseOrder.contact == null) {
				return false
			}
			if (this.objectPurchaseOrder.contact.trim().length < 5) {
				return false
			}
			if (this.objectPurchaseOrder.phone == null) {
				return false
			}
			if (this.objectPurchaseOrder.phone.trim().length < 9) {
				return false
			}
			if (this.objectPurchaseOrder.departament_id == 0 || this.objectPurchaseOrder.departament_id == null) {
				return false
			}
			if (this.objectPurchaseOrder.type_payment_id == 0 || this.objectPurchaseOrder.type_payment_id == null) {
				return false
			}
			if (this.objectPurchaseOrder.details.length == 0) {
				return false
			}
			return true
		}
	},
	methods: {
		addItemEmptyObjectPurchaseOrderDetailsLocal() {
			this.addItemEmptyObjectPurchaseOrderDetails()
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllComunasResult': this.getAllComunasResult(response); break;
				case 'getAllTypePaymentsResult': this.getAllTypePaymentsResult(response); break;
				case 'getDepartamentsResult': this.getDepartamentsResult(response); break;
				case 'savePurchaseOrderResult': this.savePurchaseOrderResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearDataSupplierLocal(fields) {
			if (this.objectPurchaseOrder.supplier_id > 0) {
				this.objectPurchaseOrder.supplier_id = 0
				fields.forEach(f => {
					this.objectPurchaseOrder.supplier[f] = ''
				})
			}
		},
		getAllComunasResultLocal(response) { },
		getAllTypePaymentsResultLocal(response) { },
		getDepartamentsResultLocal(response) { },
		getSupplierLocal(s, type) {
			let b = false
			if (type == 'NAME') {
				if (s != '') {
					if (s.trim().length > 5) {
						b = true
					}
				}
			} else if (type == 'DOCUMENTNUMBER') {
				if (s != '') {
					if (this.checkDigitoRUT(s) || this.checkDigitCUIT(s)) {
						b = true
					} else {
						this.showMsgErrorHandling('Formato no valido', 'El Formato del RUT o CUIT no es valido', 'warn', 5000, 'top right')
					}
				}
			}
			if (b == true) {
				this.searchingSupplier = true
				this.searchSupplier = s
				setTimeout(() => { this.$refs.supplierListComponent.$refs.btnGetSupplier.click() }, 50)
			}
		},
		getSuppliersResultLocal(r) {
			this.searchingSupplier = false
			if (r == null) {
				this.showMsgErrorHandling('No Encontrado', 'No se encontró ningún proveedor con estos dastos.<br>Puede seguir, se creara el nuevo proveedor', 'info', 5000, 'top right')
				if (this.checkDigitoRUT(this.objectPurchaseOrder.supplier.name) || this.checkDigitCUIT(this.objectPurchaseOrder.supplier.name)) {
					this.objectPurchaseOrder.supplier.document_number = this.objectPurchaseOrder.supplier.name
					this.objectPurchaseOrder.supplier.name = ''
					setTimeout(() => { this.$refs.inputObjectPurchaseOrderSupplierName.focus() }, 50)
				} else if (this.objectPurchaseOrder.supplier.name.trim() != '') {
					if (!this.checkDigitoRUT(this.objectPurchaseOrder.supplier.document_number)) {
						setTimeout(() => { this.$refs.inputObjectPurchaseOrderSupplierDocumentNumber.focus() }, 50)
					} else {
						setTimeout(() => { this.$refs.inputObjectPurchaseOrderContact.focus() }, 50)
					}
				} else {

				}
				console.log('No encontró nada, seguir llenando')
			} else if (r == true) {
				this.$refs.btnShowSupplierListComponentModal.click()
				console.log('Encontró mas de un resultado, abrir para seleccionar')
			} else {
				this.objectPurchaseOrder.supplier_id = r.id
				this.objectPurchaseOrder.supplier.id = r.id
				this.objectPurchaseOrder.supplier.name = r.name
				this.objectPurchaseOrder.supplier.document_number = r.document_number
				this.objectPurchaseOrder.contact = r.contact
				this.objectPurchaseOrder.phone = r.phone
				this.objectPurchaseOrder.email = r.email
				this.objectPurchaseOrder.supplier.address = r.address
				this.objectPurchaseOrder.supplier.comuna_id = r.comuna_id
				this.objectPurchaseOrder.supplier.comuna = r.comuna
				this.$refs.btnCloseSupplierListComponentModal.click()
				setTimeout(() => { this.$refs.inputObjectPurchaseOrderContact.focus() }, 50)
			}
			this.objectPurchaseOrder.supplier.document_number = this.objectPurchaseOrder.supplier.document_number.replaceAll('.', '')
		},
		initModuleLocal() {
			this.setPurchaseOrderInit()
		},
		savePurchaseOrderLocal() {
			this.savePurchaseOrder(this.objectPurchaseOrder)
		},
		savePurchaseOrderResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('savePurchaseOrderResult', response.data.data)
			}
			console.log(response)
		},
		setPurchaseOrderInit() {
			this.getAllComunas()
			this.getDepartaments()
			this.getAllTypePayments()
		},
		showPurchaseOrderLocal() {
			this.clearObjectPurchaseOrder()
			if (this.id > 0) {
				this.showPurchaseOrder(this.id)
			}
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>