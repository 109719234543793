<template>
	<div id="budgetListComponent">
		<div v-show="loadingBudgets == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Titulo">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Presupuestos</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchBudgets"
										@focus="$event.target.select()" v-model="searchBudgets"
										@keyup.enter="getBudgetsLocal(1)">
									<div class="input-group-text bg-transparent pointer" @click="getBudgetsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button" @click="showBudgetLocal(0)"
								ref="btnShowBudget">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900">Nombre</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(b, i) in listBudgetsFilter" :key="i">
								<td>{{ b.id }}</td>
								<td>{{ b.name }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationBudgets" @getAllList="getBudgetsLocal">
					</pagination>
				</div>
			</div>
		</div>
		<budget-wizard-component></budget-wizard-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import budget from '@/mixins/budgets'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

import BudgetWizardComponent from '@/components/BudgetWizardComponent.vue'

export default {
	name: 'BudgetListComponent',
	mixins: [mixin, axiosAction, errors, budget],
	props: [],
	data() {
		return {
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent,
		'budget-wizard-component': BudgetWizardComponent
	},
	computed: {
		listBudgetsFilter() {
			return this.listBudgets
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getBudgetsResult': this.getBudgetsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setBudgetListComponentInit()
		},
		getBudgetsLocal(page = 1) {
			this.getBudgets(page)
		},
		getBudgetsResultLocal(response) {

		},
		showBudgetLocal() {

		},
		setBudgetListComponentInit() {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>