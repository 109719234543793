<template>
	<div id="budgetWizardComponent">

		<div class="d-flex border-bottom border-muted pb-1 mb-2">
			<span class="fa-stack me-2 ms-n1">
				<i class="fas fa-circle fa-stack-2x text-300"></i>
				<i class="fa-inverse fa-stack-1x text-primary fas fa-spinner"></i>
			</span>
			<div class="col">
				<h5 class="mb-0 text-primary position-relative">
					<span class="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
					<span class="position-relative bg-200 dark__bg-1100 pe-3">Preparación del un Nuevo
						Presupuesto</span>
				</h5>
				<p class="mb-0"><small>Este asistente le ayudará a preparar un presupuesto nuevo</small></p>
			</div>
		</div>
		<div class="px-3 mb-2" style="min-height: 20.5em">
			<div v-show="tabWinzarIndexSelected == 0">
				<h6>Para empezar ingresaremos la información general</h6>
				<div class="row">
					<div class="col-md-4 fs--1 mb-2">
						<label class="form-label fs--1">Nombre del trabajo:</label>
						<input class="form-control form-control-sm" type="text" placeholder=""
							v-model="objectBudget.name" />
						<small><i>Ejemplo: Afiche Semana Santa 2025</i></small>
					</div>
					<div class="col-md-2 fs--1 mb-2">
						<label class="form-label fs--1">Dimenciones</label>
						<input class="form-control form-control-sm" type="text"
							placeholder="ancho x altura x profundidad" v-model="dimensions">
						<small><i>Ejemplo: 13x21 o 6x6x6</i></small>
					</div>
					<div class="col-md-4 fs--1 mb-2">
						<label class="form-label fs--1">Tipo de impreso</label>
						<select class="form-select form-select-sm" v-model="objectBudget.print_type_id">
							<option :value="null" disabled>Selecione el tipo de impreso</option>
							<option v-for="(pt, i) in listPrintTypesFilter" :key="i" :value="pt.id">{{ pt.name }}
							</option>
						</select>
					</div>
					<div class="col-md-2 fs--1 mb-2">
						<label class="form-label fs--1">Diseño</label>
						<div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" id="inlineRadio1" type="radio" name="inlineRadioOptions"
									:value="1" v-model="objectBudget.design" />
								<label class="form-check-label" for="inlineRadio1">Si</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" id="inlineRadio2" type="radio" name="inlineRadioOptions"
									:value="0" v-model="objectBudget.design" />
								<label class="form-check-label" for="inlineRadio2">No</label>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-4 fs--1 mb-2">
						<label class="form-label fs--1">Cliente</label>
						<input class="form-control form-control-sm fs--1" v-model="searchClientsLocal"
							placeholder="Ingrese código, nombre o RUT" @blur="getClientLocal(searchClientsLocal)"
							@keyup="clearDataObjectBudgetClient" @keyup.enter="getClientLocal(searchClientsLocal)"
							ref="inputSearchClients" @focus="$event.target.select()">
						<small>
							<i>
								<span class="text-warning" v-if="objectBudget.client_id == null">
									Sin cliente asociado
								</span>
								<span class="text-success" v-else>
									Cod: {{ objectBudget.client_id }} - RUT: {{ objectBudget.client.number_document }}
								</span>
							</i>
						</small>
					</div>
					<div class="col-md-3 fs--1 mb-2">
						<label class="form-label fs--1">Contacto</label>
						<input class="form-control form-control-sm" type="text" placeholder=""
							v-model="objectBudget.contact" ref="inputContact" @focus="$event.target.select()">
					</div>
					<div class="col-md-2 fs--1 mb-2">
						<label class="form-label fs--1">Telefono</label>
						<input class="form-control form-control-sm" type="text" placeholder=""
							v-model="objectBudget.phone" ref="inputPhone" @focus="$event.target.select()">
					</div>
					<div class="col-md-3 fs--1 mb-2">
						<label class="form-label fs--1">Email</label>
						<input class="form-control form-control-sm" type="text" placeholder=""
							v-model="objectBudget.email" ref="inputEmail" @focus="$event.target.select()">
					</div>
				</div>
			</div>
			<div v-show="tabWinzarIndexSelected == 1" v-if="objectBudget.components.length > 0">
				<div v-for="(c, e) in objectBudget.components" :key="e" v-show="componentIndexSelected == e"
					class="mb-2">
					<h6>
						Componente impreso {{ c.name }}
						<span title="Eliminar Componente" class="float-end pointer link-secondary"
							v-show="objectBudget.components.length > 1"
							@click="deleteItemObjectBudgetComponentLocal(e)">x</span>
					</h6>
					<div class="fs--1">
						<div class="row">
							<div class="col-md-4 fs--1 mb-2">
								<label class="form-label fs--1">Nombre del componente:</label>
								<input class="form-control form-control-sm" type="text" placeholder=""
									v-model="c.name" />
								<small><i>Ejemplo: Portada, Interior, Insert</i></small>
							</div>
							<div class="col-md-3 fs--1 mb-2">
								<label class="form-label fs--1">Páginas:</label>
								<input class="form-control form-control-sm" type="number" placeholder="" />
								<small><i>Ejemplo: 2, 4, 6, etc. (de este componente)</i></small>
							</div>
							<div class="col-md-3 fs--1 mb-2">
								<label class="form-label fs--1">Formato acabado :</label>
								<input class="form-control form-control-sm" type="text" placeholder="" />
								<small><i>Ejemplo: 21x14.85, etc. (abierto)</i></small>
							</div>
							<div class="col-md-2 fs--1 mb-2">
								<label class="form-label fs--1">Tintas:</label>
								<input class="form-control form-control-sm" type="text" placeholder="" />
								<small><i>Ejemplo: 4-0, 4-2, 4-4 (Cara - dorso)</i></small>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3 fs--1 mb-2">
								<label class="form-label fs--1">Tipo de Papel:</label>
								<select class="form-select form-select-sm">
									<option v-for="(tp, i) in listTypePapersFilter" :key="i" :value="tp.id">
										{{ tp.name }}
									</option>
								</select>
								<small><i>Ejemplo: Bond, Couche, etc.</i></small>
							</div>
							<div class="col-md-2 fs--1 mb-2">
								<label class="form-label fs--1">Gramaje:</label>
								<input class="form-control form-control-sm" type="text" placeholder="" />
								<small><i>Ejemplo: 80</i></small>
							</div>
							<div class="col-md-2 fs--1 mb-2">
								<label class="form-label fs--1">Dimensiones:</label>
								<input class="form-control form-control-sm" type="text" placeholder="" />
								<small><i>Ejemplo: 52x70 (sin cortar)</i></small>
							</div>
							<div class="col-md-3 fs--1 mb-2">
								<label class="form-label fs--1">Máquina:</label>
								<select class="form-select form-select-sm">
									<option v-for="(e, i) in listEquipmentsFilter" :key="i" :value="e.id"> {{ e.name }}
									</option>
								</select>
								<small><i>Ejemplo: Komori</i></small>
							</div>
							<div class="col-md-2 fs--1 mb-2">
								<label class="form-label fs--1">Pliego:</label>
								<input class="form-control form-control-sm" type="text" placeholder="" />
								<small><i>Ejemplo: 52x70 (como entra a la maquina)</i></small>
							</div>
						</div>
						<div>
							<label class="form-label fs--1">Acabados</label>
							<div class="row">
								<div class="col-md-3" v-for="(f, i) in listFinishingsFilter" :key="i">
									<input class="form-check-input" id="flexCheckDefault" type="checkbox"
										name="flexCheckDefault" />
									<span class="ms-2">{{ f.name }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="objectBudget.components.length > 1" class="fs--1 ps-1">
					<span v-for="(c, e) in objectBudget.components" :key="e" class="px-3 pointer"
						:class="(componentIndexSelected != e) ? 'text-400 border-top' : 'border border-top-0'"
						@click="changeComponentIndexSelected(e)">
						<span>
							{{ (c.name == '') ? 'Componente ' + (e + 1) : c.name }}
						</span>
					</span>
				</div>
				<div class="text-end fs--1" v-show="visibleAddOtherComponent == true">
					<span @click="addNewItemBlankObjectBudgetComponentLocal">
						<i class="text-info pointer hover">
							agregar otro componente
						</i>
					</span>
				</div>
			</div>
		</div>
		<div class="text-end">
			<button class="btn btn-sm btn-primary ms-2" :disabled="disabledBtnBack" @click="backTabIdSelected">
				<i class="fas fa-angle-double-left"></i>
				Anterior
			</button>
			<button class="btn btn-sm btn-primary ms-2" :disabled="disabledBtnNext" @click="nextTabIdSelected">
				Siguiente
				<i class="fas fa-angle-double-right"></i>
			</button>
			<button class="btn btn-sm btn-secondary ms-2">
				Cancelar
			</button>
		</div>

		<!-- Button trigger modal -->
		<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#searchClientModal"
			v-show="false" ref="btnShowClientModal">
			Show client modal
		</button>

		<!-- Modal -->
		<div class="modal fade" id="searchClientModal" tabindex="-1" aria-labelledby="searchClientModalLabel"
			aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<div class="input-group">
							<input type="text" class="form-control form-control-sm" v-model="searchClientsModal"
								@keyup.enter="getClientLocal(searchClientsModal, 'MODAL')" ref="inputSearchClientsModal"
								@focus="$event.target.select()">
							<button class="btn btn-outline-secondary btn-sm"
								@click="getClientLocal(searchClientsModal, 'MODAL')">Buscar</button>
						</div>
					</div>
					<div class="modal-body">
						<table class="table table-sm table-hover fs--1">
							<thead>
								<tr>
									<th>Cliente</th>
									<th colspan="2">Nº Documento</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(c, i) in listClients" :key="i">
									<td>{{ c.name }} {{ c.surname }}</td>
									<td>{{ c.number_document }}</td>
									<td class="text-end">
										<span @click="selectClientLocal(c)">
											<i class="fas fa-share pointer info"></i>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"
							ref="btnCloseClientModal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>






	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import budget from '@/mixins/budgets'
import printType from '@/mixins/productions/printType.js'
import client from '@/mixins/clients'
import equipment from '@/mixins/generals/equipment.js'
import finish from '@/mixins/generals/finish.js'

export default {
	name: 'BudgetWizardComponent',
	mixins: [mixin, axiosAction, errors, budget, printType, client, equipment],
	props: [],
	data() {
		return {
			client: '',
			dimensions: '',
			lastMessage: '',
			listFinishings: [
				{ id: 1, name: 'Pre-picado', outsourced: 1 },
				{ id: 2, name: 'Troquelado', outsourced: 1 },
				{ id: 3, name: 'Emplacado', outsourced: 1 },
				{ id: 4, name: 'Termo-laminado', outsourced: 1 },
				{ id: 5, name: 'Laca UV', outsourced: 1 },
				{ id: 6, name: 'Plisado', outsourced: 1 },
				{ id: 7, name: 'Polipropileno', outsourced: 1 },
				{ id: 8, name: 'Barniz sectorizado', outsourced: 1 },
				{ id: 9, name: 'Otro', outsourced: 1 }
			],
			listFinishes: [
				{ id: 1, name: 'Engrampado', outsourced: 0 },
				{ id: 2, name: 'Espiral', outsourced: 1 },
				{ id: 3, name: 'Anillado', outsourced: 1 },
				{ id: 4, name: 'Anillado doble 0', outsourced: 1 },
				{ id: 5, name: 'Hotmel sin costura', outsourced: 1 },
				{ id: 6, name: 'Hotmel con costura', outsourced: 1 },
				{ id: 7, name: 'Otro', outsourced: 1 }
			],
			listTabs: [
				{ id: 1, title: 'Indique el tipo de trabajo' },
				{ id: 2, title: 'Componente impreso' },
				{ id: 3, title: 'Resumen presupuesto' },
			],
			listTecnologies: [
				{ id: 1, name: 'KOMORI' }
			],
			listTypePapers: [
				{ id: 1, name: 'Papel Bond', grammages: ['54', '56', '57', '63'] },
				{ id: 2, name: 'Papel Couche', grammages: ['90', '100', '130', '170', '200', '216', '250', '300', '350'] },
			],
			searchClientsLocal: '',
			searchClientsModal: '',
			tabWinzarIndexSelected: 0,
			typeJobId: 0,
			typeSearchClient: '',
			componentIndexSelected: 0,
		}
	},
	components: {
	},
	computed: {
		disabledBtnBack() {
			if (this.tabWinzarIndexSelected == 0)
				return true
			return false
		},
		disabledBtnNext() {
			if (this.tabWinzarIndexSelected >= (this.listTabs.length - 1))
				return true
			if (this.tabWinzarIndexSelected == 0) {
				// Comprueba que el nombre del trabajo dea de al menos 5 caracteres
				if (this.objectBudget.name.trim().length < 5) {
					return true
				}
				// Comprobamos que las dimensiones tengan un formato correcto 
				if (!this.validateDimensions(this.dimensions) && !this.validateDimensions(this.dimensions, '3D')) {
					return true
				}
				// Comprobamos que se elija un tipo de impreso
				if (this.objectBudget.print_type_id == 0 || this.objectBudget.print_type_id == null) {
					return true
				}
				// Comprobamos que se haya elegido si es con diseño o no
				if (this.objectBudget.design == null) {
					return true
				}
				// Comprobamos que tenga un cliente de la base de datos seleccionada
				if (this.objectBudget.client_id == null) {
					return true
				}
				if (this.objectBudget.contact.trim().length < 5) {
					return true
				}
				if (this.objectBudget.phone.trim().length < 9) {
					return true
				}
				if (!this.validateEmail(this.objectBudget.email)) {
					return true
				}
			}
			return false
		},
		listEquipmentsFilter() {
			return this.listEquipments
		},
		listFinishingsFilter() {
			return this.listFinishings
		},
		listPrintTypesFilter() {
			return this.listPrintTypes
		},
		listTecnologiesFilter() {
			return this.listTecnologies
		},
		listTypePapersFilter() {
			return this.listTypePapers
		},
		visibleAddOtherComponent() {
			let i = this.listPrintTypesFilter.map(pt => parseInt(pt.id)).indexOf(parseInt(this.objectBudget.print_type_id))
			if (i > -1) {
				if (this.listPrintTypesFilter[i].multi_component == 1) {
					return true
				}
			}
			return false
		},
	},
	methods: {
		addNewItemBlankObjectBudgetComponentLocal() {
			this.addNewItemBlankObjectBudgetComponent()
			this.componentIndexSelected = this.objectBudget.components.length - 1
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllClientsResult': this.getAllClientsResult(response); break;
				case 'getPrintTypesResult': this.getPrintTypesResult(response); break;
				case 'getEquipmentsResult': this.getEquipmentsResult(response); break;
				case 'getFinishsResult': this.getFinishsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearDataObjectBudgetClient(e) {
			if (e.keyCode != 13) {
				this.objectBudget.client_id = null
				this.objectBudget.client = null
			}
		},
		backTabIdSelected() {
			if (this.tabWinzarIndexSelected > 0)
				this.tabWinzarIndexSelected--
		},
		getClientLocal(search, typeSearchClient = 'FORM') {
			this.typeSearchClient = typeSearchClient
			this.searchClients = search
			if (this.searchClients.trim().length > 2 && this.loadingClients == false && this.objectBudget.client == null) {
				this.getAllClients()
			}
		},
		getAllClientsResultLocal(response) {
			if (this.listClients.length == 1) {
				if (this.typeSearchClient == 'FORM') {
					this.setObjectBurgetClient(this.listClients[0])
					setTimeout(() => { this.$refs.inputContact.focus() }, 50)
				}
			} else if (this.listClients.length > 1) {
				this.searchClientsModal = this.searchClientsLocal
				this.searchClientsLocal = ''
				this.$refs.btnShowClientModal.click()
			} else {
				if (this.lastMessage != 'CLIENTNOTFOUND') {
					if (typeSearchClient == 'FORM') {
						this.lastMessage = 'CLIENTNOTFOUND'
						this.showMsgErrorHandling('No Encontrado', 'No se encontró ningún cliente con estos datos', 'warn', 5000, 'top right')
					}
				} else {
					this.lastMessage = ''
				}
			}
		},
		getEquipmentsResultLocal(response) { },
		changeComponentIndexSelected(i) {
			this.componentIndexSelected = i
		},
		deleteItemObjectBudgetComponentLocal(i) {
			this.objectBudget.components.splice(i, 1)
			if (i != 0) {
				this.componentIndexSelected = i - 1
			} else {
				this.componentIndexSelected = 0
			}
		},
		getPrintTypesResultLocal(response) { },
		initModuleLocal() {
			this.setBudgetWizardComponentInit()
		},
		nextTabIdSelected() {
			if (this.tabWinzarIndexSelected < (this.listTabs.length - 1))
				this.tabWinzarIndexSelected++
		},
		resetFormBudget() {
			this.clearObjectBudget()
			this.dimensions = ''
			this.client = ''
			this.tabWinzarIndexSelected = 0
			this.componentIndexSelected = 0
		},
		selectClientLocal(c) {
			this.setObjectBurgetClient(c)
			this.$refs.btnCloseClientModal.click()
			setTimeout(() => { this.$refs.inputContact.focus() }, 50)
		},
		setObjectBurgetClient(c) {
			this.objectBudget.client_id = this.listClients[0].id
			this.objectBudget.client = this.listClients[0]
			this.objectBudget.contact = this.listClients[0].name + ' ' + this.listClients[0].surname
			this.objectBudget.phone = this.listClients[0].phone
			this.objectBudget.email = this.listClients[0].email
			this.searchClientsLocal = this.listClients[0].name + ' ' + this.listClients[0].surname
		},
		setBudgetWizardComponentInit() {
			this.resetFormBudget()
			this.getPrintTypes()
			this.getEquipments('1,2')
			this.getFinishs('1,2')
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>

<style lang="scss"></style>
