var store = {
	data() {
		return {
			listStores: [],
			paginationStores: [],
			loadingStores: false
		}
	},
	methods: {
		getStores() {
			this.loadingStores = true
			this.listStores = []
			this.paginationStores = []
			let url = this.getApiBackendNew + 'stores'
			this.axiosActionIS(url, 'GET', 'getStoresResult')
		},
		getStoresResult(response) {
			this.loadingStores = false
			if (response.data.success == true) {
				this.listStores = response.data.data
				this.paginationStores = response.data.paginate
			}
			this.getStoresResultLocal(response)
		},
		getStoresResultLocal(response) {
			console.log('getStoresResultLocal no desarrollado', response)
		}
	},
};

export default store;