<template>
	<div id="materialComponent">
		<button ref="btnShowMaterial" @click="showMaterialLocal" v-show="false">Show Material</button>
		<div v-show="searchingMaterial == true">
			<mensagge-loading-component class="bg-aasinet text-white" :text="'Buscando Material ' + id">
			</mensagge-loading-component>
		</div>
		<div>
			<div class="row mb-2">
				<div :class="positionClassLocal(1)">
					<div class="mb-1">
						<label class="form-label" for="tipocompSelect">Tipo de Material:</label>
						<select class="form-select form-select-sm" aria-label=".form-select-sm example"
							:disabled="disabledAll" v-model="objectMaterial.material_type_id"
							@change="changeObjectMaterialmaterialTypeId">
							<option v-for="(mt, i) in listMaterialTypesFilter" :key="i" :value="mt.id">{{ mt.name }}
							</option>
						</select>
					</div>
				</div>
				<div :class="positionClassLocal(1)" v-show="listMaterialTypeClassificationsFilter.length > 0">
					<div class="mb-1">
						<label class="form-label" for="nrocompInput">Tipo {{ nameMaterialTypeSelected }}:</label>
						<select class="form-select form-select-sm" aria-label=".form-select-sm example"
							:disabled="disabledAll" v-model="objectMaterial.material_type_classification_id"
							@change="changeObjectMaterialmaterialTypeClassificationId">
							<option v-for="(mtc, i) in listMaterialTypeClassificationsFilter" :key="i" :value="mtc.id">
								{{ mtc.name }}
							</option>
						</select>
					</div>
				</div>
				<div :class="positionClassLocal(1)" v-show="listMaterialTypeSubClassificationsFilter.length > 0">
					<div class="mb-1">
						<label class="form-label" for="tipocompSelect">Tipo {{ nameMaterialTypeClassificationSelected
							}}:</label>
						<select class="form-select form-select-sm" aria-label=".form-select-sm example"
							:disabled="disabledAll" v-model="objectMaterial.material_type_sub_classification_id"
							@change="changeObjectMaterialmaterialTypeSubClassificationId">
							<option v-for="(mtsc, i) in listMaterialTypeSubClassificationsFilter" :key="i"
								:value="mtsc.id">{{ mtsc.name }}
							</option>
						</select>
					</div>
				</div>
				<div :class="positionClassLocal(1)">
					<label class="form-label" for="tipocompSelect">Nombre:</label>
					<input class="form-control form-control-sm" :disabled="disabledAll" v-model="objectMaterial.name">
				</div>
				<div :class="positionClassLocal(1)">
					<label class="form-label" for="tipocompSelect">Descripción:</label>
					<input class="form-control form-control-sm" :disabled="disabledAll"
						v-model="objectMaterial.description">
				</div>
				<div :class="positionClassLocal(1)">
					<div class="row">
						<div class="col-4">
							<label class="form-label" for="tipocompSelect">Ancho:</label>
							<input class="form-control form-control-sm" :disabled="disabledAll"
								v-model="objectMaterial.width">
						</div>
						<div class="col-4">
							<label class="form-label" for="tipocompSelect">Alto:</label>
							<input class="form-control form-control-sm" :disabled="disabledAll"
								v-model="objectMaterial.height">
						</div>
						<div class="col-4">
							<label class="form-label" for="tipocompSelect">Gramaje:</label>
							<input class="form-control form-control-sm" :disabled="disabledAll"
								v-model="objectMaterial.thickness">
						</div>
					</div>
				</div>
				<div :class="positionClassLocal(1)">
					<div class="mb-1">
						<label class="form-label" for="tipocompSelect">Unidad de Medida:</label>
						<select class="form-select form-select-sm" aria-label=".form-select-sm example"
							:disabled="disabledAll" v-model="objectMaterial.measurement_unit_id">
							<option v-for="(mu, i) in listMeasurementUnitsFilter" :key="i" :value="mu.id">{{ mu.name }}
							</option>
						</select>
					</div>
				</div>
				<div :class="positionClassLocal(1)">
					<div class="mb-1">
						<label class="form-label" for="tipocompSelect">Cta Cble:</label>
						<select class="form-select form-select-sm fs--1" @change="setObjectMaterialAccount"
							v-model="objectMaterial.account_Code" :disabled="disabledAll">
							<option v-for="(a, i) in listAccountsFilter" :key="i" :value="a.Code">
								{{ a.Code }} - {{ a.Name }}
							</option>
						</select>
					</div>
				</div>
				<div :class="positionClassLocal(1)">
					<div class="mb-1">
						<label class="form-label" for="tipocompSelect">Cta Cte:</label>
						<select class="form-select form-select-sm fs--1" @change="setObjectMaterialSubAccount"
							v-model="objectMaterial.sub_account_Code" :disabled="disabledAll">
							<option v-for="(a, i) in listSubAccountsFilter" :key="i" :value="a.Code">
								{{ a.Code }} - {{ a.Name }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<hr>
			<div class="text-end">
				<button class="btn btn-primary btn-sm" :disabled="disabledBtnSaveMaterial" @click="saveMaterialLocal">
					<span v-show="savingMaterial == false">Guardar</span>
					<span v-show="savingMaterial == true">Guardando...</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import material from '@/mixins/materials'
import materialType from '@/mixins/materials/materialType.js'
import measurementUnit from '@/mixins/generals/measurementUnit.js'
import account from '@/mixins/accounting/account.js'

import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'MaterialComponent',
	mixins: [mixin, axiosAction, errors, material, materialType, measurementUnit, account],
	props: ['id', 'inputPositionColumn'],
	data() {
		return {
		}
	},
	components: {
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
		disabledAll() {
			if (this.searchingMaterial == true) {
				return true
			}
			return false
		},
		disabledBtnSaveMaterial() {
			if (this.disabledAll == true) {
				return true
			}
			if (this.validateFormObjectMaterial == false) {
				return true
			}
			if (this.savingMaterial == true) {
				return true
			}
			return false
		},
		listAccountsFilter() {
			return this.listAccounts.filter(a => { return a.AcceptEntries == 1 })
		},
		listSubAccountsFilter() {
			let list = []
			if (this.objectMaterial.account != null && this.objectMaterial.account.AcceptEntries == 1 && this.objectMaterial.account.type_sub_account_Code != null) {
				list = this.listSubAccounts.filter(sa => {
					return (sa.type_sub_account_Code == this.objectMaterial.account.type_sub_account_Code)
				})
			}
			return list
		},
		listMaterialTypesFilter() {
			return this.listMaterialTypes
		},
		listMaterialTypeClassificationsFilter() {
			return this.listMaterialTypeClassifications.filter(ltc => {
				return (ltc.material_type_id == this.objectMaterial.material_type_id)
			})
		},
		listMaterialTypeSubClassificationsFilter() {
			return this.listMaterialTypeSubClassifications.filter(ltsc => {
				return (ltsc.material_type_classification_id == this.objectMaterial.material_type_classification_id)
			})
		},
		listMeasurementUnitsFilter() {
			return this.listMeasurementUnits
		},
		nameMaterialTypeSelected() {
			let i = this.listMaterialTypes.map(mt => parseInt(mt.id)).indexOf(parseInt(this.objectMaterial.material_type_id))
			if (i > -1) {
				return this.listMaterialTypes[i].name
			}
			return null
		},
		nameMaterialTypeClassificationSelected() {
			let i = this.listMaterialTypeClassifications.map(mt => parseInt(mt.id)).indexOf(parseInt(this.objectMaterial.material_type_classification_id))
			if (i > -1) {
				return this.listMaterialTypeClassifications[i].name
			}
			return null
		},
		validateFormObjectMaterial() {
			if (this.objectMaterial.material_type_id == null) {
				return false
			}
			if (this.objectMaterial.material_type_classification_id == null && this.listMaterialTypeClassificationsFilter.length > 0) {
				return false
			}
			if (this.objectMaterial.material_type_sub_classification_id == null && this.listMaterialTypeSubClassificationsFilter.length > 0) {
				return false
			}
			if (this.objectMaterial.name.trim().length < 5) {
				return false
			}
			return true
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAccountsResult': this.getAccountsResult(response); break;
				case 'getSubAccountsResult': this.getSubAccountsResult(response); break;
				case 'getMaterialTypesResult': this.getMaterialTypesResult(response); break;
				case 'getMaterialTypeClassificationsResult': this.getMaterialTypeClassificationsResult(response); break;
				case 'getMaterialTypeSubClassificationsResult': this.getMaterialTypeSubClassificationsResult(response); break;
				case 'getMeasurementUnitsResult': this.getMeasurementUnitsResult(response); break;
				case 'saveMaterialResult': this.saveMaterialResult(response); break;
				case 'showMaterialResult': this.showMaterialResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		changeObjectMaterialmaterialTypeId() {
			this.objectMaterial.material_type_classification_id = null
			this.objectMaterial.material_type_classification = null
			this.objectMaterial.material_type_sub_classification_id = null
			this.objectMaterial.material_type_sub_classification = null
			let i = this.listMaterialTypes.map(mt => parseInt(mt.id)).indexOf(parseInt(this.objectMaterial.material_type_id))
			if (i > -1) {
				this.objectMaterial.material_type = this.listMaterialTypes[i]
				if (this.objectMaterial.material_type.account_Code != null) {
					this.objectMaterial.account_Code = this.objectMaterial.material_type.account_Code
					this.objectMaterial.account = this.objectMaterial.material_type.account
				}
				if (this.objectMaterial.material_type.sub_account_Code != null) {
					this.objectMaterial.sub_account_Code = this.objectMaterial.material_type.sub_account_Code
					this.objectMaterial.sub_account = this.objectMaterial.material_type.sub_account
				}
			}
		},
		changeObjectMaterialmaterialTypeClassificationId() {
			this.objectMaterial.material_type_sub_classification_id = null
			this.objectMaterial.material_type_sub_classification = null
			let i = this.listMaterialTypeClassifications.map(mtc => parseInt(mtc.id)).indexOf(parseInt(this.objectMaterial.material_type_classification_id))
			if (i > -1) {
				this.objectMaterial.material_type_classification = this.listMaterialTypeClassifications[i]
				if (this.objectMaterial.material_type_classification.account_Code != null) {
					this.objectMaterial.account_Code = this.objectMaterial.material_type_classification.account_Code
					this.objectMaterial.account = this.objectMaterial.material_type_classification.account
				}
				if (this.objectMaterial.material_type_classification.sub_account_Code != null) {
					this.objectMaterial.sub_account_Code = this.objectMaterial.material_type_classification.sub_account_Code
					this.objectMaterial.sub_account = this.objectMaterial.material_type_classification.sub_account
				}
			}
		},
		changeObjectMaterialmaterialTypeSubClassificationId() {
			let i = this.listMaterialTypeSubClassifications.map(mtsc => parseInt(mtsc.id)).indexOf(parseInt(this.objectMaterial.material_type_sub_classification_id))
			if (i > -1) {
				this.objectMaterial.material_type_sub_classification = this.listMaterialTypeSubClassifications[i]
				if (this.objectMaterial.material_type_sub_classification.account_Code != null) {
					this.objectMaterial.account_Code = this.objectMaterial.material_type_sub_classification.account_Code
					this.objectMaterial.account = this.objectMaterial.material_type_sub_classification.account
				}
				if (this.objectMaterial.material_type_sub_classification.sub_account_Code != null) {
					this.objectMaterial.sub_account_Code = this.objectMaterial.material_type_sub_classification.sub_account_Code
					this.objectMaterial.sub_account = this.objectMaterial.material_type_sub_classification.sub_account
				}
				this.objectMaterial.name = this.objectMaterial.material_type_sub_classification.name + ' '
			}
		},
		clearObjectMaterialLocal() {
			this.clearObjectMaterial()
		},
		getAccountsResultLocal(response) { },
		getMaterialTypesResultLocal(response) { },
		getMaterialTypeClassificationsResultLocal(response) { },
		getMaterialTypeSubClassificationsResultLocal(response) { },
		getMeasurementUnitsResultLocal(response) { },
		getSubAccountsResultLocal(response) { },
		initModuleLocal() {
			this.setMaterialInit()
		},
		positionClassLocal(pos_id) {
			if (this.inputPositionColumn == true) {
				return 'col-12'
			}
			return 'col-md-6 col-12'
		},
		saveMaterialLocal() {
			if (this.validateFormObjectMaterial == true) {
				this.saveMaterial(this.objectMaterial)
			}
		},
		saveMaterialResultLocal(response) {
			if (response.data.success == true) {
				console.log(response.data)
				this.$emit('saveMaterial', response.data)
			}
		},
		setObjectMaterialAccount() {
			let i = this.listAccounts.map(a => a.Code).indexOf(this.objectMaterial.account_Code)
			if (i > -1) {
				this.objectMaterial.account = this.listAccounts[i]
				this.objectMaterial.sub_account_Code = null
				this.objectMaterial.sub_account = null
			}
		},
		setObjectMaterialSubAccount() {
			this.objectMaterial.sub_account = this.listSubAccounts.filter(sa => {
				return (sa.Code == this.objectMaterial.sub_account_Code && sa.type_sub_account_Code == this.objectMaterial.account.type_sub_account_Code)
			})
		},
		setMaterialInit() {
			this.getMaterialTypes()
			this.getMaterialTypeClassifications()
			this.getMaterialTypeSubClassifications()
			this.getMeasurementUnits()
			this.getAccounts()
			this.getSubAccounts()
		},
		showMaterialLocal() {
			this.clearObjectMaterialLocal()
			if (this.id > 0 && this.id != undefined) {
				this.showMaterial(this.id)
			}
		},
		showMaterialResultLocal(response) {

		},
		showMsgErrorHandlingLocal(error) {
			this.searchingMaterial = false
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>