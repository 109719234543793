var equipment = {
	data() {
		return {
			loadingEquipments: false,
			listEquipments: [],
			objectEquipment: {
				exists: 0,
				id: 0,
				name: '',
				equipment_type_id: null,
				equipment_type: null,
				brand_id: null,
				brand: null,
				model: null,
				status_id: null,
				status: null,
				created_at: null,
				updated_at: null
			},
			searchEquipment: '',
			paginationListEquipments: []
		}
	},
	methods: {
		clearObjectEquipment() {
			this.objectEquipment.exists = 0
			this.objectEquipment.id = 0
			this.objectEquipment.name = ''
			this.objectEquipment.equipment_type_id = null
			this.objectEquipment.equipment_type = null
			this.objectEquipment.brand_id = null
			this.objectEquipment.brand = null
			this.objectEquipment.model = null
			this.objectEquipment.status_id = null
			this.objectEquipment.status = null
			this.objectEquipment.created_at = null
			this.objectEquipment.updated_at = null
		},
		getEquipments(equipmentTypeIds = '', page = 1, qRow = '') {
			this.loadingEquipments = true
			this.listEquipments = []
			this.paginationListEquipments = []
			let parameters = '?search=' + this.searchEquipment + '&page=' + page + '&qRow=' + qRow + '&equipmentTypeIds=' + equipmentTypeIds
			let url = this.getApiBackendNew + 'equipments' + parameters
			this.axiosActionIS(url, 'GET', 'getEquipmentsResult')

		},
		getEquipmentsResult(response) {
			this.loadingEquipments = false
			if (response.data.success == true) {
				this.listEquipments = response.data.data
				this.paginationListEquipments = response.data.paginate
			}
			this.getEquipmentsResultLocal(response)
		},
		getEquipmentsResultLocal(response) {
			console.log('getEquipmentsResultLocal no desarollado', response)
		}
	},
};

export default equipment;