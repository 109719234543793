var materialStoreRackSideRowColumn = {
	data() {
		return {
			listMaterialStoreRackSideRowColumns: [],
			loadingMaterialStoreRackSideRowColumns: false
		}
	},
	methods: {
		getMaterialStoreRackSideRowColumnsByMaterialId(materialId, all = false) {
			this.listMaterialStoreRackSideRowColumns = []
			this.loadingMaterialStoreRackSideRowColumns = true
			let param = '?all=' + all
			let url = this.getApiBackendNew + 'material/' + materialId + '/storeRackSideRowColumns' + param
			this.axiosActionIS(url, 'GET', 'getMaterialStoreRackSideRowColumnsByMaterialIdResult')
		},
		getMaterialStoreRackSideRowColumnsByMaterialIdResult(response) {
			this.loadingMaterialStoreRackSideRowColumns = false
			if (response.data.success == true) {
				this.listMaterialStoreRackSideRowColumns = response.data.data
			}
			this.getMaterialStoreRackSideRowColumnsByMaterialIdResultLocal(response)
		},
		getMaterialStoreRackSideRowColumnsByMaterialIdResultLocal(response) {
			console.log('getMaterialStoreRackSideRowColumnsByMaterialIdResultLocal no desarrollado', response)
		},
	},
};

export default materialStoreRackSideRowColumn;