var storeRackSideRowColumn = {
	data() {
		return {
			listStoreRackSideRowColumnContent: [],
			loadingStoreRackSideRowColumnContent: false
		}
	},
	methods: {
		getStoreRackSideRowColumnContent(ids) {
			this.listStoreRackSideRowColumnContent = []
			this.loadingStoreRackSideRowColumnContent = true
			let param = '?ids=' + ids
			let url = this.getApiBackendNew + 'storeRackSideRowColumnContent' + param
			this.axiosActionIS(url, 'GET', 'getStoreRackSideRowColumnContentResult')
		},
		getStoreRackSideRowColumnContentResult(response) {
			this.loadingStoreRackSideRowColumnContent = false
			if (response.data.success == true) {
				this.listStoreRackSideRowColumnContent = response.data.data
			}
			this.getStoreRackSideRowColumnContentResultLocal(response)
		},
		getStoreRackSideRowColumnContentResultLocal(response) {
			console.log('getStoreRackSideRowColumnContentResultLocal no desarollado', response)
		}
	},
};

export default storeRackSideRowColumn;