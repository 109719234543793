var measurementUnit = {
	data() {
		return {
			listMeasurementUnits: [],
			paginationListMeasurementUnits: []
		}
	},
	methods: {
		getMeasurementUnits(page) {
			this.loadingMeasurementUnits = true
			let url = this.getApiBackendNew + 'measurementUnits'
			this.axiosActionIS(url, 'GET', 'getMeasurementUnitsResult')
		},
		getMeasurementUnitsResult(response) {
			this.loadingMeasurementUnits = false
			if (response.data.success == true) {
				this.listMeasurementUnits = response.data.data
				this.paginationListMeasurementUnits = response.data.paginate
			}
			this.getMeasurementUnitsResultLocal(response)
		},
		getMeasurementUnitsResultLocal(response) {
			console.log('getMeasurementUnitsResultLocal no desarrollado', response)
		},
	},
};

export default measurementUnit;