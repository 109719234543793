var inventory = {
	data() {
		return {
			loadingListInventories: false,
			listInventories: [],
			objectInventory: {
				exists: 0,
				id: 0,
				description: '',
				year: null,
				inventory_date: null,
				status_id: null,
				status: null,
			},
			paginationListInventories: [],
			savingInventory: false,
			searchInventory: '',
			searchingInventory: false
		}
	},
	methods: {
		addItemListInventorys(m) {
			this.listInventories.unshift(m)
		},
		clearObjectInventory() {
			this.objectInventory.exists = 0
			this.objectInventory.id = 0
			this.objectInventory.description = ''
			this.objectInventory.year = null
			this.objectInventory.inventory_date = null
			this.objectInventory.status_id = null
			this.objectInventory.status = null
		},
		getInventories(page = 1) {
			this.listInventories = []
			this.paginationListInventories = []
			this.loadingListInventories = true
			let param = '?page=' + page + '&search=' + this.searchInventory
			let url = this.getApiBackendNew + 'inventories' + param
			this.axiosActionIS(url, 'GET', 'getInventoriesResult')
		},
		getInventoriesResult(response) {
			this.loadingListInventories = false
			if (response.data.success == true) {
				this.listInventories = response.data.data
				this.paginationListInventories = response.data.paginate
			}
			this.getInventoriesResultLocal(response)
		},
		getInventoriesResultLocal(response) {
			console.log('getInventoriesResultLocal no desarrollado', response)
		},
		saveInventory(i) {
			this.savingInventory = true
			let method = (i.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'inventory/'
			url += (method == 'POST') ? 'register' : i.id + '/update'
			this.axiosActionIS(url, method, 'saveInventoryResult', i)
		},
		saveInventoryResult(response) {
			this.savingInventory = false
			if (response.data.success == true) {
				this.setObjectInventory(response.data.data)
				if (response.data.message == 'UPDATED') {
					this.setItemListInventorys(response.data.data)
				}
			}
			this.saveInventoryResultLocal(response)
		},
		saveInventoryResultLocal(response) {
			console.log('saveInventoryResultLocal no desarrollado', response)
		},
		setItemListInventorys(i) {
			let index = this.listInventories.map(li => parseInt(li.id)).indexOf(parseInt(i.id))
			if (index > -1) {
				for (const property in this.listInventories[index]) {
					this.listInventories[index][property] = i[property]
				}
			}
		},
		setObjectInventory(m) {
			this.clearObjectInventory()
			for (const property in m) {
				this.objectInventory[property] = m[property]
			}
		},
		showInventory(id, resource = 'default') {
			this.searchingInventory = true
			let param = '?resource=' + resource
			let url = this.getApiBackendNew + 'inventory/' + id + param
			this.axiosActionIS(url, 'GET', 'showInventoryResult')
		},
		showInventoryResult(response) {
			this.searchingInventory = false
			if (response.data.success == true) {
				this.setObjectInventory(response.data.data)
			}
			this.showInventoryResultLocal(response)
		},
		showInventoryResultLocal(response) {
			console.log('showInventoryResultLocal no desarrollado', response)
		},
	},
};

export default inventory;