var employee = {
	data() {
		return {
			loadingEmployees: false,
			listEmployees: [],
			objectEmployee: {
				exists: 0,
				id: 0,
				name: '',
				surname: '',
				date_birth: null,
				document_number: null,
				country_id: null,
				status_id: 1,
				img: null,
				created_at: null,
				updated_at: null
			},
			searchEmployee: '',
			paginationListEmployees: []
		}
	},
	methods: {
		clearObjectEmployee() {
			this.objectEmployee.exists = 0
			this.objectEmployee.id = 0
			this.objectEmployee.name = ''
			this.objectEmployee.surname = ''
			this.objectEmployee.date_birth = null
			this.objectEmployee.document_number = null
			this.objectEmployee.country_id = null
			this.objectEmployee.status_id = 1
			this.objectEmployee.img = null
			this.objectEmployee.created_at = null
			this.objectEmployee.updated_at = null
		},
		getEmployees(page = 1) {
			this.loadingEmployees = true
			this.listEmployees = []
			this.paginationListEmployees = []
			let parameters = '?search=' + this.searchEmployee + '&page=' + page
			let url = this.getApiBackendNew + 'employees' + parameters
			this.axiosActionIS(url, 'GET', 'getEmployeesResult')

		},
		getEmployeesResult(response) {
			this.loadingEmployees = false
			if (response.data.success == true) {
				this.listEmployees = response.data.data
				this.paginationListEmployees = response.data.paginate
			}
			this.getEmployeesResultLocal(response)
		},
		getEmployeesResultLocal(response) {
			console.log('getEmployeesResultLocal no desarollado', response)
		}
	},
};

export default employee;