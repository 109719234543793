<template>
	<div id="inventory">
		<div class="card mb-3">
			<div class="bg-holder d-none d-lg-block bg-card"
				style="background-image:url(/assets/img/icons/spot-illustrations/corner-4.png);opacity: 0.7;">
			</div>
			<!--/.bg-holder-->

			<div class="card-body position-relative">
				<h5>
					{{ objectInventory.description }} ({{ objectInventory.year }}) #{{ objectInventory.id }}
					<small class="float-end">
						Fecha Inventario:
						{{ objectInventory.inventory_date }}
					</small>
				</h5>
				<div>
					<strong class="me-2 fs-1">Status: </strong>
					<span class="badge badge rounded-pill" :class="'badge-soft-' + objectInventory.status.color"
						v-if="objectInventory.status != null">{{
							objectInventory.status.name }}</span>
				</div>
			</div>
		</div>





		<div class="card overflow-hidden mt-3">
			<div class="card-header p-0 bg-body-tertiary scrollbar-overlay">
				<ul class="nav nav-tabs border-0 tab-tickets-status flex-nowrap" id="in-depth-chart-tab" role="tablist">
					<li class="nav-item text-nowrap" role="presentation" @click="tabSelected = 0">
						<a class="nav-link mb-0 d-flex align-items-center gap-2 py-3 px-x1 active" id="templates-tab"
							data-bs-toggle="tab" href="#templates" role="tab" aria-controls="templates"
							aria-selected="true">
							<span :class="(tabSelected == 0) ? 'text-primary' : 'text-600'">
								<i class="far fa-list-alt icon"></i>
							</span>
							<h6 class="mb-0" :class="(tabSelected == 0) ? 'text-900' : 'text-600'">Plantillas</h6>
						</a>
					</li>
					<li class="nav-item text-nowrap" role="presentation" @click="tabSelected = 1"><a
							class="nav-link mb-0 d-flex align-items-center gap-2 py-3 px-x1" id="details-tab"
							data-bs-toggle="tab" href="#details" role="tab" aria-controls="details"
							aria-selected="false">
							<span :class="(tabSelected == 1) ? 'text-primary' : 'text-600'">
								<i class="fas fa-check icon"></i>
							</span>
							<h6 class="mb-0" :class="(tabSelected == 1) ? 'text-900' : 'text-600'">Detalle</h6>
						</a></li>
					<li class="nav-item text-nowrap" role="presentation" @click="tabSelected = 2"><a
							class="nav-link mb-0 d-flex align-items-center gap-2 py-3 px-x1" id="resume-tab"
							data-bs-toggle="tab" href="#resume" role="tab" aria-controls="resume" aria-selected="false">
							<span :class="(tabSelected == 2) ? 'text-primary' : 'text-600'">
								<i class="fas fa-envelope-open-text icon"></i>
							</span>
							<h6 class="mb-0" :class="(tabSelected == 2) ? 'text-900' : 'text-600'">Resumen</h6>
						</a></li>
				</ul>
			</div>
			<div class="card-body p-2 border-bottom border-dashed">
				<div class="tab-content fs--1">
					<div class="tab-pane active" id="templates" role="tabpanel" aria-labelledby="templates-tab">
						<inventory-template-list-component :inventoryId="id"></inventory-template-list-component>
					</div>
					<div class="tab-pane" id="details" role="tabpanel" aria-labelledby="details-tab">
						<inventory-template-detail-list-component :inventoryId="id"
							:disabledInputCountEnd="(objectInventory.status_id != 65)"></inventory-template-detail-list-component>
					</div>
					<div class="tab-pane" id="resume" role="tabpanel" aria-labelledby="resume-tab">
						<div class="row mx-0 border-bottom border-dashed">
							<div class="col-md-6 p-x1 border-end-md border-bottom border-bottom-md-0 border-dashed">
								<h6 class="fs--1 mb-3">Tickets reopened Split by Source</h6>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Email</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="40"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:40%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">40</p>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Phone</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="40"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:40%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">40</p>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Website</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="50"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:50%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">50</p>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Chat</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="20"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:20%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">20</p>
									</div>
								</div>
							</div>
							<div class="col-md-6 p-x1">
								<h6 class="fs--1 mb-3">Tickets reopened Split by Priority</h6>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Urgent</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="90"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:90%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">90</p>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Medium</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="50"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:50%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">50</p>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">High</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="80"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:80%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">80</p>
									</div>
								</div>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Low</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="30"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:30%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">30</p>
									</div>
								</div>
							</div>
						</div>
						<div class="row mx-0">
							<div class="col-md-6 p-x1 border-end-md border-bottom border-bottom-md-0 border-dashed">
								<h6 class="fs--1 mb-3">Tickets reopened Split by Status</h6>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Open</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="40"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:40%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">40</p>
									</div>
								</div>
							</div>
							<div class="col-md-6 p-x1">
								<h6 class="fs--1 mb-3">Tickets reopened Split by Category</h6>
								<div class="row mt-2">
									<div class="col-3 col-sm-2 col-md-3 col-lg-2">
										<p class="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">Question</p>
									</div>
									<div class="col-9 col-sm-10 col-md-9 col-lg-10 d-flex align-items-center">
										<div class="progress bg-200 w-100 rounded-pill" role="progressbar"
											aria-label="Basic example" style="height:6px" aria-valuenow="25"
											aria-valuemin="0" aria-valuemax="100">
											<div class="progress-bar rounded-pill animated-progress-bar"
												style="--falcon-progressbar-width:25%"></div>
										</div>
										<p class="mb-0 fs--1 ps-3 fw-semi-bold text-600">25</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import inventories from '@/mixins/inventories'

import InventoryTemplateListComponent from '@/components/InventoryTemplateListComponent.vue'
import InventoryTemplateDetailListComponent from '@/components/InventoryTemplateDetailListComponent.vue'

export default {
	name: 'Inventory',
	mixins: [mixin, axiosAction, errors, inventories],
	props: ['id'],
	data() {
		return {
			tabSelected: 0
		}
	},
	components: {
		'inventory-template-list-component': InventoryTemplateListComponent,
		'inventory-template-detail-list-component': InventoryTemplateDetailListComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'showInventoryResult': this.showInventoryResult(response); break;
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setInventoryModule()
		},
		setInventoryModule() {
			this.tabSelected = 0
			if (this.id > 0) {
				this.showInventory(this.id)
			}
		},
		showInventoryResultLocal(response) { }

	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
