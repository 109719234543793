<template>
	<div id="storeRackSideComponent">
		<button class="btn btn-sm btn-primary" ref="btnShowStoreRackSide" @click="showStoreRackSideLocal"
			v-show="false">Show Store Rack Side</button>
		<button class="btn btn-sm btn-primary" ref="btnClearStoreRackSideComponent" @click="clearStoreRackSideLocal"
			v-show="false">Clear Store Rack Side</button>
		<button class="btn btn-sm btn-primary" ref="btnGetSelectedNames" @click="getSelectedNames" v-show="false">Get
			Name Selected Store Rack Side</button>
		<div class="text-center fs--1" v-show="searchingStoreRackSide">
			<span class="fa-spin">
				<i class="fas fa-spinner"></i>
				<i class="fas fa-circle-notch" v-show="false"></i>
			</span>
			<span>Buscando datos...</span>
		</div>
		<div v-show="!searchingStoreRackSide" :class="position">
			<div v-if="objectStoreRackSide.store_rack != null && hideName != true" class="mb-3 fs-1 text-center">
				<i class="fas fa-table" :style="'color: ' + objectStoreRackSide.color + ';'"></i> {{
					objectStoreRackSide.store_rack.name }} {{ objectStoreRackSide.name }}
			</div>
			<table class="fs--1 m-a border border-2">
				<tr v-for="(r, i) in objectStoreRackSide.rows.slice().reverse()" :key="i">
					<td v-for="(c, ind) in columnReverse(r.columns)" :key="ind" :colspan="c.colspan"
						class="border border-dashed text-center" :class="classTD(c)" @click="clickLocal(c)">
						{{ c.name }}-{{ r.name }}
					</td>
				</tr>
			</table>
		</div>
		<button class="btn btn-sm btn-primary" ref="btnGetSelectedIds" @click="getSelectedIds" v-show="false">
			Get selected id
		</button>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import storeRackSide from '@/mixins/stores/storeRackSide.js'

export default {
	name: 'StoreRackSideComponent',
	mixins: [mixin, axiosAction, errors, storeRackSide],
	props: ['storeRackSide', 'storeRackSideRowColumnIds', 'multipleSelection', 'infoChange', 'small', 'hideName', 'position'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		listObjectStoreRackSideRowsFilter() {
			return (this.objectStoreRackSide.reverse == 0) ? this.objectStoreRackSide.rows.slice().reverse() : this.objectStoreRackSide.rows
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'showStoreRackSideResult': this.showStoreRackSideResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		classTD(c) {
			let _class = (this.small == true) ? 'py-1 px-2' : 'py-2 px-4'
			_class = (c.selected == true) ? _class + ' bg-info text-white' : _class
			_class = (this.multipleSelection == true) ? _class + ' pointer' : _class
			return _class
		},
		clearStoreRackSideLocal() {
			this.clearObjectStoreRackSide()
			if (this.infoChange == true) {
				this.getSelectedIds()
			}
		},
		clickLocal(c) {
			if (this.multipleSelection == true) {
				c.selected = !c.selected
				if (this.infoChange == true) {
					this.getSelectedIds()
				}
			}
		},
		columnReverse(c) {
			return (this.objectStoreRackSide.reverse == 1) ? c.slice().reverse() : c
		},
		getSelectedIds() {
			let list = []
			this.objectStoreRackSide.rows.forEach(r => {
				r.columns.forEach(c => {
					if (c.selected == true) {
						list.push(c.id)
					}
				})
			})
			this.$emit('getSelectedIds', list)
			return list
		},
		getSelectedNames() {
			let list = []
			this.objectStoreRackSide.rows.forEach(r => {
				r.columns.forEach(c => {
					if (c.selected == true) {
						list.push(c.name + '-' + r.name)
					}
				})
			})
			this.$emit('getSelectedNames', list)
			return list
		},
		selectColumns() {
			let i = -1
			this.objectStoreRackSide.rows.forEach(r => {
				r.columns.forEach(c => {
					i = this.storeRackSideRowColumnIds.map(id => parseInt(id)).indexOf(parseInt(c.id))
					if (i > -1) {
						c.selected = true
					}
				})
			})
		},
		showStoreRackSideLocal() {
			this.clearStoreRackSideLocal()
			if ((this.storeRackSide != null) && (this.storeRackSide.id > 0)) {
				this.showStoreRackSide(this.storeRackSide.id, 'children')
			}
		},
		showStoreRackSideResultLocal(response) {
			if (response.data.success == true) {
				if (this.storeRackSideRowColumnIds != undefined) {
					this.selectColumns()
				}
			}
		},
		initModuleLocal() {
			this.setNombreInit()
		},
		setNombreInit() { },
		showNombreLocal() { },
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>

<style lang="scss"></style>