<template>
	<div id="inventoryListComponent">
		<div v-show="loadingListInventories == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Titulo">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Titulo</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchInventory"
										v-model="searchInventory" @focus="$event.target.select()">
									<div class="input-group-text bg-transparent pointer"
										@click="getInventoriesLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showInventoryLocal(0)" ref="btnShowInventory" data-bs-toggle="offcanvas"
								data-bs-target="#editInventoryOffCanvasRight">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900">Descripción</th>
								<th class="text-900 text-center">Año</th>
								<th class="text-900 text-center">Fecha Inventario</th>
								<th class="text-900 text-center">Estado</th>
								<th class="text-900 text-center"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(i, index) in listInventoriesFilter" :key="index">
								<td class="text-center">{{ i.id }}</td>
								<td>{{ i.description }}</td>
								<td class="text-center">{{ i.year }}</td>
								<td class="text-center">{{ i.inventory_date }}</td>
								<td class="text-center">
									<span class="badge badge rounded-pill d-block"
										:class="'badge-soft-' + i.status.color">{{ i.status.name }}</span>
								</td>
								<td>
									<span class="float-end position-absolute end-0 me-2">
										<router-link :to="'/inventory/' + i.id" class=" text-info">
											<i class="fas fa-list-ol"></i>
										</router-link>
										<span @click="showInventoryLocal(i.id)" class="ms-1 pointer"
											data-bs-toggle="offcanvas" data-bs-target="#editInventoryOffCanvasRight"
											title="Editar">
											<i class="far fa-eye text-primary"></i>
										</span>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListInventories" @getAllList="getInventoriesLocal">
					</pagination>
				</div>
			</div>
		</div>

		<div class="offcanvas offcanvas-end" id="editInventoryOffCanvasRight" tabindex="-1"
			ref="editInventoryOffCanvasRight" aria-labelledby="editInventoryOffCanvasRightLabel">
			<div class="offcanvas-header">
				<h5 id="editInventoryOffCanvasRightLabel">
					<span v-show="objectInventory.id == 0">
						Nuevo Inventario
					</span>
					<span v-show="objectInventory.id > 0">
						Inventario Año {{ objectInventory.year }}
					</span>
				</h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					ref="btnCloseNewJourlaItemOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<div class="mb-3">
					<label class="form-label" for="exampleFormSelect">Año {{ objectInventory.year }}</label>
					<select class="form-select form-select-sm fs--1" v-model="objectInventory.year">
						<option disabled :value="null">Seleccione el año</option>
						<option v-for="(y, i) in listYearsFilter" :key="i" :value="y">{{ y }}</option>
					</select>
				</div>
				<div class="mb-3">
					<label class="form-label" for="exampleFormSelect">Descripción</label>
					<input class="form-control form-control-sm" v-model="objectInventory.description" type="text">
				</div>
				<div class="mb-3">
					<label class="form-label" for="exampleFormSelect">Fecha de Inventario</label>
					<input class="form-control form-control-sm" v-model="objectInventory.inventory_date" type="date">
				</div>
				<hr>
				<div class="text-end">
					<button class="btn btn-sm btn-primary" :disabled="disabledBtnSave" @click="saveInventoryLocal">
						<span v-show="savingInventory == false">Guardar</span>
						<span v-show="savingInventory == true">Guardando</span>
					</button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import inventories from '@/mixins/inventories'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'InventoryListComponent',
	mixins: [mixin, axiosAction, errors, inventories],
	props: [],
	data() {
		return {
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent,
	},
	computed: {
		disabledBtnSave() {
			if (this.savingInventory == true) {
				return true
			}
			if (this.validateFormObjectInventory != true) {
				return true
			}
			if (this.objectInventory.status_id == 66) {
				return true
			}
			return false
		},
		listInventoriesFilter() {
			return this.listInventories
		},
		listYearsFilter() {
			let list = []
			let currentYear = new Date()
			list.push(currentYear.getFullYear())
			list.push(currentYear.getFullYear() + 1)
			return list
		},
		validateFormObjectInventory() {
			if (this.objectInventory.year == null) {
				return false
			}
			if (this.objectInventory.description.trim().length < 5) {
				return false
			}
			if (this.objectInventory.inventory_date == null) {
				return false
			}
			return true
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getInventoriesResult': this.getInventoriesResult(response); break;
				case 'saveInventoryResult': this.saveInventoryResult(response); break;
				case 'showInventoryResult': this.showInventoryResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectInventoryLocal() {
			this.clearObjectInventory()
		},
		getInventoriesLocal(page = 1) {
			this.getInventories(page)
		},
		getInventoriesResultLocal(response) { },
		initModuleLocal() {
			this.setInventoryListInit()
		},
		saveInventoryLocal() {
			if (this.validateFormObjectInventory == true) {
				this.saveInventory(this.objectInventory)
			}
		},
		saveInventoryResultLocal(response) {
			if (response.data.success == true && this.objectInventory.id > 0) {
				if (response.data.message == 'CREATED') {
					this.addItemListInventorys(response.data.data)
				}
			}
		},
		setInventoryListInit() {
			this.getInventoriesLocal()
		},
		showMsgErrorHandlingLocal(err) {
			this.loadingListInventories = false
			this.savingInventory = false
			this.searchingInventory = false
			console.log(err)
		},
		showInventoryLocal(inventoryId) {
			this.clearObjectInventoryLocal()
			if (inventoryId > 0) {
				this.showInventory(inventoryId)
			}
		},
		showInventoryResultLocal(response) { }
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>