var printType = {
    data() {
        return {
            listPrintTypes: [
                { id: 1, name: 'Impreso en General', description: 'Cartas, cobres, tarjetas, papelería comercial, carteles, etiquetas, afiches, triptico, diptico, impresiones a un hoja en general', status_id: 1, status: { id: 1, name: 'Activo' } },
                { id: 2, name: 'Revista, libro y otros trabajas páginados | Multicomponentes', description: 'Revistas, libros, catálogos, folletos, calendarios. Todo tipo de impresos con más de 6 páginas | En general, trabajos con varios componentes impresos (segmentos)', status_id: 1, status: { id: 1, name: 'Activo' } },
                { id: 3, name: 'Talonario o juego de hojas', description: 'Talonario copiativos y no copiativos, Juegos de facturas y de otros impresos', status_id: 1, status: { id: 1, name: 'Activo' } },
                { id: 4, name: 'Impresión digital en gran formato (plotter)', description: '', status_id: 1, status: { id: 1, name: 'Activo' } },
                { id: 5, name: 'Reprografía en B/N y color', description: '', status_id: 1, status: { id: 1, name: 'Activo' } },
                { id: 6, name: 'Concepto libre', description: '', status_id: 1, status: { id: 1, name: 'Activo' } },
            ],
            paginationPrintType: [],
            searchPrintType: ''
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getPrintTypes(page = 1) {
            let param = '?search=' + this.searchPrintType + '&page=' + page
            let url = this.getApiBackendNew + 'printTypes' + param
            this.axiosActionIS(url, 'GET', 'getPrintTypesResult')
        },
        getPrintTypesResult(response) {
            if (response.data.success == true) {
                this.listPrintTypes = response.data.data
                this.paginationPrintType = response.data.paginate
            }
            this.getPrintTypesResultLocal(response)
        },
        getPrintTypesResultLocal(response) {
            console.log('getPrintTypesResultLocal no desarrollado', response)
        },
    }
};

export default printType;