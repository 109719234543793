var inventoryTemplateDetail = {
	data() {
		return {
			loadingInventoryTemplateDetails: false,
			listInventoryTemplateDetails: [],
			paginationListInventoryTemplateDetails: [],
			savingInventoryTemplateDetail: false,
			searchInventoryTemplateDetail: '',
			searchingInventoryTemplateDetail: false
		}
	},
	methods: {
		getInventoryTemplateDetails(inventoryId, page = 1, resource = 'List') {
			this.listInventoryTemplateDetails = []
			this.paginationListInventoryTemplateDetails = []
			this.loadingInventoryTemplateDetails = true
			let param = '?page=' + page + '&search=' + this.searchInventoryTemplateDetail + '&resource=' + resource
			let url = this.getApiBackendNew + 'inventory/' + inventoryId + '/template/details' + param
			this.axiosActionIS(url, 'GET', 'getInventoryTemplateDetailsResult')
		},
		getInventoryTemplateDetailsResult(response) {
			this.loadingInventoryTemplateDetails = false
			if (response.data.success == true) {
				this.listInventoryTemplateDetails = response.data.data
				this.paginationListInventoryTemplateDetails = response.data.paginate
			}
			this.getInventoryTemplateDetailsResultLocal(response)
		},
		getInventoryTemplateDetailsResultLocal(response) {
			console.log('getInventoryTemplateDetailsResultLocal no desarrollado', response)
		}
	},
};

export default inventoryTemplateDetail;