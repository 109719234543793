var shippingsRequest = {
	data() {
		return {
			dateFromShippingsRequest: '2024-01-01',
			dateToShippingsRequest: '2024-12-31 23:59:59',
			listShippingsRequest: [],
			loadingShippingsRequest: false,
			paginationListShippingsRequest: [],
			searchShippingsRequest: '',
			qrowsShippingRequest: 25,
			onlyMineShippingsRequest: false,
			gettingShippingRequest: false,
			objectShippingRequest: {
				exist: 0,
				id: 0,
				user_id: 0,
				user: null,
				commentary: '',
				total_price: 0,
				status_id: null,
				status: null,
				barcode: null,
				created_at: null,
				updated_at: null,
				recipient_data: {
					id: 0,
					shipping_request_id: 0,
					sendto: '',
					address: '',
					comuna_id: 0,
					comuna: null,
					phone: '',
					number_document: '',
					email: '',
					paid_shipping: 1,
					transport_id: 0,
					transport: null,
					office: 0
				},
				genesys_cabmov: [],
				trackings_shipping_request: [],
				legal_documents: [],
				orders: [],
				shipping: null,
			},
			shippingRequestSelected: 0,
			listStatusEditable: [9, 10],
			listStatusErasable: [9, 10, 18],
			savedShippingRequest: false,
			nameLocalStorageShippingRequestStatus: 'shippingRequest-status',
			selectedAllStatus: false
		}
	},
	methods: {
		addListShippingsRequest(newShippingRequest) {
			this.listShippingsRequest.unshift(newShippingRequest)
			if (this.listShippingsRequest.length > this.qrowsShippingRequest) {
				this.deleteItemListShippingsRequest(this.listShippingsRequest[this.qrowsShippingRequest].id)
			}
		},
		addListShippingRequestTrackingsShippingRequest(newShippingRequestTrackingsShippingRequest) {
			if (parseInt(this.objectShippingRequest.id) == parseInt(newShippingRequestTrackingsShippingRequest.shipping_request_id)) {
				this.objectShippingRequest.trackings_shipping_request.push(newShippingRequestTrackingsShippingRequest)
				if (newShippingRequestTrackingsShippingRequest.trackings_shipping_request.status_id != null) {
					this.objectShippingRequest.status = newShippingRequestTrackingsShippingRequest.trackings_shipping_request.status
					this.objectShippingRequest.status_id = newShippingRequestTrackingsShippingRequest.trackings_shipping_request.status.id
				}
			}
		},
		addShippingRequestTrackingsShippingRequest(id, trackingsShippingRequestId, observation) {
			this.savedShippingRequest = true
			let param = {
				'trackingsShippingRequestId': trackingsShippingRequestId,
				'observation': observation
			}
			let url = this.getApiBackendNew + 'shippingRequest/' + id + '/trackingsShippingRequest/register'
			this.axiosActionIS(url, 'POST', 'addShippingRequestTrackingsShippingRequestResult', param)
		},
		addShippingRequestTrackingsShippingRequestResult(response) {
			this.savedShippingRequest = false
			if (response.data.success == true) {
				this.addListShippingRequestTrackingsShippingRequest(response.data.data)
				this.updateStatusListShippingsRequest(response.data.data)
			}
			this.addShippingRequestTrackingsShippingRequestResultLocal(response)
		},
		addShippingRequestTrackingsShippingRequestResultLocal(response) {
			console.log('addShippingRequestTrackingsShippingRequestResultLocal no desarrollado', response)
		},
		clearObjectShippingRequest() {
			this.objectShippingRequest.exist = 0
			this.objectShippingRequest.id = 0
			this.objectShippingRequest.user_id = 0
			this.objectShippingRequest.user = null
			this.objectShippingRequest.commentary = ''
			this.objectShippingRequest.total_price = 0
			this.objectShippingRequest.status_id = null
			this.objectShippingRequest.status = null
			this.objectShippingRequest.barcode = null
			this.objectShippingRequest.created_at = null
			this.objectShippingRequest.updated_at = null
			this.objectShippingRequest.recipient_data.id = 0
			this.objectShippingRequest.recipient_data.shipping_request_id = 0
			this.objectShippingRequest.recipient_data.sendto = ''
			this.objectShippingRequest.recipient_data.address = ''
			this.objectShippingRequest.recipient_data.comuna_id = 0
			this.objectShippingRequest.recipient_data.comuna = null
			this.objectShippingRequest.recipient_data.phone = ''
			this.objectShippingRequest.recipient_data.number_document = ''
			this.objectShippingRequest.recipient_data.email = ''
			this.objectShippingRequest.recipient_data.paid_shipping = 0
			this.objectShippingRequest.recipient_data.transport_id = 0
			this.objectShippingRequest.recipient_data.transport = {}
			this.objectShippingRequest.recipient_data.office = 0
			this.objectShippingRequest.genesys_cabmov = []
			this.objectShippingRequest.trackings_shipping_request = []
		},
		deleteItemListShippingsRequest(id) {
			let index = this.listShippingsRequest.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			let shippingsRequestDeleted = this.listShippingsRequest.splice(index, 1)
		},
		deleteShippingRequest(id) {
			let url = this.getApiBackendNew + 'shippingRequest/' + id + '/delete'
			this.axiosActionIS(url, 'DELETE', 'deleteShippingRequestResult')
		},
		deleteShippingRequestResult(response) {
			if (response.data.success == true) {
				this.deleteItemListShippingsRequest(response.data.data)
			}
			this.deleteShippingRequestResultLocal(response)
		},
		deleteShippingRequestResultLocal(response) {
			console.log('deleteShippingRequestResultLocal no desallado', response)
		},
		getAllShippingsRequest(page = 1, statusIds = []) {
			this.paginationListShippingsRequest = []
			this.loadingShippingsRequest = true
			let userId = (this.onlyMineShippingsRequest) ? this.auth.id : -1
			let parameters = '?search=' + this.searchShippingsRequest + '&page=' + page + '&fromDate=' + this.dateFromShippingsRequest + '&toDate=' + this.dateToShippingsRequest + '&userId=' + userId + '&qRow=' + this.qrowsShippingRequest + '&statusId=' + statusIds
			let url = this.getApiBackendNew + 'shippingsRequest' + parameters
			this.axiosActionIS(url, 'GET', 'getAllShippingsRequestResult')
		},
		getAllShippingsRequestResult(response) {
			this.loadingShippingsRequest = false
			if (response.data.success == true) {
				this.listShippingsRequest = response.data.data
				this.paginationListShippingsRequest = response.data.paginate
			}
			this.getAllShippingsRequestResultLocal(response)
		},
		getAllShippingsRequestResultLocal(response) {
			console.log('getAllShippingsRequestResultLocal no desarrollado', response)
		},
		saveShippingRequest(sr) {
			this.savedShippingRequest = true
			let url = this.getApiBackendNew + 'shippingRequest/'
			let method = 'POST'
			if (sr.id == 0) {
				url += 'register'
			} else if (sr.id > 0) {
				url += sr.id + '/update'
				method = 'PUT'
			}
			console.log(url, sr)
			this.axiosActionIS(url, method, 'saveShippingRequestResult', sr)
		},
		saveShippingRequestResult(response) {
			this.savedShippingRequest = false
			if (response.data.success == true) {
				console.group('ShippingRequest Nº ' + response.data.data.id + ' Guardado!!!')
				response.data.message.forEach(m => {
					console.log('Type => ' + m.type + ', message => ' + m.message)
				})
				console.groupEnd()
			}
			this.saveShippingRequestResultLocal(response)
		},
		saveShippingRequestResultLocal(response) {
			console.log('saveShippingRequestResultLocal no desarrollado', response)
		},
		setObjectShippingRequest(item) {
			let newItem = Object.create(item)
			for (const property in newItem) {
				this.objectShippingRequest[property] = newItem[property]
			}
		},
		showShippingRequest(id, resource = 'default') {
			this.gettingShippingRequest = true
			let url = this.getApiBackendNew + 'shippingRequest/' + id + '?resource=' + resource
			this.axiosActionIS(url, 'GET', 'showShippingRequestResult')
		},
		showShippingRequestResult(response) {
			this.gettingShippingRequest = false
			if (response.data.success == true) {
				this.setObjectShippingRequest(response.data.data)
			}
			this.showShippingRequestResultLocal(response)
		},
		showShippingRequestResultLocal(response) {
			console.log('showShippingRequestResultLocal no desarrollado', response)
		},
		showShippingRequestConsole() {
			console.log(this.objectShippingRequest)
		},
		toPrintLabelLocal(sr) {
			let arrayZPL = []
			let user = sr.user.name.split(' ')
			let initials = ''
			user.forEach(e => {
				if (e.length > 0) {
					initials += e.substring(0, 1).toUpperCase()
				}
			})
			if (sr.recipient_data != null) {
				arrayZPL.push({ 'name': 'sendto', 'value': this.cleanedStringZPL(sr.recipient_data.sendto).toUpperCase() })
				arrayZPL.push({ 'name': 'address', 'value': this.cleanedStringZPL(sr.recipient_data.address).toUpperCase() })
				arrayZPL.push({ 'name': 'comuna', 'value': this.cleanedStringZPL(sr.recipient_data.comuna.name).toUpperCase() })
				arrayZPL.push({ 'name': 'phone', 'value': sr.recipient_data.phone.toUpperCase() })
				arrayZPL.push({ 'name': 'number_document', 'value': this.formatRUT(sr.recipient_data.number_document) })
				arrayZPL.push({ 'name': 'email', 'value': sr.recipient_data.email })
				arrayZPL.push({ 'name': 'initials', 'value': initials.toUpperCase() })
				arrayZPL.push({ 'name': 'img_zpl', 'value': (sr.recipient_data.transport.img_zpl == null || sr.recipient_data.transport.img_zpl == '') ? sr.recipient_data.transport.name : sr.recipient_data.transport.img_zpl })
				arrayZPL.push({ 'name': 'paid_shipping_desc', 'value': (sr.recipient_data.paid_shipping == 0) ? 'POR PAGAR' : '' })
			}
			if (this.needGenesys && sr.genesys_cabmov.length > 0) {
				arrayZPL.push({ 'name': 'nrocomp', 'value': this.getNrocompGenesys(sr.genesys_cabmov, 'INT') })
				arrayZPL.push({ 'name': 'codbareti', 'value': sr.genesys_cabmov[0].codbareti })
			}
			arrayZPL.push({ 'name': 'codbareti2', 'value': sr.barcode + '0001' })
			arrayZPL.push({ 'name': 'nrocompLegal', 'value': this.getLegalDocumentNumberRel(sr, 'MAIN') })
			arrayZPL.push({ 'name': 'total_price', 'value': sr.total_price })

			this.replaceZPLVariableFromArray(arrayZPL)
			this.socketWebSocketServerPrinter(this.ZPLEnd(), localStorage.printerWebSocketServer)
		},
		updateItemListShippingsRequest(data) {
			let index = this.listShippingsRequest.map(e => parseInt(e.id)).indexOf(data.id)
			if (index > -1) {
				for (const property in data) {
					this.listShippingsRequest[index][property] = data[property]
				}
			}
		},
		updateStatusListShippingsRequest(newShippingRequestTrackingsShippingRequest) {
			if (newShippingRequestTrackingsShippingRequest.trackings_shipping_request.status_id != null) {
				let index = this.listShippingsRequest.map((el) => parseInt(el.id)).indexOf(parseInt(newShippingRequestTrackingsShippingRequest.shipping_request_id))
				if (index > -1) {
					this.listShippingsRequest[index].status_id = newShippingRequestTrackingsShippingRequest.trackings_shipping_request.status_id
					this.listShippingsRequest[index].status = newShippingRequestTrackingsShippingRequest.trackings_shipping_request.status
					this.listShippingsRequest[index].consider = (newShippingRequestTrackingsShippingRequest.trackings_shipping_request.status_id > 9) ? true : false
				}
			}
		}
	},
};

export default shippingsRequest;