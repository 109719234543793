<template>
	<div id="supplierComponent">
		<button ref="btnShowSupplier" @click="showSupplierLocal()" v-show="false">Show Supplier</button>
		<div class="fs--1">
			<div class="text-center py-2 fs--1 text-muted"
				v-show="loadingRequired == true || searchingSupplier == true">
				<span v-show="loadingRequired == true">
					Espere mientras cargamos los datos necesarios para este modulo
				</span>
				<span v-show="searchingSupplier == true">
					Espere mientras buscamos el cliente
				</span>
			</div>
			<div>
				<div class="row mb-2">
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Proveedor:</label>
							<input class="form-control form-control-sm fs--1" v-model="objectSupplier.name"
								:disabled="disabledAll" ref="inputObjectSupplierName">
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">RUT:</label>
							<input class="form-control form-control-sm fs--1" v-model="objectSupplier.document_number"
								:disabled="disabledAll" ref="inputObjectSupplierDocumentNumber">
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Dirección:</label>
							<input class="form-control form-control-sm fs--1" v-model="objectSupplier.address"
								@focus="$event.target.select()" :disabled="disabledAll">
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Comuna:</label>
							<select class="form-select form-select-sm fs--1" v-model="objectSupplier.comuna_id"
								:disabled="disabledAll">
								<option v-for="(c, i) in listComunasFilter" :key="i" :value="c.id">{{ c.name }}</option>
							</select>
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Telefono:</label>
							<input class="form-control form-control-sm fs--1" v-model="objectSupplier.phone"
								@focus="$event.target.select()" :disabled="disabledAll">
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Email:</label>
							<input class="form-control form-control-sm fs--1" v-model="objectSupplier.email"
								@focus="$event.target.select()" :disabled="disabledAll">
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Contacto:</label>
							<input class="form-control form-control-sm fs--1" v-model="objectSupplier.contact"
								@focus="$event.target.select()" :disabled="disabledAll"
								ref="inputObjectSupplierContact">
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Cta Cble:</label>
							<select class="form-select form-select-sm fs--1" @change="setObjectSupplierAccount"
								v-model="objectSupplier.account_Code" :disabled="disabledAll">
								<option v-for="(a, i) in listAccountsFilter" :key="i" :value="a.Code">
									{{ a.Code }} - {{ a.Name }}
								</option>
							</select>
						</div>
					</div>
					<div :class="positionClassLocal(1)">
						<div class="mb-1">
							<label class="form-label" for="tipocompSelect">Cte Cte:</label>
							<select class="form-select form-select-sm fs--1" @change="setObjectSupplierSubAccount"
								v-model="objectSupplier.sub_account_Code" :disabled="disabledAll">
								<option v-for="(a, i) in listSubAccountsFilter" :key="i" :value="a.Code">
									{{ a.Code }} - {{ a.Name }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div class="text-end">
				<button class="btn btn-primary btn-sm" :disabled="disabledBtnSaveSupplier" @click="saveSupplierLocal">
					<span v-show="savingSupplier == false">Guardar</span>
					<span v-show="savingSupplier == true">Guardando...</span>
				</button>
			</div>
		</div>
		<div class="row">
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import supplier from '@/mixins/generals/supplier.js'
import comuna from '@/mixins/generals/comuna.js'
import account from '@/mixins/accounting/account.js'

export default {
	name: 'SupplierComponent',
	mixins: [mixin, axiosAction, errors, supplier, comuna, account],
	props: ['id'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		listAccountsFilter() {
			return this.listAccounts
		},
		listSubAccountsFilter() {
			let list = []
			if (this.objectSupplier.account != null && this.objectSupplier.account.AcceptEntries == 1 && this.objectSupplier.account.type_sub_account_Code != null) {
				list = this.listSubAccounts.filter(sa => {
					return (sa.type_sub_account_Code == this.objectSupplier.account.type_sub_account_Code)
				})
			}
			return list
		},
		listComunasFilter() {
			return this.listComunas
		},
		loadingRequired() {
			if (this.loadingComunas == true) {
				return true
			} else if (this.loadingAccounts == true) {
				return true
			} else if (this.loadingSubAccounts == true) {
				return true
			} else {
				return false
			}
		},
		disabledAll() {
			if (this.loadingRequired == true) {
				return true
			}
			if (this.savingSupplier == true) {
				return true
			}
			return false
		},
		disabledBtnSaveSupplier() {
			if (this.disabledAll == true) {
				return true
			}
			if (this.validateFormSupplier == false) {
				return true
			}
			return false
		},
		validateFormSupplier() {
			if (this.objectSupplier.name.trim().length < 5) {
				return false
			}
			if (!this.checkDigitoRUT(this.objectSupplier.document_number) && !this.checkDigitCUIT(this.objectSupplier.document_number)) {
				return false
			}
			return true
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAccountsResult': this.getAccountsResult(response); break;
				case 'getAllComunasResult': this.getAllComunasResult(response); break;
				case 'getSubAccountsResult': this.getSubAccountsResult(response); break;
				case 'saveSupplierResult': this.saveSupplierResult(response); break;
				case 'showSupplierResult': this.showSupplierResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearObjectSupplierLocal() {
			this.clearObjectSupplier()
			this.setObjectSupplierAccount()
		},
		finishLoadRequiredSupplierComponent() {
			this.clearObjectSupplierLocal()
		},
		getAccountsResultLocal(response) {
			if (response.data.success == true) {
				this.clearObjectSupplierLocal()
			}
			this.showSupplierLocal()
		},
		getAllComunasResultLocal(response) { },
		getSubAccountsResultLocal(response) { },
		initModuleLocal() {
			this.setSupplierComponentInit()
		},
		positionClassLocal(pos_id) {
			if (this.inputPositionColumn == true) {
				return 'col-12'
			}
			return ''
		},
		saveSupplierLocal() {
			if (this.validateFormSupplier == true) {
				this.objectSupplier.document_number = this.objectSupplier.document_number.replaceAll('.', '')
				this.saveSupplier(this.objectSupplier)
			}
		},
		saveSupplierResultLocal(response) {
			if (response.data.success == true) {
				if (response.data.message == 'CREATED') {
					this.showMsgErrorHandling('CREADO', 'Se creó correctamente!', 'success', 5000, 'top right')
				} else if (response.data.message == 'UPDATED') {
					this.showMsgErrorHandling('ACTUALIZADO', 'Se actualizó correctamente!', 'info', 5000, 'top right')
				} else {
					this.showMsgErrorHandling('NO ACTUALIZADO', 'No se detecto nada diferente para actualizar', 'warn', 5000, 'top right')
				}
				this.$emit('saveSupplier', response.data)
			} else {
				this.showMsgErrorHandling(response.data.message, response.data.data, 'error', 5000, 'top right')
			}
		},
		setObjectSupplierAccount() {
			let i = this.listAccounts.map(a => a.Code).indexOf(this.objectSupplier.account_Code)
			if (i > -1) {
				this.objectSupplier.account = this.listAccounts[i]
				this.objectSupplier.sub_account_Code = null
				this.objectSupplier.sub_account = null
			}
		},
		setObjectSupplierSubAccount() {
			this.objectSupplier.sub_account = this.listSubAccounts.filter(sa => {
				return (sa.Code == this.objectSupplier.sub_account_Code && sa.type_sub_account_Code == this.objectSupplier.account.type_sub_account_Code)
			})
		},
		setSupplierComponentInit() {
			this.getAllComunas()
			this.getAccounts()
			this.getSubAccounts()
		},
		showMsgErrorHandlingLocal(err) {
			this.savingSupplier = false
		},
		showSupplierLocal() {
			this.clearObjectSupplierLocal()
			if (this.id != undefined && this.id > 0) {
				this.showSupplier(this.id)
			}
		},
		showSupplierResultLocal(response) { },
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>