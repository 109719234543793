<template>
	<div id="materialListComponent">
		<div v-show="loadingMaterials == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando materiales">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Materiales</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchMaterials"
										v-model="searchMaterials" @focus="$event.target.select()"
										@keyup.enter="getMaterialsLocal(1)" />
									<div class="input-group-text bg-transparent pointer" @click="getMaterialsLocal(1)">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showMaterialLocal(0)" ref="btnShowMaterial" data-bs-toggle="offcanvas"
								data-bs-target="#editMaterialOffCanvasRight">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900 text-center">ID</th>
								<th class="text-900 text-center">Tipo</th>
								<th class="text-900 text-center">Clasificación</th>
								<th class="text-900 text-center">Material</th>
								<th class="text-900 text-center">Dimensiones</th>
								<th class="text-900 text-center">Gramaje</th>
								<th class="text-900 text-center">Stock</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(m, i) in listMaterialsFilter" :key="i">
								<td class="text-center"><span>{{ m.id }}</span></td>
								<td class="text-center"><span>{{ m.material_type.name }}</span></td>
								<td class="text-center">
									<span v-if="m.material_type_classification_id != null">{{
										m.material_type_classification.name }}</span>
								</td>
								<td class="text-start"><span>{{ m.name }}</span></td>
								<td class="text-center">
									<span>{{ parseInt(m.width) }}</span>
									<span v-if="m.height != null">x{{ parseInt(m.height) }}</span>
								</td>
								<td class="text-center">
									<span>{{ parseInt(m.thickness) }}</span>
								</td>
								<td class="text-center">
									{{ m.stock }}
									<span class="visible-hover float-end position-absolute end-0 me-2">
										<span @click="deleteMaterialLocal(m.id)" class="pointer" title="Eliminar">
											<i class="far fa-trash-alt text-danger"></i>
										</span>
										<span @click="showMaterialLocal(m.id)" class="ms-1 pointer"
											data-bs-toggle="offcanvas" data-bs-target="#editMaterialOffCanvasRight"
											title="Editar">
											<i class="far fa-eye text-primary"></i>
										</span>
										<span @click="showMaterialHistoryStockLocal(m.id)" class="ms-1 pointer"
											title="Stock" data-bs-toggle="modal"
											data-bs-target="#materialHistoryStockModal">
											<i class="fas fa-pallet text-warning"></i>
										</span>
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListMaterials" @getAllList="getMaterialsLocal">
					</pagination>
				</div>
			</div>
		</div>

		<!-- -->
		<div class="offcanvas offcanvas-end" id="editMaterialOffCanvasRight" tabindex="-1"
			ref="editMaterialOffCanvasRight" aria-labelledby="editMaterialOffCanvasRightLabel">
			<div class="offcanvas-header">
				<h5 id="editMaterialOffCanvasRightLabel">Nuevo Material</h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					ref="btnCloseNewJourlaItemOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<material-component ref="materialComponent" :inputPositionColumn="true" :id="materialIdSelected"
					@saveMaterial="saveMaterialLocal"></material-component>
			</div>
		</div>

		<!-- Material History Stock Modal -->
		<div class="modal fade" id="materialHistoryStockModal" tabindex="-1"
			aria-labelledby="materialHistoryStockModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-body">
						<material-history-stock-component ref="materialHistoryStockComponent"
							:materialId="materialIdSelected"></material-history-stock-component>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import material from '@/mixins/materials'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import MaterialComponent from '@/components/MaterialComponent.vue'
import MaterialHistoryStockComponent from '@/components/MaterialHistoryStockComponent.vue'

export default {
	name: 'MaterialListComponent',
	mixins: [mixin, axiosAction, errors, material],
	props: [],
	data() {
		return {
			materialIdSelected: 0
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent,
		'material-component': MaterialComponent,
		'material-history-stock-component': MaterialHistoryStockComponent
	},
	computed: {
		listMaterialsFilter() {
			return this.listMaterials
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getMaterialsResult': this.getMaterialsResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		deleteMaterialLocal(id) { },
		getMaterialsLocal(page = 1) {
			this.getMaterials(page)
		},
		getMaterialsResultLocal(response) {
		},
		initModuleLocal() {
			this.setMaterialInit()
		},
		saveMaterialLocal(data) {
			if (data.message == 'CREATED') {
				this.addItemListMaterials(data.data)
			} else if (data.message == 'UPDATED') {
				this.setItemListMaterials(data.data)
			}
		},
		setMaterialInit() {
			this.getMaterialsLocal()
		},
		showMsgErrorHandlingLocal(error) {
			this.loadingMaterials = false
		},
		showMaterialLocal(id) {
			this.materialIdSelected = id
			setTimeout(() => { this.$refs.materialComponent.$refs.btnShowMaterial.click() }, 50)
		},
		showMaterialHistoryStockLocal(id) {
			this.materialIdSelected = id
			setTimeout(() => { this.$refs.materialHistoryStockComponent.$refs.btnShowMaterial.click() }, 50)
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>