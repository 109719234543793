<template>
	<div id="supplierListComponent">
		<div v-show="loadingSupplier == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Titulo">
			</mensagge-loading-component>
		</div>
		<div class="card mb-1" id="shippingsCard">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-sm-auto d-flex align-items-center pe-0">
						<div class="row gy-2 gx-3 align-items-center">
							<div class="col-auto">
								<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Proveedores</h5>
							</div>
							<div class="col-auto">
								<div class="input-group">
									<input class="form-control form-control-sm shadow-none search" type="search"
										placeholder="" aria-label="search" ref="searchSuppliers"
										v-model="searchSuppliers" @focus="$event.target.select()">
									<div class="input-group-text bg-transparent pointer" @click="getSupplierLocal(1)"
										ref="btnGetSupplier">
										<span class="fa fa-search fs--1 text-600"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-8 col-sm-auto ms-auto text-end ps-0">
						<div id="orders-actions">
							<button class="btn btn-falcon-default btn-sm me-2" type="button"
								@click="showSupplierLocal(0)" ref="btnShowSupplier" data-bs-toggle="offcanvas"
								data-bs-target="#editSupplierOffCanvasRight">
								<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
								<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body p-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
						<thead class="bg-200">
							<tr>
								<th class="text-900">Proveedor</th>
								<th class="text-900">Dirección</th>
								<th class="text-900">Contacto</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(s, i) in listSuppliersFilter" :key="i" class="pointer">
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<a class="text-primary pointer" @click="showSupplierLocal(s.id)"
										data-bs-toggle="offcanvas" data-bs-target="#editSupplierOffCanvasRight">
										<strong>#{{ s.id }}</strong>
									</a>
									<strong class="ms-2">{{ s.name }}</strong>
									<br />
									<small class="text-muted">
										<i class="far fa-address-card ms-1 text-secondary"></i>
										<span class="ms-1">{{ s.document_number }}</span>
									</small>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<span class="ms-1">
										{{ s.address }}
										<span v-if="s.comuna != null">
											, {{ s.comuna.name }}
										</span>
									</span>
								</td>
								<td class="align-middle white-space-nowrap border-bottom border-muted">
									<div>
										<span v-if="s.contact != null || s.contact != undefined">{{ s.contact }}</span>
										<span v-else>Sin nombre de contacto</span>
									</div>
									<small class="text-muted">
										<i class="far fa-envelope text-secondary"></i>
										<a href="mailto:ricky@example.com" class="text-muted ms-1">{{ s.email }}</a>
										|<i class="fas fa-phone-alt ms-1 text-secondary"></i>
										<span class="ms-1">{{ s.phone }}</span>
									</small>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex align-items-center justify-content-center">
					<pagination :pagination="paginationListSuppliers" @getAllList="getSupplierLocal">
					</pagination>
				</div>
			</div>
		</div>

		<!-- -->
		<div class="offcanvas offcanvas-end" id="editSupplierOffCanvasRight" tabindex="-1"
			ref="editSupplierOffCanvasRight" aria-labelledby="editSupplierOffCanvasRightLabel">
			<div class="offcanvas-header">
				<h5 id="editSupplierOffCanvasRightLabel">Nuevo Supplier</h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					ref="btnCloseNewJourlaItemOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<supplier-component ref="supplierComponent" :inputPositionColumn="true" :id="supplierIdSelected"
					@saveSupplier="saveSupplierLocal"></supplier-component>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import supplier from '@/mixins/generals/supplier.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import SupplierComponent from '@/components/SupplierComponent.vue'

export default {
	name: 'SupplierListComponent',
	mixins: [mixin, axiosAction, errors, supplier],
	props: ['search'],
	data() {
		return {
			supplierIdSelected: 0
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent,
		'supplier-component': SupplierComponent
	},
	computed: {
		listSuppliersFilter() {
			return this.listSuppliers
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getSuppliersResult': this.getSuppliersResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getSupplierLocal(page = 1) {
			this.searchSuppliers = (this.search == undefined) ? this.searchSuppliers : this.search
			this.getSuppliers()
		},
		getSuppliersResultLocal(response) {
			if (this.search != undefined) {
				if (this.listSuppliers.length == 0) {
					this.$emit('getSuppliersResult', null)
				} else if (this.listSuppliers.length == 1) {
					this.$emit('getSuppliersResult', this.listSuppliers[0])
				} else {
					this.$emit('getSuppliersResult', true)
				}
			}
		},
		saveSupplierLocal(data) {
			if (data.message == 'CREATED') {
				this.addItemListSuppliers(data.data)
			} else if (data.message == 'UPDATED') {
				this.setItemListSuppliers(data.data)
			}
		},
		initModuleLocal() {
			this.setSupplierInit()
		},
		setSupplierInit() { },
		showSupplierLocal(id) {
			this.supplierIdSelected = id
			setTimeout(() => { this.$refs.supplierComponent.$refs.btnShowSupplier.click() }, 50)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>