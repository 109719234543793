<template>
	<div id="uploadFileExcelComponenet">
		<div class="dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message"
			@click="clickBtn('btnUploadFile')" @dragover.prevent @drop.prevent>
			<input type="file" name="file" class="invisible" ref="btnUploadFile" @change="onChangeInputFileUploadLocal"
				id="btnUploadFile" v-show="false" />
			<div class="text-center" @drop="onDropHandlerLocal">
				<img class="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />Suba
				un
				archivo Excel con las ordenes de fletes
				<p class="mb-0 fs--1 text-400">Suba un archivo (xls, xlsx, cvs)<br />con un tamaño
					máximo de 400 KB</p>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'UploadFileExcelComponenet',
	mixins: [mixin],
	props: ['extensions', 'headers'],
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
	},
	methods: {
		analizeFileLocal() {
			let ra = this.analizeFile(this.fileUpload)
			if (!ra.result) {
				console.log(ra.msg)
			} else {
				this.getFileUploadContentExcel(this.fileUpload)
			}
		},
		initModuleLocal() {
			this.setUploadFileExcelComponenetInit()
		},
		getFileUploadContentExcelResultLocal() {
			let isValid = this.isFileValidJSON(this.fileUploadContentExcelJSON, this.fileUploadHeaderExcelJSON)
			if (isValid.result == true) {
				let rows = this.getJSONfromFileUpload(this.fileUploadContentExcelJSON, this.fileUploadHeaderExcelJSON)
				console.log(rows)
			}
		},
		onChangeInputFileUploadLocal() {
			this.onChangeInputFileUpload('btnUploadFile')
		},
		onDropHandlerLocal(e) {
			this.onDropHandler(e)
		},
		onDropHandlerResultLocal() {
			this.analizeFileLocal()
		},
		setUploadFileExcelComponenetInit() {
			if (this.extensions != undefined) {
				this.extensions.forEach(e => {
					this.filesUploadAccept.push({ extension: e })
				});
			}
			if (this.headers != undefined) {
				this.fileUploadHeaderExcelJSON = this.headers
			}
			console.log(this.fileUploadHeaderExcelJSON)
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>