var purchaseOrder = {
	data() {
		return {
			listPurchaseOrders: [],
			objectPurchaseOrder: {
				exists: 0,
				id: 0,
				supplier_id: 0,
				contact: '',
				phone: '',
				email: '',
				supplier: {
					id: 0,
					name: '',
					document_number: '',
					address: '',
					comuna_id: null,
					comuna: null
				},
				departament_id: 0,
				departament: null,
				type_payment_id: 0,
				type_payment: null,
				net: 0,
				total_tax: 0,
				total: 0,
				status_id: 71,
				status: null,
				quotation_id: null,
				details: []
			},
			searchPurchaseOrders: '',
			loadingPurchaseOrders: false,
			savingPurchaseOrder: false,
			paginationListPurchaseOrders: []
		}
	},
	methods: {
		addItemListPurchaseOrders(po) {
			this.listPurchaseOrders.unshift({
				exists: po.exists,
				id: po.id,
				supplier_id: po.supplier_id,
				contact: po.contact,
				phone: po.phone,
				email: po.email,
				supplier: po.supplier,
				departament_id: po.departament_id,
				departament: po.departament,
				type_payment_id: po.type_payment_id,
				type_payment: po.type_payment,
				net: po.net,
				total_tax: po.total_tax,
				total: po.total,
				status_id: po.status_id,
				status: po.status,
				quotation_id: po.quotation_id,
				details: po.details,
			})
		},
		addItemEmptyObjectPurchaseOrderDetails() {
			let item = {
				id: 0,
				purchase_order_id: 0,
				internal_id: '',
				code: '',
				description: '',
				quantity: 1,
				currency_type_id: 1,
				currency_type: { id: 1, name: 'Peso', synthetic: 'CL' },
				price: '',
				with_taxes_included: false,
				tax: 0.19
			}
			this.objectPurchaseOrder.details.push(item)
		},
		clearObjectPurchaseOrder() {
			this.objectPurchaseOrder.exists = 0
			this.objectPurchaseOrder.id = 0
			this.objectPurchaseOrder.supplier_id = 0
			this.objectPurchaseOrder.supplier.name = ''
			this.objectPurchaseOrder.supplier.document_number = ''
			this.objectPurchaseOrder.supplier.address = ''
			this.objectPurchaseOrder.supplier.comuna_id = null
			this.objectPurchaseOrder.supplier.comuna = null
			this.objectPurchaseOrder.supplier.contact = ''
			this.objectPurchaseOrder.supplier.phone = ''
			this.objectPurchaseOrder.supplier.email = ''
			this.objectPurchaseOrder.contact = ''
			this.objectPurchaseOrder.phone = ''
			this.objectPurchaseOrder.email = ''
			this.objectPurchaseOrder.departament_id = 0
			this.objectPurchaseOrder.departament = null
			this.objectPurchaseOrder.type_payment_id = 0
			this.objectPurchaseOrder.type_payment = null
			this.objectPurchaseOrder.net = 0
			this.objectPurchaseOrder.total_tax = 0
			this.objectPurchaseOrder.total = 0
			this.objectPurchaseOrder.status_id = 71
			this.objectPurchaseOrder.status = null
			this.objectPurchaseOrder.quotation_id = null
			this.objectPurchaseOrder.details = []
		},
		getPurchaseOrders(page = 1) {
			this.loadingPurchaseOrders = true
			let param = '?search=' + this.searchPurchaseOrders + '&page=' + page
			let url = this.getApiBackendNew + 'purchaseOrders' + param
			this.axiosActionIS(url, 'GET', 'getPurchaseOrdersResult')
		},
		getPurchaseOrdersResult(response) {
			this.loadingPurchaseOrders = false
			if (response.data.success == true) {
				this.listPurchaseOrders = response.data.data
				this.paginationListPurchaseOrders = response.data.paginate
			}
			this.getPurchaseOrdersResultLocal(response)
		},
		getPurchaseOrdersResultLocal(response) {
			console.log('getPurchaseOrdersResultLocal no desarrollado', response)
		},
		setObjectPurchaseOrder(po) {
			this.clearObjectPurchaseOrder()
			for (const property in po) {
				this.objectPurchaseOrder[property] = po[property]
			}
		},
		savePurchaseOrder(po) {
			this.savingPurchaseOrder = true
			let method = (po.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'purchaseOrder/'
			url += (method == 'POST') ? 'register' : po.id + '/update'
			this.axiosActionIS(url, method, 'savePurchaseOrderResult', po)
		},
		savePurchaseOrderResult(response) {
			this.savingPurchaseOrder = false
			if (response.data.success == true) {
				this.setObjectPurchaseOrder(response.data.data)
			}
			this.savePurchaseOrderResultLocal(response)
		},
		savePurchaseOrderResultLocal(response) {
			console.log('savePurchaseOrderResultLocal no desarrollado', response)
		}
	},
};

export default purchaseOrder;