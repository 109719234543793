<template>
	<div id="inventoryTemplateWizardComponent">
		<button class="btn btn-sm btn-primary" ref="btnClearInventoryTemplateWizardComponent"
			@click="clearInventoryTemplateWizardComponent" v-show="false">Clear Inventory Template Wizard
			Component</button>
		<div class="card theme-wizard fs--1">
			<div class="card-header border-top" style="padding: 0px;">
				<div class="row" style="margin: 0px;">
					<div class="col text-center py-2 border-start" v-show="tabSelected < 4"
						:class="(tabSelected == 0) ? 'border-end' : 'border-bottom pointer'" @click="selectTab(0)">
						<span :class="(tabSelected == 0) ? 'text-primary' : 'text-muted'">
							<i class="fas fa-store"></i>
						</span>
						<span class="ms-1 fw-medium" :class="(tabSelected == 0) ? 'text-900' : 'text-600'">Bodega</span>
						<span class="ms-1 text-success float-end" v-if="storeSelected != null"
							:title="storeSelected.id">
							<i class="fas fa-check"></i>
						</span>
					</div>
					<div class="col text-center py-2" v-show="tabSelected < 4"
						:class="(tabSelected == 1) ? 'border-start border-end' : 'border-bottom pointer'"
						@click="selectTab(1)">
						<span :class="(tabSelected == 1) ? 'text-primary' : 'text-muted'">
							<i class="far fa-window-restore"></i>
						</span>
						<span class="ms-1 fw-medium" :class="(tabSelected == 1) ? 'text-900' : 'text-600'">Rack</span>
						<span class="ms-1 text-success float-end" v-if="storeRackSelected != null"
							:title="storeRackSelected.id">
							<i class="fas fa-check"></i>
						</span>
					</div>
					<div class="col text-center py-2" v-show="tabSelected < 4"
						:class="(tabSelected == 2) ? 'border-start border-end' : 'border-bottom pointer'"
						@click="selectTab(2)">
						<span :class="(tabSelected == 2) ? 'text-primary' : 'text-muted'">
							<i class="fas fa-table"></i>
						</span>
						<span class="ms-1 fw-semi-bold"
							:class="(tabSelected == 2) ? 'text-900' : 'text-600'">Lado</span>
						<span class="ms-1 text-success float-end" v-show="storeRackSideRowColumnIds.length > 0"
							:title="storeRackSelected">
							<i class="fas fa-check"></i>
						</span>
					</div>
					<div class="col text-center py-2 border-end" v-show="tabSelected < 4"
						:class="(tabSelected == 3) ? 'border-start' : 'border-bottom pointer'" @click="selectTab(3)">
						<span :class="(tabSelected == 3) ? 'text-primary' : 'text-muted'">
							<i class="fas fa-swatchbook"></i>
						</span>
						<span class="ms-1 fw-semi-bold"
							:class="(tabSelected == 3) ? 'text-900' : 'text-600'">Articulos</span>
					</div>
					<div class="col text-center py-2 border-end" v-show="tabSelected == 4"
						:class="(tabSelected == 4) ? 'border-start' : 'border-bottom pointer'">
						<span :class="(tabSelected == 4) ? 'text-primary' : 'text-muted'">
							<i class="far fa-list-alt"></i>
						</span>
						<span class="ms-1 fw-semi-bold"
							:class="(tabSelected == 4) ? 'text-900' : 'text-600'">Resumen</span>
					</div>
				</div>
			</div>
			<div class="card-body border-end border-start" style="height: 400px; overflow: auto">
				<div>
					<div v-show="tabSelected == 0">
						<div>
							<div class="fs-1 text-center">
								Elije una bodega de donde hacer la planilla
							</div>
							<div class="row" v-show="loadingStores == false">
								<div class="col text-center border m-1 pointer"
									:class="(storeSelected != null) && (storeSelected.id == s.id) ? 'border-secondary' : 'text-muted'"
									v-for="(s, i) in listStoresFilter" :key="i" @click="selectStoreLocal(s)">
									<div class="fs-8"
										:class="(storeSelected != null) && (storeSelected.id == s.id) ? 'text-info' : ''">
										<i class="fas fa-store"></i>
									</div>
									<span class="">{{ s.name }}</span>
								</div>
							</div>
							<div v-show="loadingStores == true" class="text-center">
								<span class="fa-spin">
									<i class="fas fa-spinner"></i>
									<i class="fas fa-circle-notch" v-show="false"></i>
								</span>
								<span>Buscando bodegas...</span>
							</div>
						</div>
					</div>
					<div v-show="tabSelected == 1">
						<div>
							<div class="fs-1 text-center">
								Elije un Rack de donde hacer la planilla
							</div>
							<div class="row" v-show="loadingStoreRacks == false">
								<div class="col text-center border m-1 pointer"
									:class="(storeRackSelected != null) && (storeRackSelected.id == sr.id) ? 'border-secondary' : ''"
									v-for="(sr, i) in listStoreRacksFilter" :key="i" @click="selectStoreRackLocal(sr)">
									<div class="fs-8"
										:class="(storeRackSelected != null) && (storeRackSelected.id == sr.id) ? 'text-info' : ''">
										<i class="far fa-window-restore"></i>
									</div>
									<span class="">{{ sr.name }}</span>
								</div>
							</div>
							<div v-show="loadingStoreRacks == true" class="text-center">
								<span class="fa-spin">
									<i class="fas fa-spinner"></i>
									<i class="fas fa-circle-notch" v-show="false"></i>
								</span>
								<span>Buscando racks de la bodega...</span>
							</div>
						</div>
					</div>
					<div v-show="tabSelected == 2">
						<div>
							<div class="fs-1 text-center">
								Elije un lado de Rack de donde hacer la planilla
							</div>
							<div class="row" v-show="loadingStoreRackSides == false">
								<div class="col text-center border pointer"
									:class="(storeRackSideSelected != null) && (storeRackSideSelected.id == srs.id) ? 'border-secondary' : ''"
									v-for="(srs, i) in listStoreRackSidesFilter" :key="i"
									@click="selectStoreRackSideLocal(srs)">
									<span>
										<i class="fas fa-table"></i>
									</span>
									<span class="ms-2">{{ srs.name }}</span>
								</div>
							</div>
							<div v-show="loadingStoreRackSides == true" class="text-center">
								<span class="fa-spin">
									<i class="fas fa-spinner"></i>
									<i class="fas fa-circle-notch" v-show="false"></i>
								</span>
								<span>Buscando lados de rack...</span>
							</div>
							<div>
								<store-rack-side-component ref="storeRackSideComponent"
									:storeRackSide="storeRackSideSelected" :multipleSelection="true" :infoChange="true"
									@getSelectedIds="getSelectedIdsLocal"></store-rack-side-component>
							</div>
						</div>
					</div>
					<div v-show="tabSelected == 3">
						<div class="table-responsive">
							<table class="table table-sm table-hover table-striped"
								v-show="loadingStoreRackSideRowColumnContent == false">
								<thead>
									<tr>
										<th class="text-center">
											<input class="form-check-input" id="flexCheckDefault" type="checkbox"
												v-model="selectedAllContent" @change="changeSelectedAllContent" />
										</th>
										<th>Tipo</th>
										<th>Descripción</th>
										<th class="text-end">Cantidad</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(c, i) in listStoreRackSideRowColumnContentFilter" :key="i">
										<td class="text-center">
											<input class="form-check-input" id="flexCheckDefault" type="checkbox"
												v-model="c.selected" @change="changeSelectedContent(c.selected)" />
										</td>
										<td>{{ c.type }}</td>
										<td>{{ c.name }}</td>
										<td class="text-end">{{ c.quantity }}</td>
									</tr>
								</tbody>
							</table>
							<div v-show="loadingStoreRackSideRowColumnContent == true" class="text-center">
								<span class="fa-spin">
									<i class="fas fa-spinner"></i>
									<i class="fas fa-circle-notch" v-show="false"></i>
								</span>
								<span>Buscando productos o materias primas...</span>
							</div>
						</div>
						<div class="text-end">
							<input class="form-check-input" id="flexCheckDefault" type="checkbox"
								v-model="showContentWith0" @change="changeSelectedAllContent" />
							<span class="ms-2">Incliur los con cantidad 0</span>
						</div>
					</div>
					<div v-show="tabSelected == 4">
						<div class="row">
							<div class="col-md-6 border-end border-muted mb-2">
								<div class="mb-2">
									<label class="form-label" for="o">Nombre de la planilla</label>
									<input class="form-control form-control-sm" type="text"
										placeholder="Planilla de Colportaje" v-model="objectInventoryTemplate.name" />
								</div>
								<p v-if="storeSelected != null">
									<i class="fas fa-store"></i>
									<span class="ms-2">{{ storeSelected.name }}</span>
								</p>
								<p v-if="storeRackSelected != null">
									<i class="far fa-window-restore"></i>
									<span class="ms-2">{{ storeRackSelected.name }}</span>
								</p>
								<p v-if="storeRackSideSelected != null">
									<i class="fas fa-table"></i>
									<span class="ms-2">{{ storeRackSideSelected.name }}</span>
								</p>
								<store-rack-side-component ref="storeRackSideComponentSelected"
									:storeRackSide="storeRackSideSelected" :small="true" :hideName="true"
									:storeRackSideRowColumnIds="storeRackSideRowColumnIds"></store-rack-side-component>
							</div>
							<div class="col-md-6">
								<div style="height: 360px; overflow-x: auto;"
									v-show="listStoreRackSideRowColumnContentFilter.length > 0">
									<table class="table table-sm table-hover table-striped">
										<thead>
											<tr>
												<th>Descripción</th>
												<th class="text-end">Cantidad</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(c, i) in listStoreRackSideRowColumnContentFilter" :key="i">
												<td>{{ c.name }}</td>
												<td class="text-end">{{ c.quantity }}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-show="listStoreRackSideRowColumnContentFilter.length == 0">
									<div class="alert alert-warning border-0 d-flex align-items-center" role="alert">
										<div class="bg-warning me-3 icon-item"><span
												class="fas fa-exclamation-circle text-white fs-3"></span></div>
										<p class="mb-0 flex-1">No hay Articulos o Materias primas para llenar la
											planilla</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer border">
				<ul class="pager wizard list-inline mb-0">
					<li class="previous">
						<button class="btn btn-sm btn-link ps-0" type="button" :disabled="tabSelected == 0"
							@click="selectTab(tabSelected - 1)">
							<span class="fas fa-chevron-left me-2" data-fa-transform="shrink-3"></span>
							Atrás
						</button>
					</li>
					<li class="next">
						<button class="btn btn-sm btn-secondary me-2" @click="closeInventoryTemplateComponent"
							v-show="showBtnClose == true">Cerrar</button>
						<button class="btn btn-sm btn-primary px-5 px-sm-6" type="submit"
							@click="selectTab(tabSelected + 1)" v-show="tabSelected < 4">
							Siguiente
							<span class="fas fa-chevron-right ms-2" data-fa-transform="shrink-3"> </span>
						</button>
						<button class="btn btn-sm btn-primary px-5 px-sm-6" type="submit"
							@click="saveInventoryTemplateLocal" :disabled="disabledBtnSaveInventoryTemplate"
							v-show="tabSelected == 4">
							<span v-show="savingInventoryTemplate == false">Guardar</span>
							<span v-show="savingInventoryTemplate == true">Guardando...</span>
						</button>
					</li>
				</ul>

				<div class="alert alert-warning mt-2" role="alert" v-show="!(inventoryId > 0)">
					<i class="fas fa-exclamation-triangle"></i>
					<span class="ms-2">
						No se va a guardar esta Planilla. Falta indicar a que inventario pertenece
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import stores from '@/mixins/stores'
import storeRack from '@/mixins/stores/storeRack.js'
import storeRackSide from '@/mixins/stores/storeRackSide.js'
import storeRackSideRowColumn from '@/mixins/stores/storeRackSideRowColumn.js'
import inventoryTemplate from '@/mixins/inventories/inventoryTemplate.js'

import StoreRackSideComponent from '@/components/StoreRackSideComponent.vue'

export default {
	name: 'InventoryTemplateWizardComponent',
	mixins: [mixin, axiosAction, errors, stores, storeRack, storeRackSide, storeRackSideRowColumn, inventoryTemplate],
	props: ['inventoryId', 'showBtnClose'],
	data() {
		return {
			tabSelected: 0,
			storeSelected: null,
			storeRackSelected: null,
			storeRackSideSelected: null,
			storeRackSideRowColumnIds: [],
			showContentWith0: true,
			selectedAllContent: false,
			showOnlyContentSelected: false
		}
	},
	components: {
		'store-rack-side-component': StoreRackSideComponent,
	},
	computed: {
		disabledBtnSaveInventoryTemplate() {
			if (this.objectInventoryTemplate.name.trim().length < 5) {
				return true
			}
			if (this.savingInventoryTemplate == true) {
				return true
			}
			if (this.storeRackSideRowColumnIds.length == 0)
				return false
		},
		listStoresFilter() {
			return this.listStores
		},
		listStoreRacksFilter() {
			return this.listStoreRacks
		},
		listStoreRackSidesFilter() {
			return this.listStoreRackSides
		},
		listStoreRackSideRowColumnContentFilter() {
			return this.listStoreRackSideRowColumnContent.filter(c => {
				return ((this.showContentWith0 == true) || (c.quantity > 0)) && ((this.showOnlyContentSelected == false) || (c.selected == true))
			})
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getStoresResult': this.getStoresResult(response); break;
				case 'getStoreRacksResult': this.getStoreRacksResult(response); break;
				case 'getStoreRackSidesResult': this.getStoreRackSidesResult(response); break;
				case 'getStoreRackSideRowColumnContentResult': this.getStoreRackSideRowColumnContentResult(response); break;
				case 'saveInventoryTemplateResult': this.saveInventoryTemplateResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		changeSelectedAllContent() {
			this.listStoreRackSideRowColumnContent.forEach(c => {
				c.selected = this.selectedAllContent
			});
		},
		changeSelectedContent(selected) {
			if (selected == false) {
				this.selectedAllContent = selected
			} else {
				let s = true
				this.listStoreRackSideRowColumnContent.forEach(c => {
					if (c.selected == false) {
						s = false
					}
				})
				this.selectedAllContent = s
			}
		},
		clearInventoryTemplateWizardComponent() {
			this.clearObjectInventoryTemplate()
			this.tabSelected = 0
			this.storeSelected = null
			this.storeRackSelected = null
			this.storeRackSideSelected = null
			this.storeRackSideRowColumnIds = [],
				this.showContentWith0 = true
			this.selectedAllContent = false
			this.showOnlyContentSelected = false
		},
		closeInventoryTemplateComponent() {
			this.$emit('closeInventoryTemplate')
		},
		getSelectedIdsLocal(storeRackSideRowColumnIds) {
			this.storeRackSideRowColumnIds = storeRackSideRowColumnIds
		},
		getStoresLocal() {
			this.getStores()
		},
		getStoresResultLocal(response) {
		},
		getStoreRacksResultLocal(response) {
		},
		getStoreRackSidesResultLocal(response) {
			if (response.data.success == true) {
				if (this.listStoreRackSides.length == 1) {
					this.selectStoreRackSideLocal(this.listStoreRackSides[0])
				}
			}
		},
		getStoreRackSideRowColumnContentResultLocal(response) {
			if (response.data.success == true) {
				if (this.listStoreRackSideRowColumnContentFilter.length > 0) {
					this.selectedAllContent = true
				}
			}
		},
		initModuleLocal() {
			this.setInventoryTemplateWizardInit()
		},
		saveInventoryTemplateLocal() {
			this.objectInventoryTemplate.store_rack_side_row_columns = this.storeRackSideRowColumnIds
			this.objectInventoryTemplate.content = this.listStoreRackSideRowColumnContentFilter
			this.saveInventoryTemplate(this.objectInventoryTemplate)
		},
		saveInventoryTemplateResultLocal(response) {
			if (response.data.success == true) {
				this.$emit('saveInventoryTemplateResult', response.data.data)
			}
		},
		selectStoreLocal(store) {
			this.storeSelected = store
			this.tabSelected += 1
			this.storeRackSelected = null
			this.getStoreRacksByStoreId(this.storeSelected.id)
		},
		selectStoreRackLocal(storeRack) {
			if ((this.storeRackSelected == null) || (this.storeRackSelected.id != storeRack.id)) {
				this.storeRackSelected = storeRack
				this.storeRackSideSelected = null
				this.getStoreRackSidesByStoreId(this.storeRackSelected.id)
				this.$refs.storeRackSideComponent.$refs.btnClearStoreRackSideComponent.click()
			}
			this.tabSelected += 1
		},
		selectStoreRackSideLocal(storeRackSide) {
			this.storeRackSideSelected = storeRackSide
			setTimeout(() => { this.$refs.storeRackSideComponent.$refs.btnShowStoreRackSide.click() }, 50)
		},
		selectTab(index) {
			this.tabSelected = (index > 0) ? index - 1 : index
			this.showOnlyContentSelected = false
			if (index == 1) {
				if (this.storeSelected != null) {
					this.tabSelected = index
				}
			}
			if (index == 2) {
				if (this.storeSelected != null && this.storeRackSelected != null) {
					this.tabSelected = index
				}
			}
			if (index == 3) {
				if (this.storeSelected != null && this.storeRackSelected != null && this.storeRackSideRowColumnIds.length > 0) {
					this.tabSelected = index
					this.getStoreRackSideRowColumnContent(this.storeRackSideRowColumnIds)
				}
			}
			if (index == 4) {
				this.showOnlyContentSelected = true
				this.tabSelected = index
				this.clearObjectInventoryTemplate()
				setTimeout(() => { this.$refs.storeRackSideComponentSelected.$refs.btnShowStoreRackSide.click() }, 50)
				this.objectInventoryTemplate.inventory_id = this.inventoryId
			}
		},
		getStoreRacksResultLocal(response) { },
		setInventoryTemplateWizardInit() {
			this.getStoresLocal()
			this.clearInventoryTemplateWizardComponent()
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>