<template>
	<div id="typePrintListComponent">
		<div class="card z-1 mb-3" id="recentPurchaseTable">
			<div class="card-header">
				<div class="row flex-between-center">
					<div class="col-6 col-sm-auto d-flex align-items-center pe-0">
						<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Lista</h5>
					</div>
					<div class="col-6 col-sm-auto ms-auto text-end ps-0">
						<div id="table-purchases-replace-element">
							<button class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-plus"
									data-fa-transform="shrink-3 down-2"></span><span
									class="d-none d-sm-inline-block ms-1">Nuevo</span></button>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body px-0 py-0">
				<div class="table-responsive scrollbar">
					<table class="table table-sm fs--1 mb-0 overflow-hidden">
						<thead class="bg-200">
							<tr>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="name">
									Tipo</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="description">
									Descripción</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="status">
									Estado</th>
								<th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="actions">
								</th>
							</tr>
						</thead>
						<tbody class="list" id="table-purchase-body">
							<tr class="btn-reveal-trigger" v-for="(tp, i) in listPrintTypesFilter" :key="i">
								<td class="align-middle white-space-nowrap name">
									<span class="d-inline-block text-truncate" style="max-width: 379px;">
										{{ tp.name }}
									</span>
								</td>
								<td class="align-middle white-space-nowrap email">
									<span class="d-inline-block text-truncate" style="max-width: 900px;">
										{{ tp.description }}
									</span>
								</td>
								<td class="align-middle white-space-nowrap product">
									{{ tp.status.name }}
								</td>
								<td class="align-middle text-center fs-0 white-space-nowrap payment">
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-footer">
				<div class="row align-items-center">
					<div class="pagination d-none"></div>
					<div class="col">
						<p class="mb-0 fs--1"><span class="d-none d-sm-inline-block me-2"
								data-list-info="data-list-info"></span><span
								class="d-none d-sm-inline-block me-2">&mdash;</span><a class="fw-semi-bold" href="#!"
								data-list-view="*">Ver todo<span class="fas fa-angle-right ms-1"
									data-fa-transform="down-1"></span></a><a class="fw-semi-bold d-none" href="#!"
								data-list-view="less">Ver de {{ n }} registros<span class="fas fa-angle-right ms-1"
									data-fa-transform="down-1"></span></a>
						</p>
					</div>
					<div class="col-auto d-flex">
						<button class="btn btn-sm btn-primary" type="button"
							data-list-pagination="prev"><span>Atras</span></button>
						<button class="btn btn-sm btn-primary px-4 ms-2" type="button"
							data-list-pagination="next"><span>Siguiente</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import printType from '@/mixins/productions/printType.js'

export default {
	name: 'TypePrintListComponent',
	mixins: [mixin, axiosAction, errors, printType],
	props: [],
	data() {
		return {
			n: 10
		}
	},
	components: {
	},
	computed: {
		listPrintTypesFilter() {
			return this.listPrintTypes
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
			this.setTypePrintListComponentInit()
		},
		setTypePrintListComponentInit() {
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>