var materialMovement = {
	data() {
		return {
			listMaterialMovements: [],
			loadingMaterialMovements: false
		}
	},
	methods: {
		getMaterialMovementsByMaterialId(materialId, all = false) {
			this.listMaterialMovements = []
			this.loadingMaterialMovements = true
			let param = '?all=' + all
			let url = this.getApiBackendNew + 'material/' + materialId + '/movements' + param
			this.axiosActionIS(url, 'GET', 'getMaterialMovementsByMaterialIdResult')
		},
		getMaterialMovementsByMaterialIdResult(response) {
			this.loadingMaterialMovements = false
			if (response.data.success == true) {
				this.listMaterialMovements = response.data.data
			}
			this.getMaterialMovementsByMaterialIdResultLocal(response)
		},
		getMaterialMovementsByMaterialIdResultLocal(response) {
			console.log('getMaterialMovementsByMaterialIdResultLocal no desarrollado', response)
		},
	},
};

export default materialMovement;