<template>
	<div id="inventories">
		<inventory-list-component></inventory-list-component>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import InventoryListComponent from '@/components/InventoryListComponent.vue'

export default {
	name: 'Inventories',
	mixins: [mixin, axiosAction, errors],
	data() {
		return {
		}
	},
	components: {
		'inventory-list-component': InventoryListComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultFunction': this.defaultFunction(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		initModuleLocal() {
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
