<template>
	<div id="teamAdmin">
		<div v-if="!loadingTeams">
			<div>
				<div class="row mb-3">
					<div class="col">
						<div class="card bg-100 shadow-none border px-3 py-2">
							<div class="bg-holder bg-card"
								style="background-image:url(/assets/img/icons/spot-illustrations/corner-1.png);"></div>
							<div class="row gx-0 flex-between-center">
								<div class="col-sm-auto d-flex align-items-center">
									<div>
										<h6 class="text-primary fs--1 mb-0">ADMINISTRACION - <span class="pointer"
												@click="path('/team/' + objectTeam.id)">Campaña {{ objectTeam.id
												}}</span>
										</h6>
										<h4 class="text-primary fw-bold mb-0"><span class="text-info fw-medium">{{
											objectTeam.name }}</span></h4>
									</div>
								</div>
								<div class="col-md-auto p-3">
									<div class="col-auto text-end">
										<h6 class="text-700 mb-0">{{ objectTeam.client.name }} {{
											objectTeam.client.surname
											}}</h6>
										<small>{{ objectTeam.begin_date }}</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row g-3 mb-3">
					<div class="col-md-4">
						<div class="card h-md-100 ecommerce-card-min-width">
							<div class="bg-holder bg-card"
								style="background-image:url(/assets/img/icons/spot-illustrations/corner-3.png);"></div>
							<div class="card-header pb-0">
								<h6 class="mb-0 mt-2 d-flex align-items-center">
									Libros Campaña vs Libros Asignados
								</h6>
							</div>
							<div class="card-body d-flex flex-column justify-content-end">
								<div class="row align-items-end h-100 mb-n1">
									<div class="col-6 pe-md-0 pe-lg-3">
										<div class="row g-0">
											<div class="col-5">
												<h6 class="text-600">Pedido:</h6>
											</div>
											<div class="col-7">
												<h6 class="text-800 text-end">$ {{ methodFormatNumber(sumOrdersTotal) }}
												</h6>
											</div>
										</div>
										<div class="row g-0">
											<div class="col-5">
												<h6 class="mb-0 text-600">Asignado:</h6>
											</div>
											<div class="col-7 text-end">
												<h6 class="mb-0 text-800">$ {{ methodFormatNumber(totalClient(-1,
													'total', true)) }}</h6>
											</div>
										</div>
									</div>
									<div class="col-6">
										<div class="lms-half-doughnut mt-n7 ms-auto">
											<doughnut-chart :options="chartOptions"
												:chartData="chartDataProductsTeamVSProductsAssigned">
											</doughnut-chart>
											<p class="mb-0 mt-n7 text-center fs-2 fw-medium">{{
												percentageProductsTeamVSProductsAssigned }} %</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card h-md-100 ecommerce-card-min-width">
							<div class="bg-holder bg-card"
								style="background-image:url(/assets/img/icons/spot-illustrations/corner-2.png);"></div>
							<div class="card-header pb-0">
								<h6 class="mb-0 mt-2 d-flex align-items-center">
									Libros Asignados vs Abono Colportores
								</h6>
							</div>
							<div class="card-body d-flex flex-column justify-content-end">
								<div class="row align-items-end h-100 mb-n1">
									<div class="col-6 pe-md-0 pe-lg-3">
										<div class="row g-0">
											<div class="col-5">
												<h6 class="text-600">Asignado:</h6>
											</div>
											<div class="col-7">
												<h6 class="text-800 text-end">$ {{ methodFormatNumber(totalClient(-1,
													'total', true)) }}</h6>
											</div>
										</div>
										<div class="row g-0">
											<div class="col-5">
												<h6 class="mb-0 text-600">Abono Colportores:</h6>
											</div>
											<div class="col-7 text-end">
												<h6 class="mb-0 text-800">$ {{
													methodFormatNumber(sumTeamClientPaymentLocal) }}</h6>
											</div>
										</div>
									</div>
									<div class="col-6">
										<div class="lms-half-doughnut mt-n6 ms-auto">
											<doughnut-chart v-if="sumTeamClientPaymentLocal > 0" :options="chartOptions"
												:chartData="chartDataProductsAssignedVSPaymentTeams">
											</doughnut-chart>
											<p class="mb-0 mt-n7 text-center fs-2 fw-medium">
												{{ percentageProductsAssignedVSPaymentTeams }} %</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="card h-md-100 ecommerce-card-min-width">
							<div class="bg-holder bg-card"
								style="background-image:url(/assets/img/icons/spot-illustrations/corner-4.png);"></div>
							<div class="card-header pb-0">
								<h6 class="mb-0 mt-2 d-flex align-items-center">
									Abonos Colportores vs Remesa a ACES
								</h6>
							</div>
							<div class="card-body d-flex flex-column justify-content-end">
								<div class="row align-items-end h-100 mb-n1">
									<div class="col-6 pe-md-0 pe-lg-3">
										<div class="row g-0">
											<div class="col-5">
												<h6 class="text-600">Abono Colportores:</h6>
											</div>
											<div class="col-7">
												<h6 class="text-800 text-end">$ {{
													methodFormatNumber(sumTeamClientPaymentLocal) }}</h6>
											</div>
										</div>
										<div class="row g-0">
											<div class="col-5">
												<h6 class="mb-0 text-600">Remesas a ACES:</h6>
											</div>
											<div class="col-7 text-end">
												<h6 class="mb-0 text-800">$ {{ methodFormatNumber(sumConsignmentsTotal)
													}}</h6>
											</div>
										</div>
									</div>
									<div class="col-6">
										<div class="lms-half-doughnut mt-n6 ms-auto">
											<doughnut-chart v-if="sumTeamClientPaymentLocal > 0" :options="chartOptions"
												:chartData="chartDataPaymentTeamsVSPaymentACES">
											</doughnut-chart>
											<p class="mb-0 mt-n7 text-center fs-2 fw-medium">
												{{ percentagePaymentTeamsVSPaymentACES }} %</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-lg-7">
						<div class="card mb-3">
							<div class="card-header d-flex flex-between-center py-2">
								<h6 class="mb-0">Estudiantes</h6>
							</div>
							<div class="card-body px-0 py-0">
								<div class="table-responsive scrollbar">
									<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
										<thead class="bg-200 text-900">
											<tr>
												<th class="sort pe-1 align-middle white-space-nowrap">Nombre</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">
													Control
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Deuda
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">
													Bonificable</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center"><span
														@click="showNewTeamClientPaymentModal(0)">Abono</span></th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center"
													v-show="actionSecurity(14)">Considerar</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Acción
												</th>
											</tr>
										</thead>
										<tbody class="list" id="table-teams-body">
											<tr v-for="(item, index) in objectTeam.clients" :key="index">
												<td class="align-middle py-2 align-middle white-space-nowrap">
													<i class="fas fa-user-tie"
														:class="(item.status_id == 1) ? 'text-success' : 'text-muted'"
														:title="(item.status_id == 1) ? 'Activo' : 'Inactivo'"></i>
													<span class="m-2">{{ item.client.name }} {{
														item.client.surname}}</span>
													<p class="mb-0 text-500">{{ item.client.email }}</p>
												</td>
												<td class="align-middle text-center"
													:title="'Asignado $' + methodFormatNumber(totalClient(item.client_id, 'total', false))">
													<div class="progress rounded-3 worked" style="height: 6px;"
														role="progressbar"
														:aria-valuenow="percentageProductsAssignedTeamVSPaymentToLeader(item)"
														aria-valuemin="0" aria-valuemax="100">
														<div class="progress-bar bg-info"
															:style="'width: ' + percentageProductsAssignedTeamVSPaymentToLeader(item) + '%'">
														</div>
													</div>
													{{ percentageProductsAssignedTeamVSPaymentToLeader(item) }}%
												</td>
												<td class="align-middle text-end">
													$ {{ methodFormatNumber(totalDebt(item.client_id)) }}
												</td>
												<td class="align-middle text-end">
													$ {{ methodFormatNumber(totalClient(item.client_id, 'bonusable',
													false)) }}
												</td>
												<td class="align-middle text-end">
													$ {{ methodFormatNumber(sumTeamClientPayment(item.payments)) }}
												</td>
												<td class="align-middle text-center" v-show="false">
													<div class="d-flex align-items-center">
														<div class="progress rounded-3 bg-200"
															style="height: 5px; width:100%" title="39% del objetivo">
															<div class="progress-bar rounded-pill" role="progressbar"
																style="width: 39%;" aria-valuenow="39" aria-valuemin="0"
																aria-valuemax="100"></div>
														</div>
													</div>
												</td>
												<td class="align-middle text-center" v-show="actionSecurity(14)">
													<div class="form-switch text-center">
														<input class="form-check-input" id="flexSwitchCheckChecked"
															type="checkbox" checked="" />
														<label class="form-check-label"
															for="flexSwitchCheckChecked"></label>
													</div>
												</td>
												<td class="text-center">
													<div class="dropdown font-sans-serif position-static">
														<div class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
															type="button" id="team-dropdown-0" data-bs-toggle="dropdown"
															data-boundary="viewport" aria-haspopup="true"
															aria-expanded="false">
															<span class="fas fa-ellipsis-h fs--1"></span>
														</div>
														<div class="dropdown-menu dropdown-menu-end bteam py-0"
															aria-labelledby="team-dropdown-0">
															<div class="bg-white py-2">
																<router-link
																	:to="('/team/' + id + '/client/' + item.client_id)"
																	class="dropdown-item pointer">Ver</router-link>
																<span class="dropdown-item pointer"
																	v-show="objectTeam.status_id == 42 && actionSecurity(9)"
																	@click="showOrderTransferLocal(14, item)">Asignar
																	Articulos</span>
																<span class="dropdown-item pointer"
																	v-show="objectTeam.status_id == 42 && actionSecurity(10)"
																	@click="showNewTeamClientPaymentModal(item.id)">Asignar
																	Pago</span>
															</div>
														</div>
													</div>

												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="card-footer bg-light p-0">
								<span class="btn btn-sm btn-link d-block py-2"><span @click="getTeamClients(id)">{{
									objectTeam.clients.length }} colportores</span></span>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<div class="row g-3 mb-3">
							<div class="col-md-6 col-xxl-4">
								<div class="card h-md-100 ecommerce-card-min-width">
									<div class="card-header pb-0">
										<h6 class="mb-0 mt-2 d-flex align-items-center">
											Asignado
											<span class="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top"
												title="Calculated according to last week's sales">
												<span class="far fa-question-circle"
													data-fa-transform="shrink-1"></span>
											</span>
										</h6>
									</div>
									<div class="card-body d-flex flex-column justify-content-end">
										<div class="row">
											<div class="col text-end">
												$ {{ methodFormatNumber(totalClient(-1, 'total', true)) }}
											</div>
											<div class="col-auto ps-0" v-show="false">
												<div class="echart-bar-weekly-sales h-100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-xxl-4">
								<div class="card h-md-100 ecommerce-card-min-width">
									<div class="card-header pb-0">
										<h6 class="mb-0 mt-2 d-flex align-items-center">
											Bonificable
											<span class="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top"
												title="Calculated according to last week's sales">
												<span class="far fa-question-circle"
													data-fa-transform="shrink-1"></span>
											</span>
										</h6>
									</div>
									<div class="card-body d-flex flex-column justify-content-end">
										<div class="row">
											<div class="col text-end">
												$ {{ methodFormatNumber(totalClient(-1, 'bonusable', true)) }}
											</div>
											<div class="col-auto ps-0" v-show="false">
												<div class="echart-bar-weekly-sales h-100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-xxl-4">
								<div class="card h-md-100 ecommerce-card-min-width">
									<div class="card-header pb-0">
										<h6 class="mb-0 mt-2 d-flex align-items-center">
											Abonos
											<span class="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top"
												title="Calculated according to last week's sales">
												<span class="far fa-question-circle"
													data-fa-transform="shrink-1"></span>
											</span>
										</h6>
									</div>
									<div class="card-body d-flex flex-column justify-content-end">
										<div class="row">
											<div class="col text-end">
												$ {{ methodFormatNumber(sumTeamClientPaymentLocal) }}
											</div>
											<div class="col-auto ps-0" v-show="false">
												<div class="echart-bar-weekly-sales h-100"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="card">
							<div class="card-header d-flex flex-between-center py-2">
								<h6 class="my-1">Stock de Libros</h6>
							</div>
							<div class="card-body px-0 py-0">
								<div class="table-responsive scrollbar">
									<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
										<thead class="bg-200 text-900">
											<tr>
												<th class="sort pe-1 align-middle white-space-nowrap">Articulo</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">Precio
												</th>
												<th class="sort pe-1 align-middle white-space-nowrap text-center">
													Cantidad
												</th>
											</tr>
										</thead>
										<tbody class="list" id="table-teams-body">
											<tr v-for="(item, index) in listProductsFilter" :key="index"
												v-if="item.quantity > 0">
												<td class="align-middle" :title="'Codigo: ' + item.product_id">{{
													item.product }}</td>
												<td class="align-middle text-end">{{ methodFormatNumber(item.price) }}
												</td>
												<td class="align-middle text-center pointer"
													@click="showListProductDetailLocal(item.product_id)"
													data-bs-toggle="modal"
													data-bs-target=".listProductsDetailForIdModal">{{
														methodFormatNumber(item.quantity) }}</td>
											</tr>
											<tr v-for="(item, index) in listProductsFilter" :key="index"
												v-if="item.quantity == 0" class="text-300">
												<td class="align-middle" :title="'Codigo: ' + item.product_id">
													<del>{{ item.product }}</del>
												</td>
												<td class="align-middle text-end">
													<del>{{ methodFormatNumber(item.price) }}</del>
												</td>
												<td class="align-middle text-center pointer"
													@click="showListProductDetailLocal(item.product_id)"
													data-bs-toggle="modal"
													data-bs-target=".listProductsDetailForIdModal">
													<del>{{ methodFormatNumber(item.quantity) }}</del>
												</td>
											</tr>
											<tr v-for="(item, index) in listProductsFilter" :key="index"
												v-if="item.quantity < 0" class="text-danger">
												<td class="align-middle" :title="'Codigo: ' + item.product_id">
													<del>{{ item.product }}</del>
												</td>
												<td class="align-middle text-end">
													<del>{{ methodFormatNumber(item.price) }}</del>
												</td>
												<td class="align-middle text-center pointer"
													@click="showListProductDetailLocal(item.product_id)"
													data-bs-toggle="modal"
													data-bs-target=".listProductsDetailForIdModal">
													<del>{{ methodFormatNumber(item.quantity) }}</del>
													<small class="float-end" title="Revise los movimientos"><i
															class="fas fa-exclamation-triangle"></i></small>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center">
			<p class="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">Estamos buscando
				información
				de la campaña...</p>
		</div>
		<order-colportaje-transfer-modal-component ref="orderColportajeTransferComponent" :team="objectTeam.name"
			:teamId="objectTeam.id" :client="client" :listProducts="listProductsFilter" :typeOrderId="typeOrderId"
			@saveOrderComponent="saveOrderLocal">
		</order-colportaje-transfer-modal-component>

		<div class="modal fade listProductsDetailForIdModal" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="listProductsDetailForIdModalLabel" aria-hidden="true">
			<div class="modal-dialog mt-6 modal-lg" role="document">
				<div class="modal-content bconsignment-0">
					<div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
						<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
							data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body p-0">
						<div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
							<h4 class="mb-1" id="listProductsDetailForIdModalLabel">{{ productIdSelected.name }}</h4>
							<p class="fs--2 mb-0">Movimiento dentro de la campaña<a class="link-600 fw-semi-bold"
									href="#!">Model</a></p>
						</div>
						<div class="p-4">
							<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden">
								<thead class="bg-200 text-900">
									<tr>
										<th class="">Tipo de movimiento</th>
										<th class="">Colportor</th>
										<th class="" style="width: 100px">Cantidad</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in listProductsDetailForId" :key="index"
										:class="(item.factor == -1) ? 'text-danger' : ''">
										<td class="align-middle text-">{{ item.type_order.name }}</td>
										<td class="align-middle text-">{{ item.client }}</td>
										<td class="align-middle text-end">{{ item.quantity * item.factor }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td colspan="2" class="text-end">Stock Actual</td>
										<td colspan="1" class="text-end">{{ sumProductCurrent }}</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<button v-show="false" class="pointer" ref="btnNewTeamClientPaymentModal" data-bs-toggle="modal"
			data-bs-target=".newTeamClientPaymentModal">+</button>

		<div class="modal fade newTeamClientPaymentModal" data-bs-keyboard="false" data-bs-backdrop="static"
			tabindex="-1" aria-labelledby="newTeamClientPaymentModalLabel" aria-hidden="true">
			<div class="modal-dialog mt-6 modal-md" role="document">
				<div class="modal-content bconsignment-0">
					<div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
						<button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
							data-bs-dismiss="modal" aria-label="Close" ref="btnCloseNewTeamClientPaymentModal"></button>
					</div>
					<div class="modal-body p-0">
						<div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
							<h4 class="mb-1" id="newTeamClientPaymentModalLabel">Asignar Pago</h4>
							<p class="fs--2 mb-0">Seleccione el Colportor</p>
						</div>
						<div class="p-4">
							<div>
								<div class="mb-3">
									<label class="form-label" for="basic-form-gender">Colportor</label>
									<select class="form-select form-select-sm" id="basic-form-gender"
										aria-label="Default select example" v-model="teamClientIdSelected">
										<option value="0" selected="selected" disabled>Seleccione al colportor</option>
										<option v-for="(item, index) in objectTeam.clients" :key="index"
											:value="item.id">
											{{ item.client.name }} {{ item.client.surname }}</option>
									</select>
								</div>
								<div class="mb-3">
									<label class="form-label" for="basic-form-name">Forma de Pago</label>
									<select v-model="objectPayment.type_payment_id" class="form-select form-select-sm">
										<option v-for="(item, index) in listTypePayments" :key="index" :value="item.id">
											{{
												item.name }}</option>
									</select>
								</div>
								<div class="mb-3">
									<label class="form-label" for="basic-form-name">Monto</label>
									<input type="text" class="text-end form-control form-control-sm"
										@focus="$event.target.select()" v-model="objectPayment.amount"
										style="width: 100%;" ref="amount" />
								</div>
								<div class="mb-3">
									<label class="form-label" for="basic-form-name">Banco</label>
									<select v-model="objectPayment.bank_id" class="form-select form-select-sm">
										<option v-for="(item, index) in listBanks" :key="index" :value="item.id">{{
											item.name }}</option>
									</select>
								</div>
								<div class="mb-3">
									<label class="form-label" for="basic-form-name">Código</label>
									<input type="text" class="form-control form-control-sm"
										v-model="objectPayment.code" />
								</div>
								<div class="mb-3">
									<label class="form-label" for="basic-form-name">Descripción</label>
									<input type="text" class="form-control form-control-sm"
										v-model="objectPayment.description" />
								</div>
								<div class="mb-3">
									<label class="form-label" for="basic-form-name">Fecha</label>
									<input type="date" class="form-control form-control-sm"
										v-model="objectPayment.transaction_date" />
								</div>
								<div class="text-end">
									<button class="btn btn-primary" type="button" @click="savePaymentLocal()"
										:disabled="disabledSavePayment">
										<span v-if="savingPayment">
											Guardando...
										</span>
										<span v-else>
											Guardar
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5" v-show="messagePayment != ''">
			<div class="p-3 text-white" :class="(true) ? 'bg-success' : 'bg-danger'" id="liveToast" role="alert"
				aria-live="assertive" aria-atomic="true" data-bs-autohide="true">
				{{ messagePayment }}
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import teams from '@/mixins/teams'
import productPriceList from '@/mixins/products/productPriceList.js'
import orders from '@/mixins/orders'
import teamClient from '@/mixins/teams/teamClient.js'
import payments from '@/mixins/generals/payment.js'
import typePayments from '@/mixins/generals/typePayment.js'

import OrderColportajeTransferModalComponent from '@/components/OrderColportajeTransferModalComponent.vue'
import { DoughnutChart } from 'vue-chart-3';

export default {
	name: 'TeamAdmin',
	mixins: [mixin, axiosAction, errors, teams, productPriceList, orders, teamClient, payments, typePayments],
	props: ['id'],
	data() {
		return {
			client: {},
			listProducts: [],
			productIdSelected: {
				id: 0,
				name: 'Sin definir'
			},
			teamClientIdSelected: 0,
			typeOrderId: 0,
			typeOrderIdNotConsiderate: [14, 15],
			typeOrderIdSumaClient: [14, 15],
			messagePayment: '',
			chartOptions: {
				responsive: true,
				aspectRadio: 1.8,
				plugins: {
					legend: {
						display: false
					}
				}
			},
			percentageProductsTeamVSProductsAssigned: 0,
			percentageProductsAssignedVSPaymentTeams: 0,
			percentagePaymentTeamsVSPaymentACES: 0,
		}
	},
	components: {
		'order-colportaje-transfer-modal-component': OrderColportajeTransferModalComponent,
		'doughnut-chart': DoughnutChart,
	},
	computed: {
		chartDataProductsTeamVSProductsAssigned() {
			this.percentageProductsTeamVSProductsAssigned = (this.totalClient(-1, 'total', true) * 100) / this.sumOrdersTotal
			this.percentageProductsTeamVSProductsAssigned = this.percentageProductsTeamVSProductsAssigned.toFixed(0)
			let chartData = {
				datasets: [{
					data: [this.percentageProductsTeamVSProductsAssigned, 100 - this.percentageProductsTeamVSProductsAssigned],
					circumference: 180,
					rotation: 270,
					cutout: '75%',
					backgroundColor: ['#2c7be5', '#d8e2ef']
				}],
			}
			return chartData
		},
		chartDataProductsAssignedVSPaymentTeams() {
			this.percentageProductsAssignedVSPaymentTeams = (this.sumTeamClientPaymentLocal * 100) / this.totalClient(-1, 'total', true)
			this.percentageProductsAssignedVSPaymentTeams = this.percentageProductsAssignedVSPaymentTeams.toFixed(0)
			let chartData = {
				datasets: [{
					data: [this.percentageProductsAssignedVSPaymentTeams, 100 - this.percentageProductsAssignedVSPaymentTeams],
					circumference: 180,
					rotation: 270,
					cutout: '75%',
					backgroundColor: ['#0077b5', '#d8e2ef']
				}],
			}
			return chartData
		},
		chartDataPaymentTeamsVSPaymentACES() {
			this.percentagePaymentTeamsVSPaymentACES = (this.sumConsignmentsTotal * 100) / this.sumTeamClientPaymentLocal
			this.percentagePaymentTeamsVSPaymentACES = this.percentagePaymentTeamsVSPaymentACES.toFixed(0)
			let chartData = {
				datasets: [{
					data: [parseInt(this.percentagePaymentTeamsVSPaymentACES), 100 - this.percentagePaymentTeamsVSPaymentACES],
					circumference: 180,
					rotation: 270,
					cutout: '75%',
					backgroundColor: ['#27bcfd', '#d8e2ef']
				}],
			}

			console.log(chartData)
			return chartData
		},
		disabledSavePayment() {
			if (this.teamClientIdSelected == 0)
				return true
			if (this.objectPayment.amount == 0)
				return true
			if (this.savingPayment)
				return this.savingPayment
			return false
		},
		listProductsFilter() {
			let list = []
			let index = -1
			this.objectTeam.orders.forEach(order => {
				if (order.type_order_id != 7) {
					if (order.status_id != 30) {
						order.order_products.forEach(p => {
							p.factor = (order.type_order_id == 4 || order.type_order_id == 6 || order.type_order_id == 14) ? -1 : (order.type_order_id == 7) ? 0 : 1
							list.push({
								product_id: p.product_id,
								product: p.product,
								quantity: p.quantity,
								price: p.price,
								tax: p.tax,
								price_list_id: p.price_list_id,
								with_freight: p.with_freight,
								bonusable: p.bonusable,
								tithable: p.tithable,
								synthetic: p.synthetic,
								factor: p.factor
							})
						});
					}
				}
			});

			list = list.sort(function (a, b) { return (a.product > b.product) ? 1 : (a.product < b.product) ? -1 : 0 });
			let _list = []
			let repeated = false
			for (let p in list) {
				repeated = false
				for (let pr in _list) {
					if (list[p].product_id == _list[pr].product_id && list[p].price == _list[pr].price) {
						repeated = true
						_list[pr].quantity = parseFloat(_list[pr].quantity) + (parseFloat(list[p].quantity) * parseFloat(list[p].factor))
					}
				}
				if (!repeated) {
					list[p].quantity = (parseFloat(list[p].quantity) * parseFloat(list[p].factor))
					_list.push(list[p])
				}
			}
			this.listProducts = _list
			//_list = _list.filter(p => { return (p.quantity > 0) })
			return _list
		},
		listProductsDetailForId() {
			let list = []
			this.objectTeam.orders.forEach(o => {
				if (o.type_order_id != 7) {
					o.order_products.forEach(p => {
						if (p.product_id == this.productIdSelected.id) {
							this.productIdSelected.name = p.product
							p.type_order = o.type_order
							p.client = o.client
							p.factor = (o.type_order_id == 4 || o.type_order_id == 6 || o.type_order_id == 14) ? -1 : (o.type_order_id == 7) ? 0 : 1
							list.push(p)
						}
					})
				}
			})
			return list
		},
		sumProductCurrent() {
			let total = 0
			total = this.listProductsDetailForId.reduce(function (a, b) {
				return parseFloat(a) + parseFloat(b.quantity * b.factor)
			}, 0)
			return total.toFixed(0)
		},
		sumTeamClientPaymentLocal() {
			let total = 0
			total = this.objectTeam.clients.reduce(function (a, b) {
				let t = b.payments.reduce(function (c, d) {
					return parseFloat(c) + parseFloat(d.amount)
				}, 0)
				return parseFloat(a) + parseFloat(t)
			}, 0)
			return total.toFixed(0)
		},
		sumConsignmentsTotal() {
			let total = 0
			total = this.objectTeam.consignments.reduce(function (a, b) {
				let z = b.payments.reduce(function (c, d) {
					let f = d.amount
					return parseFloat(c) + parseFloat(f)
				}, 0)
				return parseFloat(a) + parseFloat(z)
			}, 0)
			return parseFloat(total.toFixed(0))
		},
		sumOrdersTotal() {
			let total = 0
			total = this.objectTeam.orders.reduce((a, b) => {
				let c = 0
				let index = this.typeOrderIdNotConsiderate.map(el => parseInt(el)).indexOf(parseInt(b.type_order_id))
				if (index == -1) {
					c = (b.type_order_id == 4 || b.type_order_id == 6) ? -1 * parseFloat(b.total) : parseFloat(b.total)
				}
				return parseFloat(a) + parseFloat(c)
			}, 0);
			return total.toFixed(0)
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getTeamClientsResult': this.getTeamClientsResult(response); break;
				case 'getTeamOrdersResult': this.getTeamOrdersResult(response); break;
				case 'getProductPriceListNotBonusResult': this.getProductPriceListNotBonusResult(response); break;
				case 'getProductPriceListNotFreightResult': this.getProductPriceListNotFreightResult(response); break;
				case 'getAllTypePaymentsResult': this.getAllTypePaymentsResult(response); break;
				case 'savePaymentResult': this.savePaymentResult(response); break;
				case 'showTeamResult': this.showTeamResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getProductPriceListNotBonusResultLocal(response) { },
		getProductPriceListNotFreightResultLocal(response) { },
		getTeamClientsResultLocal(response) { },
		getTeamOrdersResultLocal(response) { },
		getAllTypePaymentsResultLocal(response) { },
		initModuleLocal() {
			console.clear()
			this.setTeamAdminModule()
		},
		percentageProductsAssignedTeamVSPaymentToLeader(item) {
			let p = (this.sumTeamClientPayment(item.payments) * 100) / this.totalClient(item.client_id, 'total', false)
			return p.toFixed(0)
		},
		savePaymentLocal() {
			this.savePayment('teamClient', this.teamClientIdSelected)
		},
		savePaymentResultLocal(response) {
			if (response.data.success == true) {
				let index = this.objectTeam.clients.map(e => parseInt(e.id)).indexOf(parseInt(this.teamClientIdSelected))
				if (index > -1) {
					this.objectTeam.clients[index].payments.push(response.data.data.payment)
				}
				this.$refs.btnCloseNewTeamClientPaymentModal.click()
				this.clearObjectPayment()
				if (response.data.data.payment.source.length != 0) {
					if (response.data.data.payment.source.type == 'consignment') {
						this.messagePayment = 'Se asignó automaticamente a la remesa "' + response.data.data.payment.source.data[0].description + '" - #' + response.data.data.payment.source.data[0].id
						setTimeout(() => { this.messagePayment = '' }, 5000)
					}
				}
			}
		},
		saveOrderLocal(response) {
			this.objectTeam.orders.push(response.data.data)
		},
		setTeamAdminModule() {
			this.showTeamFull(this.id)
			this.getAllTypePayments()
		},
		showOrderTransferLocal(typeOrderId, client) {
			this.typeOrderId = typeOrderId
			this.client = client.client
			setTimeout(() => {
				this.$refs.orderColportajeTransferComponent.$refs.btnShowOrderColportajeTransferModalComponent.click()
			}, 100)
		},
		showListProductDetailLocal(productId) {
			this.productIdSelected.id = productId
		},
		showNewTeamClientPaymentModal(teamClientId) {
			this.teamClientIdSelected = teamClientId
			this.clearObjectPayment()
			this.$refs.btnNewTeamClientPaymentModal.click()
			setTimeout(() => { this.$refs.amount.focus() }, 500)
		},
		showTeamResultLocal(response) {
			if (response.data.success == true) {
				this.getTeamClients(this.objectTeam.id)
				this.priceListsIdSelect = this.teamPriceListId
				this.getProductPriceListNotBonus(this.priceListsIdSelect)
				this.getProductPriceListNotFreight(this.priceListsIdSelect)
			}
		},
		totalClient(clientId, type = 'total', all = false) {
			let total = 0
			let index = -1
			let factor = 1
			let precio = 0
			this.objectTeam.orders.forEach(o => {
				index = this.typeOrderIdSumaClient.map(e => parseInt(e)).indexOf(parseInt(o.type_order_id))
				if ((o.client_id == clientId && index > -1) || (all && index > -1)) {
					factor = (o.type_order_id == 14) ? 1 : -1
					if (type == 'total' || type == 'bonusable') {
						o.order_products.forEach(op => {
							precio = 0
							if (type == 'total') {
								total = parseInt(total) + ((parseInt(op.price) * parseInt(op.quantity)) * factor)
							} else {
								if (op.bonusable == true) {
									precio = ((op.price - op.with_freight) * parseInt(op.quantity)) * factor
									total = parseInt(total) + parseInt(precio)
								}
							}
						})
					} else if (type == 'tithe') {
						total += parseInt(o.tithe) * factor
					}
				}
			})
			return total.toFixed(0)
		},
		totalClientTithe(clientId, type = 'payment') {
			let total = 0
			let index = this.objectTeam.clients.map(e => parseInt(e.client_id)).indexOf(parseInt(clientId))
			if (index > -1) {
				if (type == 'payment' || type == 'commission') {
					total = this.objectTeam.clients[index].payments.reduce(function (c, d) {
						let a = 0
						if (type == 'payment') {
							a = d.amount
						} else {
							a = (d.amount * (d.pivot.percentage / 100))
						}
						return parseFloat(c) + parseFloat(a)
					}, 0)
				} else if (type == 'expense') {
					total = this.objectTeam.clients[index].expenses.reduce(function (c, d) {
						return parseFloat(c) + parseFloat(d.amount)
					}, 0)

				}

			}
			return total.toFixed(0)
		},
		totalDebt(clientId) {
			let total = this.totalClient(clientId, 'total', false)
			let tithe = this.totalClient(clientId, 'tithe', false)
			let expense = this.totalClientTithe(clientId, 'expense', false)
			let commission = this.totalClientTithe(clientId, 'commission', false)
			let payment = this.totalClientTithe(clientId, 'payment', false)
			let result = (parseFloat(total) + parseFloat(tithe) + parseFloat(expense) + parseFloat(commission)) - parseFloat(payment)

			if (clientId == 4345) {
				console.log('total', total)
				console.log('tithe', tithe)
				console.log('expense', expense)
				console.log('commission', commission)
				console.log('payment', payment)
			}
			return (result > 0) ? result.toFixed(0) : 0
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}	
</script>

<style lang="scss"></style>
