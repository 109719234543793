/* Rquiere de mixin.js */

var supplier = {
    data() {
        return {
            listSuppliers: [],
            paginationListSuppliers: [],
            searchSuppliers: '',
            priceListIdSelected: 0,
            searchingSupplier: false,
            loadingSupplier: false,
            objectSupplier: {
                exists: 0,
                id: 0,
                name: '',
                document_number: '',
                address: '',
                comuna_id: null,
                comuna: null,
                contact: '',
                email: '',
                phone: '',
                account_Code: null,
                account: null,
                sub_account_Code: null,
                sub_account: null
            },
            accountCodeDefault: 2130101,
            savingSupplier: false
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        addItemListSuppliers(s) {
            this.listSuppliers.push(s)
        },
        clearObjectSupplier() {
            this.objectSupplier.exists = 0
            this.objectSupplier.id = 0
            this.objectSupplier.name = ''
            this.objectSupplier.document_number = ''
            this.objectSupplier.address = ''
            this.objectSupplier.comuna_id = null
            this.objectSupplier.comuna = null
            this.objectSupplier.contact = ''
            this.objectSupplier.email = ''
            this.objectSupplier.phone = ''
            this.objectSupplier.account_Code = this.accountCodeDefault
            this.objectSupplier.account = null
            this.objectSupplier.sub_account_Code = null
            this.objectSupplier.sub_account = null
        },
        getSuppliers(page = 1) {
            this.searchingSupplier = true
            let parameters = '?search=' + this.searchSuppliers + '&page=' + page
            let url = this.getApiBackendNew + 'suppliers' + parameters
            this.axiosActionIS(url, 'GET', 'getSuppliersResult')
        },
        getSuppliersResult(response) {
            this.searchingSupplier = false
            if (response.data.success == true) {
                this.listSuppliers = response.data.data
            }
            this.getSuppliersResultLocal(response)
        },
        getSuppliersResultLocal(response) {
            console.log('getSuppliersResultLocal no desarrollado', response)
        },
        setItemListSuppliers(s) {
            let i = this.listSuppliers.map(ls => parseInt(ls.id)).indexOf(parseInt(s.id))
            if (i > -1) {
                for (const property in this.listSuppliers[i]) {
                    this.listSuppliers[i][property] = s[property]
                }
            }
        },
        setObjectSupplier(s) {
            this.clearObjectSupplier()
            let ns = Object.create(s)
            for (const property in ns) {
                this.objectSupplier[property] = ns[property]
            }
        },
        saveSupplier(s) {
            let method = (s.id == 0) ? 'POST' : 'PUT'
            this.savingSupplier = true
            let url = this.getApiBackendNew
            url += (method == 'POST') ? 'supplier/register' : 'supplier/' + s.id + '/update'
            this.axiosActionIS(url, method, 'saveSupplierResult', s)
        },
        saveSupplierResult(response) {
            this.savingSupplier = false
            if (response.data.success == true) {
                this.setObjectSupplier(response.data.data)
            }
            this.saveSupplierResultLocal(response)
        },
        saveSupplierResultLocal(response) {
            console.log('saveSupplierResultLocal no desarrollado', response)
        },
        showSupplier(id) {
            this.searchingSupplier = true
            let url = this.getApiBackendNew + 'supplier/' + id
            this.axiosActionIS(url, 'GET', 'showSupplierResult')
        },
        showSupplierResult(response) {
            this.searchingSupplier = false
            if (response.data.success == true) {
                this.setObjectSupplier(response.data.data)
            }
            this.showSupplierResultLocal(response)
        },
        showSupplierResultLocal(response) {
            console.log('showSupplierResultLocal no desarrollado', response)
        }
    },
};

export default supplier;