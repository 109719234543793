var budget = {
	data() {
		return {
			loadingBudgets: false,
			listBudgets: [],
			paginationBudgets: [],
			objectBudget: {
				exists: 0,
				id: 0,
				name: '',
				width: null,
				height: null,
				depth: null,
				print_type_id: null,
				print_type: null,
				design: null,
				client_id: null,
				client: null,
				contact: '',
				phone: '',
				email: '',
				status_id: null,
				status: null,
				components: [],
				quantities: []
			},
			objectBudgetComponent: {
				exists: 0,
				id: 0,
				budget_id: null,
				budget: '',
				pages: 0,
				format: '',
				color: '',
				type_paper_id: 0,
				grammage: '',
				dimension_original: '',
				technology_id: 0,
				dimension_end: ''
			},
			objectBudgetQuantity: {
				id: 0,
				budget_id: 0,
				quantity: 0
			},
			savingbudget: false,
			searchingbudget: false,
			searchBudgets: ''
		}
	},
	methods: {
		addItemListbudgets(budgetVar) {
			this.listbudgets.unshift(budgetVar)
		},
		addNewItemBlankObjectBudgetComponent() {
			this.clearObjectBudgetComponent()
			let propiertiesItem = Object.create(this.objectBudgetComponent)
			let newObject = new Object()
			for (const property in propiertiesItem) {
				Object.defineProperty(newObject, property, { value: propiertiesItem[property], writable: true, enumerable: true, configurable: true })
			}
			this.objectBudget.components.push(newObject)
		},
		clearObjectBudget() {
			this.objectBudget.exists = 0
			this.objectBudget.id = 0
			this.objectBudget.name = ''
			this.objectBudget.width = null
			this.objectBudget.height = null
			this.objectBudget.depth = null
			this.objectBudget.print_type_id = null
			this.objectBudget.print_type = null
			this.objectBudget.design = null
			this.objectBudget.client_id = null
			this.objectBudget.client = null
			this.objectBudget.contact = ''
			this.objectBudget.phone = ''
			this.objectBudget.email = ''
			this.objectBudget.status_id = null
			this.objectBudget.status = null
			this.components = []
			this.quantities = []
			this.addNewItemBlankObjectBudgetComponent()
			this.clearObjectBudgetQuantity()
		},
		clearObjectBudgetComponent() {
			this.objectBudgetComponent.id = 0
			this.objectBudgetComponent.budget_id = null
			this.objectBudgetComponent.budget = null
			this.objectBudgetComponent.pages = 0
			this.objectBudgetComponent.format = ''
			this.objectBudgetComponent.color = ''
			this.objectBudgetComponent.type_paper_id = 0
			this.objectBudgetComponent.grammage = ''
			this.objectBudgetComponent.dimension_original = ''
			this.objectBudgetComponent.technology_id = 0
			this.objectBudgetComponent.dimension_end = ''
		},
		clearObjectBudgetQuantity() {
			this.objectBudgetQuantity.id = 0
			this.objectBudgetQuantity.budget_id = 0
			this.objectBudgetQuantity.quantity = 0
		},
		getBudgets(page = 1) {
			this.listBudgets = []
			this.paginationBudgets = []
			this.loadingBudgets = true
			let param = '?page=' + page + '&search=' + this.searchBudgets
			let url = this.getApiBackendNew + 'budgets' + param
			this.axiosActionIS(url, 'GET', 'getBudgetsResult')
		},
		getBudgetsResult(response) {
			this.loadingBudgets = false
			if (response.data.success = true) {
				this.listBudgets = response.data.data
				this.paginationBudgets = response.data.paginate
			}
			this.getBudgetsResultLocal(response)
		},
		getBudgetsResultLocal(response) {
			console.log('getBudgetsResultLocal no desarrollado', response)
		},
		saveBudget(b) {
			this.savingBudget = true
			let method = (b.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'budget/'
			url += (method == 'POST') ? 'register' : b.id + '/update'
			this.axiosActionIS(url, method, 'saveBudgetResult', b)
		},
		saveBudgetResult(response) {
			this.savingBudget = false
			if (response.data.success == true) {
				this.setObjectBudget(response.data.data)
			}
			this.saveBudgetResultLocal(response)
		},
		saveBudgetResultLocal(response) {
			console.log('saveBudgetResultLocal no desarrollado', response)
		},
		setObjectBudget(b) {
			this.clearObjectBudget()
			for (const property in b) {
				this.objectBudget[property] = r[property]
			}
		},
		showBudget(id) {
			this.searchingBudget = true
			let url = this.getApiBackendNew + 'budget/' + id
			this.axiosActionIS(url, 'GET', 'showBudgetResult')
		},
		showBudgetResult(response) {
			this.searchingBudget = false
			if (response.data.success == true) {
				this.setObjectBudget(response.data.data)
			}
			this.showBudgetResultLocal(response)
		},
		showBudgetResultLocal(response) {
			console.log('showBudgetResultLocal no desarrollado', response)
		}
	},
};

export default budget;