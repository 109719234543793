<template>
	<div id="inventoryTemplateDetailComponent">
		<div v-show="false == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Titulo">
			</mensagge-loading-component>
		</div>
		<div class="row">
			<div :class="positionClassLocal(1)">
				<div class="mb-1">
					<label class="form-label" for="tipocompSelect">Plantilla:</label>
					<select class="form-select form-select-sm" aria-label=".form-select-sm example">
						<option :value="null" disabled selected>Seleccione una plantilla</option>
					</select>
				</div>
			</div>
			<div :class="positionClassLocal(2)">
				<div class="mb-1">
					<label class="form-label" for="tipocompSelect">Ubicación:</label>
					<select class="form-select form-select-sm" aria-label=".form-select-sm example">
						<option :value="null" disabled selected>Seleccione una ubicación</option>
					</select>
				</div>
			</div>
			<div :class="positionClassLocal(3)">
				<div class="mb-1">
					<label class="form-label" for="tipocompSelect">Tipo de registro:</label>
					<select class="form-select form-select-sm" aria-label=".form-select-sm example">
						<option :value="null" disabled selected>Seleccione un tipo de registro</option>
						<option>Producto</option>
						<option>Material</option>
					</select>
				</div>
			</div>
			<div :class="positionClassLocal(4)">
				<div class="mb-1">
					<label class="form-label" for="tipocompSelect">Producto o Material:</label>
					<input class="form-control form-control-sm" placeholder="Ingrese codigo, isbn o palabra" />
				</div>
			</div>
			<div :class="positionClassLocal(5)">
				<div class="mb-1">
					<label class="form-label" for="tipocompSelect">Conteo:</label>
					<input class="form-control form-control-sm" placeholder="Ingrese el conteo" />
					<div class="form-check text-400">
						<input class="form-check-input" id="flexCheckIndeterminate" type="checkbox" value="" />
						<label class="form-check-label" for="flexCheckIndeterminate">Marcar como definitivo</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'InventoryTemplateDetailComponent',
	mixins: [mixin, axiosAction, errors],
	props: [],
	data() {
		return {
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'defaultResult': this.defaultResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getInventoryTemplateDetailLocal(page = 1) { },
		initModuleLocal() {
			this.setInventoryTemplateDetailInit()
		},
		positionClassLocal() {
			return 'col-12'
		},
		setInventoryTemplateDetailInit() { },
		showInventoryTemplateDetailLocal() { },
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>