var inventoryTemplate = {
	data() {
		return {
			loadingListInventoryTemplates: false,
			listInventoryTemplates: [],
			objectInventoryTemplate: {
				exists: 0,
				id: 0,
				name: '',
				inventory_id: null,
				inventory: null,
				status_id: null,
				status: null,
				employees: [],
				store_rack_side_row_columns: [],
				content: [],
				created_at: null,
				updated_at: null
			},
			paginationListInventoryTemplates: [],
			savingInventoryTemplate: false,
			searchInventoryTemplate: '',
			searchingInventoryTemplate: false
		}
	},
	methods: {
		addItemListInventoryTemplates(it) {
			this.listInventoryTemplates.push(it)
		},
		clearObjectInventoryTemplate() {
			this.objectInventoryTemplate.exists = 0
			this.objectInventoryTemplate.id = 0
			this.objectInventoryTemplate.name = ''
			this.objectInventoryTemplate.inventory_id = null
			this.objectInventoryTemplate.inventory = null
			this.objectInventoryTemplate.status_id = null
			this.objectInventoryTemplate.status = null
			this.objectInventoryTemplate.employees = []
			this.objectInventoryTemplate.store_rack_side_row_columns = []
			this.objectInventoryTemplate.content = []
			this.objectInventoryTemplate.created_at = null
			this.objectInventoryTemplate.updated_at = null
		},
		getInventoryTemplates(inventoryId, page = 1, resource = 'List') {
			this.listInventoryTemplates = []
			this.paginationListInventoryTemplates = []
			this.loadingListInventoryTemplates = true
			let param = '?page=' + page + '&search=' + this.searchInventoryTemplate + '&resource=' + resource
			let url = this.getApiBackendNew + 'inventory/' + inventoryId + '/templates' + param
			this.axiosActionIS(url, 'GET', 'getInventoryTemplatesResult')
		},
		getInventoryTemplatesResult(response) {
			this.loadingListInventoryTemplates = false
			if (response.data.success == true) {
				this.listInventoryTemplates = response.data.data
				this.paginationListInventoryTemplates = response.data.paginate
			}
			this.getInventoryTemplatesResultLocal(response)
		},
		getInventoryTemplatesResultLocal(response) {
			console.log('getInventoryTemplatesResultLocal no desarrollado', response)
		},
		saveInventoryTemplate(it) {
			this.savingInventoryTemplate = true
			let method = (it.id == 0) ? 'POST' : 'PUT'
			let url = this.getApiBackendNew + 'inventory/' + it.inventory_id
			url += (method == 'POST') ? '/template/register' : it.id + '/update'
			this.axiosActionIS(url, method, 'saveInventoryTemplateResult', it)
		},
		saveInventoryTemplateResult(response) {
			this.savingInventoryTemplate = false
			if (response.data.success == true) {
				this.setObjectInventoryTemplate(response.data.data)
			}
			this.saveInventoryTemplateResultLocal(response)
		},
		saveInventoryTemplateResultLocal(response) {
			console.log('saveInventoryTemplateResultLocal no desarrollado', response)
		},
		setObjectInventoryTemplate(it) {
			this.clearObjectInventoryTemplate()
			for (const property in it) {
				this.objectInventoryTemplate[property] = it[property]
			}
		},
	},
};

export default inventoryTemplate;