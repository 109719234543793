var storeRackSide = {
	data() {
		return {
			listStoreRackSides: [],
			paginationStoreRackSides: [],
			loadingStoreRackSides: false,
			objectStoreRackSide: {
				exists: 0,
				id: 0,
				name: '',
				color: null,
				reverse: 0,
				store_id: null,
				store: null,
				store_rack_id: null,
				store_rack: null,
				status_id: null,
				status: null,
				code: null,
				rows: [],
				created_at: null,
				updated_at: null
			},
			searchingStoreRackSide: false
		}
	},
	methods: {
		clearObjectStoreRackSide() {
			this.objectStoreRackSide.exists = 0
			this.objectStoreRackSide.id = 0
			this.objectStoreRackSide.name = ''
			this.objectStoreRackSide.color = null
			this.objectStoreRackSide.reverse = 0
			this.objectStoreRackSide.store_id = null
			this.objectStoreRackSide.store = null
			this.objectStoreRackSide.store_rack_id = null
			this.objectStoreRackSide.store_rack = null
			this.objectStoreRackSide.status_id = null
			this.objectStoreRackSide.status = null
			this.objectStoreRackSide.code = null
			this.objectStoreRackSide.rows = []
			this.objectStoreRackSide.created_at = null
			this.objectStoreRackSide.updated_at = null
		},
		getStoreRackSidesByStoreId(storeRackId) {
			this.loadingStoreRackSides = true
			this.listStoreRackSides = []
			this.paginationStoreRackSides = []
			let url = this.getApiBackendNew + 'storeRack/' + storeRackId + '/sides'
			this.axiosActionIS(url, 'GET', 'getStoreRackSidesResult')
		},
		getStoreRackSidesResult(response) {
			this.loadingStoreRackSides = false
			if (response.data.success == true) {
				this.listStoreRackSides = response.data.data
				this.paginationStoreRackSides = response.data.paginate
			}
			this.getStoreRackSidesResultLocal(response)
		},
		getStoreRackSidesResultLocal(response) {
			console.log('getStoreRackSidesResultLocal no desarrollado', response)
		},
		setObjectStoreRackSide(srs) {
			this.clearObjectStoreRackSide()
			for (const property in srs) {
				this.objectStoreRackSide[property] = srs[property]
			}
		},
		showStoreRackSide(id, resource = 'default') {
			this.searchingStoreRackSide = true
			let param = '?resource=' + resource
			let url = this.getApiBackendNew + 'storeRackSide/' + id + param
			this.axiosActionIS(url, 'GET', 'showStoreRackSideResult')
		},
		showStoreRackSideResult(response) {
			this.searchingStoreRackSide = false
			if (response.data.success == true) {
				this.setObjectStoreRackSide(response.data.data)
			}
			this.showStoreRackSideResultLocal(response)
		},
		showStoreRackSideResultLocal(response) {
			console.log('showStoreRackSideResultLocal no desarrollado', response)
		}
	},
};

export default storeRackSide;