var aasinet = {
    data() {
        return {
            certificate: [
                { 'name': 'Integración', 'val': '1D3F0284-395F-4DEE-AF9D-B2B1B9B1B7B8' },
                { 'name': 'Producción', 'val': '9191C103-4385-4913-A1D8-E68A1BF57AEC' }
            ],
            certificateSelected: 1,
            entity: 9413,
            externalSystem: 67,
            gettingToken: false,
            token: '',
            xmlAasinet: '<ASSIS><Context><AccountingEntity>{{Entity}}</AccountingEntity><Certificate>{{Certificate}}</Certificate></Context>{{Component}}{{Parameters}}{{Content}}</ASSIS>',
            listComponents: [
                {
                    name: 'ChartOfAccounts',
                    messageLoading: 'Obteniendo Plan de cuentas desde AASINET',
                    xmlComponent: '<Component><Name>ChartOfAccounts</Name></Component>',
                    xmlParameters: '<Parameters/>',
                    xmlContent: ''
                },
                {
                    name: 'SubAccountSyncList',
                    xmlComponent: '<Component><Name>SubAccountSyncList</Name></Component>',
                    xmlParameters: '<Parameters><SubAccountsSyncListParams><UpdatedSince>2010-01-01</UpdatedSince></SubAccountsSyncListParams></Parameters>',
                    xmlContent: ''
                },
                {
                    name: 'ExternalAccountingPostPeriod',
                    messageLoading: 'Cerrando Periodo...',
                    xmlComponent: '<Component><Name>ExternalAccountingPostPeriod</Name></Component>',
                    xmlParameters: '<Parameters><ExternalAccountingPostPeriodParams><ExternalSystem>{{ExternalSystem}}</ExternalSystem><PeriodToClose>{{Content}}</PeriodToClose></ExternalAccountingPostPeriodParams></Parameters>',
                    xmlContent: ''
                },
                {
                    name: 'PeriodStatusInfo',
                    messageLoading: 'Cargando Periodos Periodo...',
                    xmlComponent: '<Component><Name>PeriodStatusInfo</Name></Component>',
                    xmlParameters: '<Parameters><PeriodStatusInfoParams><Year>2025</Year><Month></Month></PeriodStatusInfoParams></Parameters>',
                    xmlContent: ''
                },
                {
                    name: 'ChartOfAccountsAuxiliary',
                    messageLoading: '',
                    xmlComponent: '<Component><Name>ChartOfAccountsAuxiliary</Name></Component>',
                    xmlParameters: '<Parameters/>',
                    xmlContent: ''
                },
                {
                    name: 'ChartOfFunctions',
                    messageLoading: '',
                    xmlComponent: '<Component><Name>ChartOfFunctions</Name></Component>',
                    xmlParameters: '<Parameters><ChartOfFunctionsParams><IsStandard>false</IsStandard></ChartOfFunctionsParams></Parameters>',
                    xmlContent: ''
                },
                {
                    name: 'ExternalMultipleAccounting',
                    messageLoading: '',
                    xmlComponent: '<Component><Name>ExternalMultipleAccounting</Name></Component>',
                    xmlParameters: '<Parameters><ExternalMultipleAccountingParams><ExternalSystem>{{ExternalSystem}}</ExternalSystem></ExternalMultipleAccountingParams></Parameters>',
                    xmlContent: '<Content><JournalList>{{Content}}</JournalList></Content>'
                },
                {
                    name: 'ChartOfFunds',
                    messageLoading: '',
                    xmlComponent: '<Component><Name>ChartOfFunds</Name></Component>',
                    xmlParameters: '<Parameters><ChartOfFundsParams><AcceptEntries>true</AcceptEntries></ChartOfFundsParams></Parameters>',
                    xmlContent: ''
                },
            ],
            listComponentsSelected: 0,
            listAccounts: [
                {
                    code: '1111005',
                    name: 'Caja Departamentos',
                    type_sub_account_code: 'D070',
                    group: '070',
                    restrictions: ['0A']
                },
                {
                    code: '1112001',
                    name: 'Bancos Cuenta Corrientes',
                    type_sub_account_code: 'CTB',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '1131001',
                    name: 'Clientes Nacionales a Cobrar',
                    type_sub_account_code: 'CLIE',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '1134001',
                    name: 'Otras Cuentas a Cobrar',
                    type_sub_account_code: 'CLIE',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '1134002',
                    name: 'Colportores Estudiantes a Cobrar',
                    type_sub_account_code: 'CLIE',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '1139001',
                    name: 'Cheques de Cobro Diferido a Depositar',
                    type_sub_account_code: 'D604',
                    group: '604',
                    restrictions: ['0A']
                },
                {
                    code: '1139090',
                    name: 'Otras Cuentas a Cobrar',
                    type_sub_account_code: 'D604',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '1138520',
                    name: 'IVA Crédito Fiscal a Compensar',
                    type_sub_account_code: 'D403',
                    group: '403',
                    restrictions: ['0A']
                },
                {
                    code: '1141001',
                    name: 'Inventario de Mercaderias',
                    type_sub_account_code: 'D482',
                    group: '482',
                    restrictions: ['0A']
                },
                {
                    code: '1144001',
                    name: 'Inventario de Materia-Prima',
                    type_sub_account_code: 'D227',
                    group: '227',
                    restrictions: ['0A']
                },
                {
                    code: '1136001',
                    name: 'Entidades Internas a Cobrar',
                    type_sub_account_code: 'D227',
                    group: '227',
                    restrictions: ['0A']
                },
                {
                    code: '1136010',
                    name: 'Entidades Congéneres a Cobrar',
                    type_sub_account_code: 'D227',
                    group: '227',
                    restrictions: ['0A']
                },
                {
                    code: '2130101',
                    name: 'Proveedores a Pagar',
                    type_sub_account_code: 'FORN',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '2151025',
                    name: 'Fondo Proyectos/Diversos',
                    type_sub_account_code: 'D314',
                    group: '314',
                    restrictions: ['0A']
                },
                {
                    code: '1149501',
                    name: '(-)Previsión para Ajuste de Inventarios',
                    type_sub_account_code: null,
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '1135001',
                    name: 'Adelanto Personal a Cobrar',
                    type_sub_account_code: 'FUNC',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '1135005',
                    name: 'Adelanto Reemb./Reintegro Gastos y Ayudas a Cobrar',
                    type_sub_account_code: 'FUNC',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '2136010',
                    name: 'Entidades Congéneres a Pagar',
                    type_sub_account_code: 'FUNC',
                    group: null,
                    restrictions: ['0A']
                },
                {
                    code: '3182090',
                    name: 'Otros Ingresos Recurrientes',
                    type_sub_account_code: 'D559',
                    group: '559',
                    restrictions: ['2']
                },
                {
                    code: '4121030',
                    name: 'Fletes y Acarreos',
                    type_sub_account_code: null,
                    group: null,
                    restrictions: ['2']
                },
                {
                    code: '4121585',
                    name: 'Material de Consumo',
                    type_sub_account_code: 'D480',
                    group: '480',
                    restrictions: ['2']
                },
                {
                    code: '4121017',
                    name: 'Correos, Currier y Bolsin',
                    type_sub_account_code: null,
                    group: null,
                    restrictions: ['2']
                },
                {
                    code: '4213090',
                    name: 'Otros Gastos No Recurrientes',
                    type_sub_account_code: 'D162',
                    group: '162',
                    restrictions: ['2']
                },
                {
                    code: '4121555',
                    name: 'Material de Higiene y Limpieza',
                    type_sub_account_code: null,
                    group: null,
                    restrictions: ['2']
                },
                {
                    code: '4142030',
                    name: 'Comisión s/ Ventas',
                    type_sub_account_code: 'D076',
                    group: '076',
                    restrictions: ['2']
                },
                {
                    code: '3171001',
                    name: '(-)Costo de Mercaderias Vendidas',
                    type_sub_account_code: 'D482',
                    group: '482',
                    restrictions: ['2']
                },
                {
                    code: '3213090',
                    name: 'Otros Ingresos No Recurriente',
                    type_sub_account_code: 'D560',
                    group: '560',
                    restrictions: ['2']
                },
                {
                    code: '4142025',
                    name: 'Campañas y Promociones',
                    type_sub_account_code: 'D073',
                    group: '073',
                    restrictions: ['2']
                },
                {
                    code: '4129510',
                    name: '(-)Reemb./Reint.Gastos Administrativos y Generales',
                    type_sub_account_code: 'D687',
                    group: '687',
                    restrictions: ['2']
                },
                {
                    code: '4213015',
                    name: 'Gastos de Ejercicios Anteriores',
                    type_sub_account_code: 'D162',
                    group: '162',
                    restrictions: ['2']
                },
                {
                    code: '3189016',
                    name: 'Costo de Bienes siniestrados, obsoletos y otros',
                    type_sub_account_code: null,
                    group: null,
                    restrictions: ['2']
                },
                {
                    code: '4121550',
                    name: 'Material de Oficina',
                    type_sub_account_code: null,
                    group: null,
                    restrictions: ['2']
                },
            ],
            listFunctions: [
                { code: '131111', name: 'Administracion General', dsadpto: '101' },
                { code: '131331', name: 'Distribucion - Ventas', dsadpto: '201' },
                { code: '131332', name: 'Produccion - Taller', dsadpto: '301' },
            ],
            listRestrictions: [
                { code: '0A', name: 'Activo y Pasivo o Sin Restricción' },
                { code: '2', name: '2' },
                { code: '2', name: '2' },
            ],
            listSubAccounts: [
                {
                    type_sub_account_code: 'CTB',
                    code: '1',
                    name: 'Banco Estado',
                    group: null
                },
                {
                    type_sub_account_code: 'CTB',
                    code: '2',
                    name: 'Banco BCI',
                    group: null
                },
                {
                    type_sub_account_code: 'CTB',
                    code: '3',
                    name: 'Tansbank',
                    group: null
                },
                {
                    type_sub_account_code: 'D227',
                    code: '1',
                    name: 'Inventario Tintas',
                    group: '227'
                },
                {
                    type_sub_account_code: 'D227',
                    code: '2',
                    name: 'Inventario Planchas',
                    group: '227'
                },
                {
                    type_sub_account_code: 'D227',
                    code: '3',
                    name: 'Inventario Papeles',
                    group: '227'
                },
                {
                    type_sub_account_code: 'D403',
                    code: '1',
                    name: 'Iva Credito - Aces Santiago',
                    group: '403'
                },
                {
                    type_sub_account_code: 'D403',
                    code: '2',
                    name: 'Iva Credito - Libreria Temuco',
                    group: '403'
                },
                {
                    type_sub_account_code: 'D403',
                    code: '3',
                    name: 'Iva Debito - Aces Santiago',
                    group: '403'
                },
                {
                    type_sub_account_code: 'D482',
                    code: '2',
                    name: 'Denominacional',
                    group: '482'
                },
                {
                    type_sub_account_code: 'CLIE',
                    code: '610',
                    name: 'Colportores Permanentes - Independientes',
                    group: null
                },
                {
                    type_sub_account_code: 'CLIE',
                    code: '620',
                    name: 'Colportores Permanentes - En Campaña',
                    group: null
                },
                {
                    type_sub_account_code: 'CLIE',
                    code: '710',
                    name: 'Colportores Estudiantes - Campañas Verano',
                    group: null
                },
                {
                    type_sub_account_code: 'CLIE',
                    code: '720',
                    name: 'Colportores Estudiantes - Campañas Invierno',
                    group: null
                },
                {
                    type_sub_account_code: 'CLIE',
                    code: '730',
                    name: 'Colportores Estudiantes - Sueña en Grande',
                    group: null
                },
                {
                    type_sub_account_code: 'FUNC',
                    code: '122',
                    name: 'Quiroga Sandoval, Damaris Mercedes',
                    group: null
                },
                {
                    type_sub_account_code: 'D604',
                    code: '700',
                    name: 'Ventas Publico',
                    group: null
                },
                {
                    type_sub_account_code: 'D604',
                    code: '800',
                    name: 'Ventas con Facturas',
                    group: null
                }
            ],
            listTypeSubAccounts: [
                { code: 'CLIE', name: 'CLIENTE', group: true },
                { code: 'D403', name: 'D403', group: true },
                { code: 'D604', name: 'D604', group: true },
                { code: 'ENTI', name: 'ENTIDAD', group: false },
                { code: 'FORN', name: 'FORN', group: false },
                { code: 'CTB', name: 'CUENTA BANCARIA', group: true },
                { code: 'FUNC', name: 'FUNCIONARIO', group: true },
                { code: 'DEPTO', name: 'DEPARTAMENTO', group: false },
            ],
            listPeriods: [],
            urlConnection: 'https://aasinet.sdasystems.org/aces-ch/integration/payloadService/',
            urlToken: 'aasinet/token',
            userName: 'e15c3307-076d-4567-ab16-320c21abf8b0',
            xmlResult: null,
            jsonResult: {},
            sendingPayloadService: false,
            syncingAccounts: false
        }
    },
    computed: {
        currentPeriodAasinet() {
            let date = 'No Definido'
            this.listPeriods.forEach(p => {
                if (p.ClosedDate._text == undefined && date == 'No Definido') {
                    date = p.Year._text + '-' + p.Month._text + '-01'
                }
            })
            return date
        },
        postedPeriod() {
            if (this.currentPeriodAasinet != 'No Definido') {
                let dateArr = this.currentPeriodAasinet.split("-")
                dateArr[1] = (dateArr[1].length == 1) ? '0' + dateArr[1] : dateArr[1]
                return dateArr[1] + dateArr[0]
            }
            return 'Buscando Periodo...'
        },
    },
    filters: {
    },
    methods: {
        createXMLAasinet(component, paramters) {
            let index = this.listComponents.map((el) => el.name).indexOf(component)
            let xml
            if (index > -1) {
                switch (component) {
                    case 'ChartOfFunctions':
                        console.log(this.createXMLJournal(0, paramters))
                        break;
                    case 'ExternalMultipleAccounting':
                        console.log(this.createXMLJournal(1, paramters))
                        break;
                    default:
                        break;
                }
            }
            return xml
        },
        createXMLJournal(index, journal) {
            console.log(journal.JournalDate.substring(0, 10))
            let date = journal.JournalDate.substring(0, 10).split('-')
            let xml = '<Journal>'
            xml += '<ItemId>' + index + '</ItemId>'
            xml += '<PostedPeriod>' + journal.PostedPeriod + '</PostedPeriod>'
            xml += '<JournalDate>' + date[2] + date[1] + date[0] + '</JournalDate>'
            xml += '<Description>' + journal.Description + '</Description>'
            xml += '<Items>'
            let memo = false
            let send_memo = false
            Object.values(journal.items).forEach((item, i) => {
                memo = (item.memo != true || item.memo != false) ? (item.memo == 1) : item.memo
                send_memo = (item.send_memo != true || item.send_memo != false) ? (item.send_memo == 1) : item.send_memo
                xml += '<Item>'
                xml += '<ItemId>' + i + '</ItemId>'
                xml += '<AccountCode>' + item.account_Code + '</AccountCode>'
                if (item.sub_account_Code != null) {
                    xml += '<SubAccountCode>' + item.sub_account_Code + '</SubAccountCode>'
                } else {
                    xml += '<SubAccountCode/>'
                }
                xml += '<FundCode>' + item.fund_Code + '</FundCode>'
                xml += '<FunctionCode>' + item.function_Code + '</FunctionCode>'
                xml += '<RestrictionCode>' + item.restriction_Code + '</RestrictionCode>'
                xml += '<EntityValue>' + item.entity_value + '</EntityValue>'
                xml += '<Description>' + item.description.trim() + '</Description>'
                xml += '<Memo>' + memo + '</Memo>'
                xml += '<SendMemo>' + send_memo + '</SendMemo>'
                xml += '</Item>'
            })
            xml += '</Items>'
            xml += '</Journal>'
            return xml
        },
        getAccountName(code) {
            let index = this.listAccounts.map(el => el.code).indexOf(code)
            let val = 'No registrada'
            if (index > -1) {
                val = this.listAccounts[index].name
            }
            return val
        },
        getAccountRestrictionCode(code) {
            let index = this.listAccounts.map(el => parseInt(el.Code)).indexOf(parseInt(code))
            let val = 'N/E'
            if (index > -1) {
                val = this.listAccounts[index].restrictions[0].Code
            }
            return val
        },
        getFunctionCode(val) {
            let index = this.listFunctions.map((el) => parseInt(el.dsadpto)).indexOf(parseInt(val))
            if (index > -1) {
                val = this.listFunctions[index].code
            }
            return val
        },
        getHeadersAasinet() {
            return { 'Authorization': 'Bearer ' + this.token, 'Content-Type': 'text/plain' }
        },
        getIndexComponent(name) {
            let index = this.listComponents.map((el) => el.name).indexOf(name)
            return index
        },
        getTokenAasinet() {
            console.clear()
            console.log('Obteniendo Token...')
            this.gettingToken = true
            this.token = ''
            let url = this.getApiBackendNew + this.urlToken
            console.log(url)
            this.axiosActionIS(url, 'GET', 'getTokenAasinetResult')
        },
        getTokenAasinetResult(response) {
            this.gettingToken = false;
            if (response.data.result == 'OK') {
                console.log('Token Recibido!!!')
                this.token = response.data.message
            } else {
                console.log('Problemas al generar el Token')
            }
            this.getTokenAasinetResultLocal(response)
        },
        getTokenAasinetResultLocal(response) {
            console.log('No hay configurada ninguna acción despues de obtener el Token')
        },
        payloadService(index) {
            let component = this.listComponents[index]
            this.payloadServiceLoad(component)
        },
        payloadServiceLoad(component) {
            let xmlAasinet = this.xmlAasinet
            console.log('Ejecutando ' + component.name + 'load ...')
            xmlAasinet = this.replaceKey(xmlAasinet, 'Entity', this.entity)
            xmlAasinet = this.replaceKey(xmlAasinet, 'Certificate', this.certificate[this.certificateSelected].val)
            xmlAasinet = this.replaceKey(xmlAasinet, 'Component', component.xmlComponent)
            xmlAasinet = this.replaceKey(xmlAasinet, 'Parameters', component.xmlParameters)
            xmlAasinet = this.replaceKey(xmlAasinet, 'ExternalSystem', this.externalSystem)
            xmlAasinet = this.replaceKey(xmlAasinet, 'Content', component.xmlContent)
            //console.log(xmlAasinet)
            //console.log(this.xmlToJson(xmlAasinet))
            this.sendPayloadService(xmlAasinet, 'load')
        },
        payloadServiceLoadExecute(token) {
            console.log('Token del Load: ' + token)
            console.log('Execute: ' + token + ' ...')
            this.sendPayloadService(token, 'execute')
        },
        resultAxiosLocal(responseLocal, response) {
            switch (responseLocal) {
                case 'resultGetToken': this.resultGetToken(response); break;
                default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')'); break;
            }
        },
        sendPayloadServiceResult(response) {
            this.sendingPayloadService = false
            if (typeof response.data != undefined) {
                this.xmlResult = this.xmlToJson(response.data)
                if (typeof this.xmlResult.AASIS != undefined) {
                    if (this.xmlResult.AASIS.Messages.Exceptions) {
                        console.log('Exceptions!!! ' + this.xmlResult.AASIS.Messages.Exceptions.string._text)
                        alert('EXCEPTIONS!!!\n' + this.xmlResult.AASIS.Messages.Exceptions.string._text)
                    } else if (typeof this.xmlResult.AASIS.Result != undefined) {
                        if (Object.keys(this.xmlResult.AASIS.Result).length === 0) {
                            console.log('No hay resultados')
                        } else {
                            this.sendPayloadServiceResultLocal(this.xmlResult.AASIS.Result)
                        }
                    } else {
                        console.log('No está devolviendo datos')
                        console.log(this.xmlResult.AASIS.Messages)
                    }
                } else {
                    console.log('No esta devolviendo datos de AASINET')
                }
            } else {
                console.log('No devuelve datos para analizar')
            }
        },
        sendPayloadServiceResultLocal(result) {
            console.log('No está desarrollado', result)
        },
        replaceKey(xml, key, value) {
            return xml.replace(new RegExp('{{' + key + '}}', "gi"), value)
        },
        sendPayloadService(xmlAasinet, action) {
            this.sendingPayloadService = true
            let data = '{"UserName": "' + this.userName + '","XmlContent": "' + xmlAasinet + '"}'

            let config = {
                method: 'post',
                url: this.urlConnection + action,
                headers: this.getHeadersAasinet(),
                data: data
            };

            this.axios(config)
                .then(response => {
                    if (action == 'load') {
                        this.payloadServiceLoadExecute(response.data)
                    } else if (action == 'execute') {
                        this.sendPayloadServiceResult(response)
                    }
                })
                .catch(error => { console.log(error) })
        },
        synchronizeAccountsISystem(data) {
            this.syncingAccounts = true
            let url = this.getApiBackendNew + 'aasinet/synchronizeAccountsISystem'
            console.log(url, data)
            this.axiosActionIS(url, 'POST', 'synchronizeAccountsISystemResult', data)
        },
        synchronizeAccountsISystemResult(response) {
            this.syncingAccounts = false
            this.synchronizeAccountsISystemResultLocal(response)
        },
        synchronizeAccountsISystemResultLocal(response) {
            console.log('synchronizeAccountsISystemResultLocal no desarrollado', response)
        }
    },
};

export default aasinet;
