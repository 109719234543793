<template>
	<div id="materialHistoryStockComponent">
		<button ref="btnShowMaterial" @click="showMaterialLocal" v-show="false">Show Material</button>
		<div v-show="loadingMaterialMovements == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando Titulo">
			</mensagge-loading-component>
		</div>
		<div>
			<h5 class="text-900 fs-0 mb-2">{{ objectMaterial.name }} {{ objectMaterial.width }}x{{
				objectMaterial.height }}</h5>
			<h6 class="mb-0 fs--2 text-500">Codigo: {{ objectMaterial.id }}</h6>
			<ul class="nav nav-tabs" id="myTab" role="tablist">
				<li class="nav-item"><a class="nav-link active" id="stock-tab" data-bs-toggle="tab" href="#tab-stock"
						role="tab" aria-controls="tab-stock" aria-selected="false">Stock</a></li>
				<li class="nav-item"><a class="nav-link" id="history-tab" data-bs-toggle="tab" href="#tab-history"
						role="tab" aria-controls="tab-history" aria-selected="true">Historia</a>
				</li>
			</ul>
			<div class="tab-content border border-top-0 p-3" id="myTabContent">
				<div class="tab-pane fade show active" id="tab-stock" role="tabpanel" aria-labelledby="stock-tab">
					<table class="table table-sm fs--1 table-striped">
						<thead>
							<tr>
								<th class="ps-2">Bodega</th>
								<th>Rack</th>
								<th class="text-center">Lado</th>
								<th class="text-center">Modulo</th>
								<th class="text-end">Cantidad</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(msrsrc, i) in listMaterialStoreRackSideRowColumnsFilter" :key="i">
								<td class="ps-0">
									<div class="ps-2 border-start border-success border-2">
										{{ msrsrc.store.name }}
									</div>
								</td>
								<td>{{ msrsrc.store_rack.name }}</td>
								<td class="text-center">{{ msrsrc.store_rack_side.name }}</td>
								<td class="text-center">
									{{ msrsrc.name }}-{{ msrsrc.store_rack_side_row.name }}
								</td>
								<td class="text-end">{{ methodFormatNumber(msrsrc.pivot.quantity) }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="tab-pane fade" id="tab-history" role="tabpanel" aria-labelledby="history-tab">
					<table class="table table-sm fs--1 table-striped">
						<thead>
							<tr>
								<th class="text-center">ID</th>
								<th>Trasacción</th>
								<th class="text-center">Fecha</th>
								<th class="text-end">Cantidad</th>
								<th class="text-end">Costo</th>
								<th>Relación</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(mm, i) in listMaterialMovementsFilter" :key="i">
								<td class="text-center">{{ mm.id }}</td>
								<td>{{ mm.transaction_type.name }}</td>
								<td class="text-center">{{ mm.transaction_date }}</td>
								<td class="text-end">
									<span :class="(mm.quantity < 0) ? 'text-danger' : ''">
										{{ methodFormatNumber(mm.quantity) }}
									</span>
								</td>
								<td class="text-end">{{ methodFormatNumber(mm.cost) }}</td>
								<td>
									<span v-if="mm.primary_key != null">
										{{ mm.primary_key.name }}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import material from '@/mixins/materials'
import materialMovement from '@/mixins/materials/materialMovement.js'
import materialStoreRackSideRowColumn from '@/mixins/materials/materialStoreRackSideRowColumn.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'MaterialHistoryStockComponent',
	mixins: [mixin, axiosAction, errors, material, materialMovement, materialStoreRackSideRowColumn],
	props: ['materialId'],
	data() {
		return {
		}
	},
	components: {
		'pagination': Pagination,
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
		listMaterialMovementsFilter() {
			return this.listMaterialMovements
		},
		listMaterialStoreRackSideRowColumnsFilter() {
			return this.listMaterialStoreRackSideRowColumns
		}
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'showMaterialResult': this.showMaterialResult(response); break;
				case 'getMaterialMovementsByMaterialIdResult': this.getMaterialMovementsByMaterialIdResult(response); break;
				case 'getMaterialStoreRackSideRowColumnsByMaterialIdResult': this.getMaterialStoreRackSideRowColumnsByMaterialIdResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		getMaterialHistoryStockComponentLocal(page = 1) { },
		initModuleLocal() {
			this.setMaterialHistoryStockComponentInit()
		},
		setMaterialHistoryStockComponentInit() { },
		showMaterialLocal() {
			if (this.materialId > 0) {
				this.showMaterial(this.materialId)
			}
		},
		showMaterialResultLocal(response) {
			if (this.objectMaterial.id > 0) {
				this.getMaterialMovementsByMaterialId(this.objectMaterial.id)
				this.getMaterialStoreRackSideRowColumnsByMaterialId(this.objectMaterial.id)
			}
		},
		getMaterialMovementsByMaterialIdResultLocal(response) { },
		getMaterialStoreRackSideRowColumnsByMaterialIdResultLocal(response) { console.log(this.listMaterialStoreRackSideRowColumns) },
		showMaterialHistoryStockComponentLocal() { },
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>