var departament = {
    data() {
        return {
            listDepartaments: [],
            loadingDepartaments: false,
            paginationDepartaments: [],
            searchDepartaments: '',
            urlsDepartament: {
                get: 'departaments',
                show: 'departament/',
            },
        }
    },
    computed: {
    },
    filters: {
    },
    methods: {
        getDepartaments(page = 1) {
            this.loadingDepartaments = true
            this.listDepartaments = []
            this.paginationDepartaments = []
            let parameters = '?search=' + this.searchDepartaments + '&page=' + page
            let url = this.getApiBackendNew + this.urlsDepartament.get + parameters
            this.axiosActionIS(url, 'GET', 'getDepartamentsResult')
        },
        getDepartamentsResult(response) {
            this.loadingDepartaments = false
            this.listDepartaments = []
            this.paginationDepartaments = []
            if (response.data.success) {
                this.listDepartaments = response.data.data
                this.paginationDepartaments = response.data.paginate
            }
            this.getDepartamentsResultLocal(response)
        },
        getDepartamentsResultLocal(response) {
            console.log('getDepartamentsResultLocal no desarrollado', response)
        },
        showDepartament(id) {
            this.loadingDepartaments = true
            let url = this.getApiBackendNew + this.urlsDepartament.show + id
            this.axiosActionIS(url, 'GET', 'showDepartamentResult')
        },
        showDepartamentResult(response) {
            this.loadingDepartaments = false
            this.showDepartamentResultLocal(response)
        },
        showDepartamentResultLocal(response) {
            console.log('showDepartamentResultLocal no está desarrollado', response)
        },

    },
};

export default departament;