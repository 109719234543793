export default {
    state: {
        nameApp: 'System X',
        version: '1.0.1',
        environments: [
            {
                name: 'development',
                url: 'http://localhost:8080/',
                urlApiBackend: 'http://10.10.38.43:8000/api/',
                urlApiBackendNew: 'http://10.10.38.43:8000/apisystemx/',
                urlResource: 'http://10.10.38.43:8000/resources',
                urlSocketIO: 'http://10.10.38.43:3000/',
                urlWebSocketFacturacionCl: 'ws://localhost:8005',
                urlImagen: 'https://isystem4.aceschile.cl/img/'
            },
            {
                name: 'production',
                url: 'https://systemx.aceschile.cl/',
                urlApiBackend: 'https://api.isystem4.aceschile.cl/api/',
                urlApiBackendNew: 'https://api.isystem4.aceschile.cl/apisystemx/',
                urlResource: 'https://resources.aceschile.cl',
                urlSocketIO: 'http://10.10.38.43:3000/',
                urlWebSocketFacturacionCl: 'ws://localhost:8005',
                urlImagen: 'https://systemx.aceschile.cl/img/'
            },
        ],
        headers: {
            Accept: 'application/json',
            Authorization: '',
        },
    },
    mutations: {
        setHeadersAuthorization(state, token) { state.headers.Authorization = 'Bearer ' + token },
    },
    getters: {
        getEnvironment: state => {
            return process.env.NODE_ENV
        },
        getEnvironmentCurrent: state => {
            let environment = process.env.NODE_ENV
            if (environment == 'development') {
                environment = (localStorage.getItem('environment') != null) ? localStorage.getItem('environment') : environment
            }
            return environment
        },
        getApiBackend: state => {
            let environment = process.env.NODE_ENV
            if (environment == 'development') {
                environment = (localStorage.getItem('environment') != null) ? localStorage.getItem('environment') : environment
            }
            let index = state.environments.map((el) => el.name).indexOf(environment)
            index = (index == -1) ? 0 : index
            return state.environments[index].urlApiBackend
        },
        getApiBackendNew: state => {
            let environment = process.env.NODE_ENV
            if (environment == 'development') {
                environment = (localStorage.getItem('environment') != null) ? localStorage.getItem('environment') : environment
            }
            let index = state.environments.map((el) => el.name).indexOf(environment)
            index = (index == -1) ? 0 : index
            return state.environments[index].urlApiBackendNew
        },
        getHeaders: state => { return state.headers },
        getNameApp: state => { return state.nameApp },
        getVersion: state => { return state.version },
        getUrl: state => {
            let environment = 'development'
            if (process.env.NODE_ENV == environment) {
                environment = (localStorage.getItem('environment') != null) ? localStorage.getItem('environment') : environment
            }
            let index = state.environments.map((el) => el.name).indexOf(environment)
            index = (index == -1) ? 0 : index
            return state.environments[index].url
        },
        getUrlSocketIO: state => {
            let environment = 'development'
            if (process.env.NODE_ENV == environment) {
                environment = (localStorage.getItem('environment') != null) ? localStorage.getItem('environment') : environment
            }
            let index = state.environments.map((el) => el.name).indexOf(environment)
            index = (index == -1) ? 0 : index
            return state.environments[index].urlSocketIO
        },
        getUrlImagen: state => {
            let environment = 'development'
            if (process.env.NODE_ENV == environment) {
                environment = (localStorage.getItem('environment') != null) ? localStorage.getItem('environment') : environment
            }
            let index = state.environments.map((el) => el.name).indexOf(environment)
            index = (index == -1) ? 0 : index
            return state.environments[index].urlImagen
        },
        getUrlResource: state => {
            let environment = process.env.NODE_ENV
            if (environment == 'development') {
                environment = (localStorage.getItem('environment') != null) ? localStorage.getItem('environment') : environment
            }
            let index = state.environments.map((el) => el.name).indexOf(environment)
            index = (index == -1) ? 0 : index
            return state.environments[index].urlResource
        }
    },
    actions: {},
    modules: {},
};