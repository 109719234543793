<template>
	<div id="receiptComponent">
		<button ref="btnShowReceipt" @click="showReceiptLocal()" v-show="false">Show Receipt</button>

		<div class="col mb-2">
			<div class="card">
				<div class="bg-holder bg-card"
					style="background-image:url(/assets/img/icons/spot-illustrations/corner-1.png);">
				</div>
				<div class="card-body position-relative">
					<div class="row justify-content-between align-items-center">
						<div class="col-md">
							<h5 class="mb-2 mb-md-0">
								<span v-show="objectReceipt.id == 0">
									<span v-show="searchingReceipt">Buscando Recepción de Compra Nº {{ this.id }}</span>
									<span v-show="!searchingReceipt">Nueva Recepción de Compra</span>
								</span>
								<span v-show="objectReceipt.id > 0">
									Recepción de Compra Nº
									{{ objectReceipt.id }}
								</span>
							</h5>
						</div>
						<div class="col-md text-end">
							<div>
								Estado:
								<span v-if="objectReceipt.status != null" :class="'text-' + objectReceipt.status.color">
									{{ objectReceipt.status.name }}
								</span>
								<span v-else>
									Nuevo
								</span>
							</div>
							<small>{{ objectReceipt.created_at }}</small>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col mb-2 fs--1">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-1 col-12 mb-2">
							<span class="fw-bold">Ingreso:</span>
						</div>
						<div class="col-md-2 col-12 mb-2 text-end">
							<input class="form-control form-control-sm" v-model="objectReceipt.date_received"
								type="date" :disabled="disabledAllFormReceipt" placeholder="Fecha de Ingreso">
						</div>
						<div class="col-md-1 col-12 mb-2">
							<span class="fw-bold">Proveedor:</span>
						</div>
						<div class="col-md-5 col-12 mb-2 text-end">
							<input class="form-control form-control-sm" v-model="objectReceipt.supplier.name"
								type="text" ref="inputObjectReceiptSupplierName" :disabled="disabledAllFormReceipt"
								placeholder="Ingrese código, nombre o RUT del Proveedor" @focus="$event.target.select()"
								@keyup="clearDataSupplierLocal(['document_number'])"
								@blur="getSupplierLocal(objectReceipt.supplier.name, 'NAME')"
								@keyup.enter="$event.target.blur()">
						</div>
						<div class="col-md-1 col-12 mb-2">
							<span class="fw-bold">RUT:</span>
						</div>
						<div class="col-md-2 col-12 mb-2 text-end">
							<input class="form-control form-control-sm" v-model="objectReceipt.supplier.document_number"
								type="text" :disabled="disabledAllFormReceipt" placeholder="Rut del Proveedor"
								@focus="$event.target.select()"
								@blur="getSupplierLocal(objectReceipt.supplier.document_number, 'DOCUMENTNUMBER')"
								@keyup.enter="$event.target.blur()">
						</div>
						<div class="col-md-1 col-12 mb-2">
							<span class="fw-bold">Documento:</span>
						</div>
						<div class="col-md-3 col-12 mb-2">
							<select class="form-select form-select-sm" v-model="objectReceipt.type_legal_document_id"
								:disabled="disabledAllFormReceipt" ref="selectObjectReceiptLegalDocument">
								<option v-for="(tld, i) in listTypeLegalDocumentsFilter" :key="i" :value="tld.id">{{
									tld.name }}
								</option>
							</select>
						</div>
						<div class="col-md-1 col-12 mb-2">
							<span class="fw-bold">Fecha Doc:</span>
						</div>
						<div class="col-md-2 col-12 mb-2">
							<input class="form-control form-control-sm" v-model="objectReceipt.date_legal_document"
								type="date" :disabled="disabledAllFormReceipt" placeholder="Fecha del documento">
						</div>
						<div class="col-md-1 col-12 mb-2">
							<span class="fw-bold">Numero:</span>
						</div>
						<div class="col-md-2 col-12 mb-2">
							<input class="form-control form-control-sm text-end"
								v-model="objectReceipt.legal_document_number" type="text"
								:disabled="disabledAllFormReceipt" placeholder="Número del Documento">
						</div>
					</div>
					<span class="fw-bold">Nota u observación:</span>
					<input class="form-control form-control-sm" v-model="objectReceipt.note" type="text"
						:disabled="disabledAllFormReceipt" placeholder="Nota u observación">
				</div>
			</div>
		</div>

		<div class="col mb-2 fs--1">
			<div class="card">
				<div class="card-body p-0">
					<div class="table-responsive scrollbar" style="height: 37vh;">
						<table class="table table-sm fs--1"
							:class="(objectReceipt.items.length == 0) ? 'table-borderless' : ''">
							<thead>
								<tr>
									<th style="width: 175px;">Tipo ingreso</th>
									<th>Descripción</th>
									<th class="text-end" style="width: 120px;">Cantidad</th>
									<th class="text-end" style="width: 120px;">Prec. Uni.</th>
									<th class="text-end" style="width: 120px;"><span class="me-1">Total</span></th>
									<th class="text-end" style="width: 40px;">
										<span class="text-primary pointer float-end"
											@click="addItemEmptyObjectReceiptsItems()"
											v-show="disabledAllFormReceipt == false">
											<i class="fas fa-plus-circle"></i>
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(ri, i) in objectReceipt.items" :key="i">
									<td>
										<select v-model="ri.reception_type_id" class="input-clear"
											:disabled="disabledAllFormReceipt">
											<option v-for="(rt, i) in listReceptionTypesFilter" :key="i" :value="rt.id">
												{{ rt.name }}
											</option>
										</select>
									</td>
									<td>
										<input v-model="ri.primary_key_name" placeholder="material" class="input-clear"
											:disabled="ri.reception_type_id == null || disabledAllFormReceipt"
											@blur="searchItemInTableLocal(ri, i, $event.target)"
											@keyup.enter="$event.target.blur()" @keyup="ri.primary_key_id = null"
											:ref="'inputReceiptItemName' + i">
									</td>
									<td>
										<input v-model="ri.quantity" placeholder="cantidad" class="input-clear text-end"
											:disabled="disableInputReceiptItem(ri)">
									</td>
									<td>
										<input v-model="ri.price" placeholder="precio" class="input-clear text-end"
											:disabled="disableInputReceiptItem(ri)"
											@keyup.enter="analizeEnterLocal(ri, 'price')">
									</td>
									<td class="text-end">
										<span class="me-2">
											{{ methodFormatNumber(parseFloat(ri.price.toString().replace(',', '.')) *
												ri.quantity)
											}}
										</span>
									</td>
									<td>
										<span class="visible-hover float-end position-absolute end-0 me-2">
											<span @click="deleteReceptionItemLocal(ri, i)"
												v-show="disabledAllFormReceipt == false" class="pointer"
												title="Eliminar">
												<i class="far fa-trash-alt text-danger"></i>
											</span>
										</span>
									</td>
								</tr>
								<tr v-show="objectReceipt.items.length == 0">
									<td colspan="6" class="text-center pt-5">
										<upload-file-excel-componenet :extensions="['xlsx', 'xls', 'cvs']"
											:headers="[{ field: 'CODE', type: 'integer', index: -1, null: true }]">
										</upload-file-excel-componenet>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="col mb-2 fs--1">
			<div class="card">
				<div class="card-body p-0">
					<div class="table-responsive mb-2">
						<table width="100%">
							<tbody>
								<tr>
									<td class="pt-2 ps-4" colspan="4">
										{{ objectReceipt.items.length }} items
									</td>
									<td class="text-end fw-bold pt-2">TOTAL:</td>
									<td class="text-end fw-bold pt-2" style="width: 120px;">{{
										methodFormatNumber(totalReceipt) }}</td>
									<td style="width: 40px;"></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<button class="btn btn-danger btn-sm" v-show="objectReceipt.status_id == 87">
					Eliminar
				</button>
				<button class="btn btn-danger btn-sm" v-show="objectReceipt.status_id == 88"
					@click="saveReceiptLocal(4)">
					Anular
				</button>
			</div>
			<div class="col-md-6 text-end">
				<button class="btn btn-primary btn-sm" :disabled="disabledBtnSaveReceipt" @click="saveReceiptLocal">
					<span v-show="savingReceipt == false">Guardar</span>
					<span v-show="savingReceipt == true">Guardando...</span>
				</button>
				<button class="btn btn-info btn-sm ms-2" :disabled="disabledBtnSaveReceipt"
					v-show="objectReceipt.status_id == 87" @click="saveReceiptLocal(2)">
					<span v-show="savingReceipt == false">Confirmar</span>
					<span v-show="savingReceipt == true">Confirmando...</span>
				</button>
			</div>
		</div>

		<!-- Button trigger modal -->
		<button type="button" class="btn btn-primary" data-bs-toggle="modal"
			data-bs-target="#listSearchItemInTableModal" ref="btnShowListSearchItemInTableModal" v-show="false">
			Show ListSearchItemInTable
		</button>

		<!-- Modal -->
		<div class="modal fade" id="listSearchItemInTableModal" tabindex="-1"
			aria-labelledby="listSearchItemInTableModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<table class="table table-sm fs--1 table-hover">
							<thead>
								<tr>
									<th class="text-center">ID</th>
									<th class="text-center">Nombre</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, i) in listSearchItemInTableFilter" :key="i"
									@dblclick="selectSearchItemInTable(item)" class="pointer">
									<td class="text-center">{{ item.id }}</td>
									<td>{{ item.name }}</td>
								</tr>
							</tbody>
						</table>
						<div class="text-end">
							<button type="button" class="btn btn-secondary btn-sm fs--1" data-bs-dismiss="modal"
								ref="btnCloseListSearchItemInTableModal">Cerrar</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Button trigger modal -->
		<button type="button" class="btn btn-primary" data-bs-toggle="modal"
			data-bs-target="#supplierListComponentModal" v-show="false" ref="btnShowSupplierListComponentModal">
			Show Supplier List Component Modal
		</button>

		<!-- Modal -->
		<div class="modal fade" id="supplierListComponentModal" tabindex="-1"
			aria-labelledby="supplierListComponentModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header" v-show="false">
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
							ref="btnCloseSupplierListComponentModal"></button>
					</div>
					<supplier-list-component ref="supplierListComponent" :search="searchSupplier"
						@getSuppliersResult="getSuppliersResultLocal"></supplier-list-component>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import receipts from '@/mixins/receipts'
import typeLegalDocument from '@/mixins/legalDocuments/typeLegalDocument.js'

import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import SupplierListComponent from '@/components/SupplierListComponent.vue'
import UploadFileExcelComponenet from '@/components/UploadFileExcelComponenet.vue'

export default {
	name: 'Receipt',
	mixins: [mixin, axiosAction, errors, receipts, typeLegalDocument],
	props: ['id'],
	data() {
		return {
			filterTypeLegalDocumentIds: [33, 39, 52, 914], // Configurable,
			listReceptionTypes: [
				{ id: 1, name: 'Materia Prima', table: 'materials', types: 2 },
				{ id: 2, name: 'Producto', table: 'products', types: 'U' },
				{ id: 3, name: 'Insumos', table: 'materials', types: 1, mandatory: false },
			],
			searchingSupplier: false,
			searchSupplier: '',
			tagAction: null
		}
	},
	components: {
		'mensagge-loading-component': MessageLoadingComponent,
		'supplier-list-component': SupplierListComponent,
		'upload-file-excel-componenet': UploadFileExcelComponenet
	},
	computed: {
		disabledAllFormReceipt() {
			if (this.objectReceipt.status_id != null && this.objectReceipt.status_id != 87) {
				return true
			}
			if (this.searchingSupplier == true) {
				return true
			}
			if (this.searchingReceipt == true) {
				return true
			}
			return false
		},
		disabledBtnSaveReceipt() {
			if (this.disabledAllFormReceipt == true) {
				return true
			}
			if (this.savingReceipt == true) {
				return true
			}
			if (this.validateFormObjectReceipt == false) {
				return true
			}
			return false
		},
		listReceptionTypesFilter() {
			return this.listReceptionTypes
		},
		listTypeLegalDocumentsFilter() {
			let i = -1
			return this.listTypeLegalDocuments.filter(tld => {
				i = this.filterTypeLegalDocumentIds.map(id => parseInt(id)).indexOf(parseInt(tld.id))
				return (i > -1)
			})
		},
		listSearchItemInTableFilter() {
			return this.listSearchItemInTable
		},
		totalReceipt() {
			this.objectReceipt.total = this.objectReceipt.items.reduce((a, b) => {
				return a + (parseFloat(b.price.toString().replace(',', '.')) * b.quantity)
			}, 0)
			return this.objectReceipt.total
		},
		validateFormObjectReceipt() {
			if (this.objectReceipt.date_received == null) {
				return false
			}
			if (this.objectReceipt.supplier_id == null) {
				return false
			}
			if (this.objectReceipt.total == null || this.objectReceipt.total == 0) {
				return false
			}
			if (this.objectReceipt.type_legal_document_id == null) {
				return false
			}
			if (this.objectReceipt.date_legal_document == null) {
				return false
			}
			if (this.objectReceipt.legal_document_number == null) {
				return false
			}
			if (this.objectReceipt.items.length == 0) {
				return false
			}
			return true
		}
	},
	methods: {
		addItemEmptyObjectReceiptsItemsLocal() {
			setTimeout(() => {
				this.$refs['inputReceiptItemName' + (this.objectReceipt.items.length - 1)][0].focus()
			}, 50)
		},
		analizeEnterLocal(ri, field) {
			if (field == 'quantity' || field == 'price') {
				if (ri.quantity != null && ri.price) {
					if (isNaN(ri.quantity) == false && isNaN(ri.price.toString().replace(',', '.')) == false) {
						this.addItemEmptyObjectReceiptsItems()
					}
				}
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAllTypeLegalDocumentsResult': this.getAllTypeLegalDocumentsResult(response); break;
				case 'saveReceiptResult': this.saveReceiptResult(response); break;
				case 'searchItemInTableResult': this.searchItemInTableResult(response); break;
				case 'showReceiptResult': this.showReceiptResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		clearDataSupplierLocal(fields) {
			if (this.objectReceipt.supplier_id > 0) {
				this.objectReceipt.supplier_id = 0
				fields.forEach(f => {
					this.objectReceipt.supplier[f] = ''
				})
			}
		},
		deleteReceptionItemLocal(ri, i) {
			if (this.disabledAllFormReceipt == true) {
				return true
			}
			console.log(ri, i)
			let index = -1
			if (ri.primary_key_id == null) {
				index = i
			} else {
				index = this.objectReceipt.items.map((el) => parseInt(el.id)).indexOf(parseInt(id))
			}
			let receiptItem = this.objectReceipt.items.splice(index, 1)
		},
		disableInputReceiptItem(ri) {
			if (this.disabledAllFormReceipt == true) {
				return true
			}
			let i = this.listReceptionTypes.map(rt => parseInt(rt.id)).indexOf(parseInt(ri.reception_type_id))
			if (i > -1) {
				if (this.listReceptionTypes[i].mandatory != false) {
					return (ri.reception_type_id == null || ri.primary_key_id == null)
				} else {
					return (ri.primary_key_name.trim().length < 5)
				}
			}
			return true
		},
		getAllTypeLegalDocumentsResultLocal(response) { },
		getReceiptLocal(page = 1) { },
		getSupplierLocal(s, type) {
			let b = false
			if (type == 'NAME') {
				if (s != '') {
					if (s.trim().length > 5) {
						b = true
					}
				}
			} else if (type == 'DOCUMENTNUMBER') {
				if (s != '') {
					if (this.checkDigitoRUT(s)) {
						b = true
					} else {
						this.showMsgErrorHandling('Formato no valido', 'El Formato del RUT no es valido', 'warn', 5000, 'top right')
					}
				}
			}
			if (b == true) {
				this.searchingSupplier = true
				this.searchSupplier = s
				setTimeout(() => { this.$refs.supplierListComponent.$refs.btnGetSupplier.click() }, 50)
			}
		},
		getSuppliersResultLocal(r) {
			this.searchingSupplier = false
			if (r == null) {
				this.showMsgErrorHandling('No Encontrado', 'No se encontró ningún proveedor con estos dastos.<br>Puede seguir, se creara el nuevo proveedor', 'info', 5000, 'top right')
			} else if (r == true) {
				this.$refs.btnShowSupplierListComponentModal.click()
				console.log('Encontró mas de un resultado, abrir para seleccionar')
			} else {
				this.objectReceipt.supplier_id = r.id
				this.objectReceipt.supplier.name = r.name
				this.objectReceipt.supplier.document_number = r.document_number
				this.$refs.btnCloseSupplierListComponentModal.click()
				setTimeout(() => { this.$refs.selectObjectReceiptLegalDocument.focus() }, 50)
			}
			this.objectReceipt.supplier.document_number = this.objectReceipt.supplier.document_number.replaceAll('.', '')
		},
		initModuleLocal() {
			this.setReceiptInit()
		},
		saveReceiptLocal(trackingsReceiptId = null) {
			if (this.validateFormObjectReceipt == true) {
				this.saveReceipt(this.objectReceipt, trackingsReceiptId)
			}
		},
		saveReceiptResultLocal(response) { },
		searchItemInTableLocal(ri, i, tag) {
			if (ri.primary_key_id == null) {
				if (ri.primary_key_name.trim().length > 3) {
					this.searchedIndex = i
					let ind = this.listReceptionTypes.map(rt => parseInt(rt.id)).indexOf(ri.reception_type_id)
					if (ind > -1) {
						if (this.listReceptionTypes[ind].table != null) {
							this.tagAction = tag
							this.searchItemInTable(ri.primary_key_name, this.listReceptionTypes[ind].table, this.listReceptionTypes[ind].types)
						}
					}
				}
			}
		},
		searchItemInTableResultLocal(response) {
			if (this.listSearchItemInTable.length == 1) {
				this.objectReceipt.items[this.searchedIndex].primary_key_id = this.listSearchItemInTable[0].id
				this.objectReceipt.items[this.searchedIndex].primary_key_name = this.listSearchItemInTable[0].name
			} else if (this.listSearchItemInTable.length > 0) {
				this.$refs.btnShowListSearchItemInTableModal.click()
			} else {
				let i = this.listReceptionTypes.map(rt => parseInt(rt.id)).indexOf(parseInt(this.objectReceipt.items[this.searchedIndex].reception_type_id))
				if (i > -1) {
					if (this.listReceptionTypes[i].mandatory != false) {
						this.showMsgErrorHandling('NO Encontrado', 'No se encontró el material ingresado', 'warn', 5000, 'top right')
						this.tagAction.focus()
						this.tagAction.select()
					}
				}
			}
		},
		selectSearchItemInTable(item) {
			this.objectReceipt.items[this.searchedIndex].primary_key_id = item.id
			this.objectReceipt.items[this.searchedIndex].primary_key_name = item.name
			this.$refs.btnCloseListSearchItemInTableModal.click()
		},
		showMsgErrorHandlingLocal() {
			this.searchingItemInTable = false
		},
		showReceiptResultLocal(response) {
		},
		setReceiptInit() {
			this.getAllTypeLegalDocuments()
		},
		showReceiptLocal() {
			this.searchingReceipt = false
			this.clearObjectReceipt()
			this.searchedIndex = -1
			console.log(this.id)
			if (this.id > 0) {
				this.showReceipt(this.id)
			} else {
				this.$refs.inputObjectReceiptSupplierName.focus()
			}
		},
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>