<template>
	<div id="aasinetChartOfAccountListComponent">
		<div>
			<mensagge-loading-component class="bg-aasinet" text="Cargando información desde Aasinet!!!"
				:listTasks="listTasks">
			</mensagge-loading-component>
		</div>

		<div class="row">
			<div class="col-md-4">
				<div class="card mb-3" v-show="listPeriods.length > 0">
					<div class="card-body">
						<div class="row justify-content-between align-items-center">
							<div class="text-center">
								<h5 class="mb-2 mb-md-0">Periodo <span class="text-aasinet">{{
									currentPeriodAasinet |
									formatMonthYearOnly }} </span></h5>
							</div>
							<div class="text-end mt-2">
								<button class="btn btn-aasinet btn-sm" @click="closePeriodAasinetLocal()">Cerrar
									Periodo</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-8">
				<div v-show="!gettingAccounts">
					<div class="card mb-1" id="legalDocumentsListCard">
						<div class="card-header">
							<div class="row flex-between-center">
								<div class="col-sm-auto d-flex align-items-center pe-0">
									<div class="row gy-2 gx-3 align-items-center">
										<div class="col-auto">
											<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
												<i class="fas fa-list-ol"></i>
												Plan de Cuentas
											</h5>
										</div>
										<div class="col-auto">
											<div class="input-group">
												<input class="form-control form-control-sm shadow-none search"
													type="search" v-model="searchAccountLocal"
													@keyup.enter="getAasinetChartOfAccountsLocal(1)"
													@focus="$event.target.select()" />
												<div class="input-group-text bg-transparent pointer"
													@click="getAasinetChartOfAccountsLocal(1)">
													<span class="fa fa-search fs--1 text-600"></span>
												</div>
											</div>
										</div>
										<div class="col-auto align-self-end">
											<span v-show="syncingAccounts == true" class="fs--1 float-end">
												<div class="spinner-border spinner-border-sm" role="status">
													<span class="visually-hidden">Loading...</span>
												</div>
												<i>Sinconizando cuentas con las base de datos . . .</i>
											</span>
											<span v-show="syncingAccounts == false" class="pointer"
												title="Sincronizar con base de datos del Sistema"
												@click="synchronizeAccountsISystemLocal">
												<i class="fas fa-sync-alt"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body p-0">
							<div class="scrollbar recent-activity-body-height ps-2">
								<table class="table table-sm table-bordered fs--1 mb-0 overflow-hidden table-hover">
									<thead class="bg-aasinet text-white">
										<tr>
											<th class="text-center">Código</th>
											<th class="text-center">Nombre</th>
											<th class="text-center">
												Tipo Sub-Cuenta
												<span class="pointer" data-bs-toggle="modal"
													data-bs-target="#typeSubAccountModal">
													<i class="fas fa-info-circle ms-2"
														title="Ver tipo de Sub-Cuentas"></i>
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(a, i) in listAccountsFilter" :key="i"
											:class="(a.AcceptEntries._text == 'false') ? 'fw-semi-bold' : ''">
											<td class="text-center">{{ a.Code._text }}</td>
											<td>{{ a.Name._text }}</td>
											<td>
												<span v-show="a.SubAccountType.Code._text != undefined"
													data-bs-toggle="modal" data-bs-target="#subAccountModal"
													@click="selectTypeSubAccount(a.SubAccountType.Code._text)">
													{{ a.SubAccountType.Code._text }} -
													{{ a.SubAccountType.Name._text }}
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal -->
		<div class="modal fade" id="typeSubAccountModal" tabindex="-1" aria-labelledby="typeSubAccountModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="typeSubAccountModalLabel">Tipo de Sub-Cuentas</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<table class="table table-sm table-bordered fs--1 mb-0 overflow-hidden table-hover">
							<thead class="bg-aasinet text-white">
								<tr>
									<th class="text-center">#</th>
									<th class="text-center">Code</th>
									<th class="text-center">Name</th>
									<th class="text-center">EnumType</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(tsa, i) in listTypesSubAccount" :key="i">
									<td class="text-center">{{ i + 1 }}</td>
									<td class="text-center">{{ tsa.Code }}</td>
									<td>{{ tsa.Name }}</td>
									<td class="text-center">{{ tsa.EnumType }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="subAccountModal" tabindex="-1" aria-labelledby="subAccountModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="subAccountModalLabel">Sub Cuentas - {{ subAccountTypeCodeSelected }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<table class="table table-sm table-bordered fs--1 mb-0 overflow-hidden table-hover">
							<thead class="bg-aasinet text-white">
								<tr>
									<th class="text-center">Código</th>
									<th class="text-center">Nombre</th>
									<th class="text-center">
										Tipo Sub-Cuenta
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(sa, i) in listSubAccountsFilter" :key="i">
									<td class="text-center">{{ sa.Code._text }}</td>
									<td>{{ sa.Name._text }}</td>
									<td class="text-center">
										<span v-show="sa.SubAccountTypeCode._text != undefined">
											{{ sa.SubAccountTypeCode._text }}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'

import aasinet from '@/mixins/accounting/aasinet.js'

import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'

export default {
	name: 'AasinetChartOfAccountListComponent',
	mixins: [mixin, axiosAction, errors, aasinet],
	props: [],
	data() {
		return {
			closingPeriod: false,
			searchAccountLocal: '',
			subAccountTypeCodeSelected: '',
			listAccounts: [],
			listSubAccounts: [],
			listFunds: [],
			listFunctions: [],
			gettingAccounts: false,
			typeAction: '',
			loadCounter: 0,
			listTasks: []
		}
	},
	components: {
		'mensagge-loading-component': MessageLoadingComponent
	},
	computed: {
		listAccountsFilter() {
			let position = -1
			return this.listAccounts.filter(a => {
				position = a.Name._text.toUpperCase().search(this.searchAccountLocal.toUpperCase())
				if (position < 0) {
					position = a.Code._text.search(this.searchAccountLocal)
				}
				if (position < 0) {
					if (a.SubAccountType.Code._text != undefined) {
						position = a.SubAccountType.Code._text.search(this.searchAccountLocal)
					}
				}
				return (position > -1)
			})
		},
		listSubAccountsFilter() {
			let index = -1
			return this.listSubAccounts.filter(sa => {
				index = sa.SubAccountTypeCode._text.toUpperCase().search(this.subAccountTypeCodeSelected.toUpperCase())
				return (index > -1)
			})
		},
		listTypesSubAccount() {
			let list = []
			let i = -1
			this.listAccounts.forEach(a => {
				if (a.SubAccountType.Code._text != undefined) {
					i = list.map(tsa => tsa.Code).indexOf(a.SubAccountType.Code._text)
					if (i < 0) {
						list.push({
							Code: a.SubAccountType.Code._text,
							Name: a.SubAccountType.Name._text,
							EnumType: a.SubAccountType.EnumType._text
						})
					}
				}
			})
			return list
		},
	},
	methods: {
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'synchronizeAccountsISystemResult': this.synchronizeAccountsISystemResult(response); break;
				case 'getTokenAasinetResult': this.getTokenAasinetResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		closePeriodAasinetLocal() {
			if (confirm('Despues de esto no se podrá continuar trabajando en este periodo\n¿Deseas continuar?')) {
				this.typeAction = 'EXTERNALACCOUNTINGPOSTPERIOD'
				this.getTokenAasinetLocal()
			}
		},
		getAasinetChartOfAccountsLocal() { },
		getAccountsAassinetLocal() {
			this.gettingAccounts = true
			this.typeAction = 'GETACCOUNTS'
			this.getTokenAasinetLocal()
		},
		getTokenAasinetLocal() {
			this.listTasks = []
			this.listTasks.push({ action: { text: 'Obteniendo Token...' }, success: { text: 'Token Obtenido!' }, error: { text: '' }, currentStatus: 1 })
			this.getTokenAasinet()
		},
		getTokenAasinetResultLocal(response) {
			this.listTasks[0].currentStatus = 2
			if (this.typeAction == 'GETACCOUNTS') {
				this.loadCounter = 0
				this.listTasks.push({ action: { text: 'Obteniendo Plan de Cuentas...' }, success: { text: 'Plan de cuentas recuperado!' }, error: { text: '' }, currentStatus: 1 })
				this.listTasks.push({ action: { text: 'Obteniendo las Sub Cuentas...' }, success: { text: 'Sub Cuentas recuperado!' }, error: { text: '' }, currentStatus: 1 })
				this.listTasks.push({ action: { text: 'Buscando datos del periodo...' }, success: { text: 'Información del Periodo recuperada!' }, error: { text: '' }, currentStatus: 1 })
				this.listTasks.push({ action: { text: 'Buscando departamentos...' }, success: { text: 'Departamentos recuperados!' }, error: { text: '' }, currentStatus: 1 })
				this.listTasks.push({ action: { text: 'Buscando fondos...' }, success: { text: 'Fondos recuperados!' }, error: { text: '' }, currentStatus: 1 })
				setTimeout(() => { this.payloadService(0) }, 10)
				setTimeout(() => { this.payloadService(1) }, 10)
				setTimeout(() => { this.payloadService(3) }, 10)
				setTimeout(() => { this.payloadService(5) }, 10)
				setTimeout(() => { this.payloadService(7) }, 10)
			} else if (this.typeAction == 'EXTERNALACCOUNTINGPOSTPERIOD') {
				this.listTasks.push({ action: { text: 'Cerrando Periodo...' }, success: { text: 'Periodo cerrado!' }, error: { text: '' }, currentStatus: 1 })
				let component = this.listComponents[2]
				let arrDate = this.currentPeriodAasinet.split('-')
				let month = (arrDate[1].length == 1) ? '0' + arrDate[1] : arrDate[1]
				component.xmlParameters = this.replaceKey(component.xmlParameters, 'Content', month + arrDate[0])
				console.log(component)
				setTimeout(() => { this.payloadServiceLoad(component) }, 10)
			}
		},
		initModuleLocal() {
			this.setListAasinetChartOfAccountsComponentInit()
		},
		selectTypeSubAccount(subAccountTypeCode) {
			this.subAccountTypeCodeSelected = subAccountTypeCode
		},
		sendPayloadServiceResultLocal(response) {
			console.log('sendPayloadServiceResultLocal', response)
			if (response.ChartOfAccountsResult != undefined) {
				this.listTasks[1].currentStatus = 2
				this.listAccounts = response.ChartOfAccountsResult.ArrayOfAccount.Account
			} else if (response.SubAccountsSyncListResult != undefined) {
				this.listTasks[2].currentStatus = 2
				this.listSubAccounts = response.SubAccountsSyncListResult.ArrayOfSubAccountSync.SubAccountSync
				this.gettingAccounts = false
				setTimeout(() => { this.loadCounter++ }, 2000)
			} else if (response.PeriodStatusInfoResult != undefined) {
				this.listTasks[3].currentStatus = 2
				this.listPeriods = response.PeriodStatusInfoResult.ArrayOfPeriodStatusInfo.PeriodStatusInfo
				this.gettingAccounts = false
				setTimeout(() => { this.loadCounter++ }, 2000)
			} else if (response.ChartOfFundsResult != undefined) {
				this.listTasks[4].currentStatus = 2
				this.listFunds = response.ChartOfFundsResult.ArrayOfFund.Fund
				setTimeout(() => { this.loadCounter++ }, 2000)
			} else if (response.ChartOfFunctionsResult != undefined) {
				this.listTasks[5].currentStatus = 2
				this.listFunctions = response.ChartOfFunctionsResult.ArrayOfFunction.Function
				setTimeout(() => { this.loadCounter++ }, 2000)
			} else if (response.ExternalAccountingPostPeriodResult != undefined) {
				this.listTasks[1].currentStatus = 2
				setTimeout(() => { this.loadCounter++ }, 2000)
				this.showMsgErrorHandling('Cerrado', 'Se cerró con exito el Periodo', 'info', 5000, 'top right')
				//this.payloadService(3)
			} else {
				console.log(response)
			}
		},
		setListAasinetChartOfAccountsComponentInit() {
			this.getAccountsAassinetLocal()
		},
		synchronizeAccountsISystemLocal() {
			this.synchronizeAccountsISystem({
				accounts: this.listAccounts,
				subAccounts: this.listSubAccounts,
				funds: this.listFunds,
				functions: this.listFunctions
			})
		},
		synchronizeAccountsISystemResultLocal(response) {
			if (response.data.success == true) {
				this.showMsgErrorHandling('Terminado', 'Sincronización Exitosa' + this.listMessageError(), 'info', 5000, 'top right')
				console.log('Sincronización exitosa', response.data.data)
			}
		},
		showMsgErrorHandlingLocal(error) {
			this.syncingAccounts = false
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>

<style lang="scss"></style>