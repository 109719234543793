<template>
	<div id="juournalComponent">
		<button @click="showJournalLocal" ref="btnShowJournal" v-show="false">Show Journal {{ id }}</button>
		<div class="col mb-2">
			<div class="card">
				<div class="bg-holder bg-card"
					style="background-image:url(/assets/img/icons/spot-illustrations/corner-1.png);">
				</div>
				<div class="card-body position-relative">
					<div class="row justify-content-between align-items-center">
						<div class="col-md">
							<h5 class="mb-2 mb-md-0">
								<span v-show="objectJournal.id == 0">
									<span v-show="loadingJournal">Buscando Asiento Nº {{ this.id }}</span>
									<span v-show="!loadingJournal">Nuevo Asiento</span>
								</span>
								<span v-show="objectJournal.id > 0">Asiento Nº {{ objectJournal.id }}</span>
							</h5>
						</div>
						<div class="col-auto">
							<span v-show="objectJournal.JournalCode != null" class="fs--1 text-aasinet">
								{{ objectJournal.JournalTypeCode }}-{{ objectJournal.JournalCode }}
								| <i>{{ objectJournal.JournalGuid }}</i>
							</span>
							<span @click="showTypeJournalEntriesModal()" data-bs-toggle="modal"
								data-bs-target="#typeJournalEntriesModal" class="pointer"
								v-show="objectJournal.id == 0 && !loadingJournal">
								<i class="fas fa-external-link-alt"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col mb-2">
			<div class="card">

				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<div class="mb-1">
								<label class="form-label">Descripción</label>
								<input class="form-control form-control-sm" type="text" ref="inputJournalDescription"
									v-model="objectJournal.Description" :disabled="disabledModification" />
							</div>
						</div>
						<div class="col-md-3">
							<div class="mb-1">
								<label class="form-label">Fecha</label>
								<input class="form-control form-control-sm" type="date"
									v-model="objectJournal.JournalDate" :disabled="disabledModification" />
							</div>
						</div>
						<div class="col-md-2">
							<div class="mb-1">
								<label class="form-label">Pediodo</label>
								<input class="form-control form-control-sm" type="text" v-model="postedPeriodLocal"
									:disabled="true" />
							</div>
						</div>
						<div class="col-md-1 text-end">
							<div class="mb-1">
								<span class="pointer text-info" title="Nuevo Item" data-bs-toggle="offcanvas"
									data-bs-target="#newJourlaItemOffCanvasRight" ref="btnNewJourlaItemOffCanvasRight"
									@click="showNewJourlaItemOffCanvasRight">
									<i class="far fa-plus-square"></i>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col mb-2">
			<div class="card">
				<div class="card-body p-0">
					<div class="table-responsive scrollbar" style="height: 55vh;">
						<table class="table table-sm fs--1">
							<thead>
								<tr>
									<th class="text-center py-2 px-0">Item</th>
									<th class="py-2 px-0">Cuenta</th>
									<th class="py-2 px-0">Sub-Cuenta</th>
									<th class="text-center py-2 px-0">Fondo</th>
									<th class="text-center py-2 px-0">Departamento</th>
									<th class="text-center py-2 px-0">Rest</th>
									<th class="text-center py-2 px-0">Valor</th>
									<th class="py-2 px-0">Description</th>
									<th class="text-center py-2 px-0">Memo D/C</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(ij, i) in objectJournal.items" :key="i"
									:class="(ij.entity_value < 0) ? 'border-bottom' : ''">
									<td class="text-center px-0">{{ i + 1 }}</td>
									<td class="px-0">
										<strong>{{ ij.account_Code }}</strong>
										-
										{{ (ij.account != null) ? ij.account.Name : '' }}
									</td>
									<td class="px-0">
										<strong>{{ ij.sub_account_Code }}</strong>
										-
										{{ (ij.sub_account != null) ? ij.sub_account.Name : '' }}
									</td>
									<td class="text-center px-0">{{ ij.fund_Code }}</td>
									<td class="text-center px-0">{{ ij.function_Code }}</td>
									<td class="text-center px-0">{{ ij.restriction_Code }}</td>
									<td class="text-end pe-2" :class="(ij.entity_value < 0) ? 'text-danger' : ''">
										{{ methodFormatNumber(ij.entity_value) }}
									</td>
									<td class="px-0">
										<input type="text" v-model="ij.description" class="fs--2 text-muted"
											style="width: 100%; border: none; background: transparent;">
									</td>
									<td class="text-center px-0">
										<input type="checkbox" checked="checked" v-model="ij.send_memo"
											:disabled="disabledModification">
									</td>
									<td class="text-end">
										<span title="Quitar Item" v-show="objectJournal.id == 0"
											@click="removeItemLocal(i)">
											<i class="far fa-trash-alt text-danger pointer"></i>
										</span>
									</td>
								</tr>
								<tr v-show="searchingJournalEntries == true || loadingJournal == true">
									<td colspan="10" class="text-center">
										Buscando información . . .
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-3">
			<div class="card-body">
				<div class="row justify-content-between align-items-center">
					<div class="col-md">
						<span class="me-1">Filas: <strong>{{ objectJournal.items.length }}</strong></span> |
						<span class="me-1">Debitos: <strong>{{ methodFormatNumber(totalDebit) }}</strong></span>
						<span class="me-1">Creditos: <strong class="text-danger">{{ methodFormatNumber(totalCredit)
								}}</strong></span>
						<span class="ms-5 text-danger" v-show="totalDebit != (totalCredit * -1)">Error!!! existe una
							diferencia de <strong>{{ methodFormatNumber(parseFloat(totalCredit) +
								parseFloat(totalDebit)) }}</strong></span>
					</div>
					<div class="col-auto">
						<button v-show="objectJournal.id == 0" class="btn btn-falcon-default btn-sm me-2"
							:disabled="disableBtnSaveJournal" @click="saveJournalLocal">
							<span v-show="savingJournal">Guardardando</span>
							<span v-show="!savingJournal">Guardar</span>
						</button>
						<button v-show="objectJournal.id > 0" class="btn btn-aasinet btn-sm me-2"
							:disabled="disableBtnSaveJournal || this.objectJournal.JournalGuid != null"
							@click="sendJournalToAasinetLocal">
							<span v-show="migrateJournalToAssinet == true">
								Valcando a Aasinet
							</span>
							<span v-show="migrateJournalToAssinet == false">
								Volcar AasiNET
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="text-end fs--1 pe-2">
			<span class="text-primary pointer" @click="listJournalLocal">Volver a la lista</span>
		</div>

		<!-- Modal Type Journals Entries-->
		<div class="modal fade" id="typeJournalEntriesModal" tabindex="-1"
			aria-labelledby="typeJournalEntriesModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="typeJournalEntriesModalLabel">Tipos de Asientos Contables</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
							ref="btnCloseTypeJournalEntriesModal"></button>
					</div>
					<div class="modal-body">
						<select class="form-select form-select-sm mb-3" v-model="typeJournalEntrie"
							:disabled="searchingJournalEntries">
							<option v-for="(tjs, i) in listTypeJournalEntries" :key="i" :value="tjs.code">{{
								tjs.description }}</option>
						</select>
						<div>
							<div v-show="typeJournalEntrie == 'SUBSCRIPTIONPAYMENT'">
								<div class="row">
									<div class="col-md-6">
										<div class="mb-1">
											<label class="form-label">Desde</label>
											<input class="form-control form-control-sm" type="date"
												v-model="dateFromLegalDocuments" />
										</div>
									</div>
									<div class="col-md-6">
										<div class="mb-1">
											<label class="form-label">Hasta</label>
											<input class="form-control form-control-sm" type="date"
												v-model="dateToLegalDocuments" />
										</div>
									</div>
								</div>
							</div>
							<div v-show="typeJournalEntrie == 'IMPORTACCOUNTINGENTRIESFROMGENESYS'">
								<div class="row">
									<div class="col-md-6">
										<div class="mb-1">
											<label class="form-label">Desde</label>
											<input class="form-control form-control-sm" type="date"
												v-model="dateFromLegalDocuments" />
										</div>
									</div>
									<div class="col-md-6">
										<div class="mb-1">
											<label class="form-label">Hasta</label>
											<input class="form-control form-control-sm" type="date"
												v-model="dateToLegalDocuments" />
										</div>
									</div>
								</div>
								<div>
									<table class="table table-sm fs--1">
										<thead>
											<tr>
												<th class="text-center">Asiento</th>
												<th>Usuario Genesys</th>
												<th class="text-center">Agrupado</th>
												<th class="text-center">Fecha de Asiento</th>
												<th class="text-center">Fecha de Imputación</th>
												<th class="text-end"></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(a, i) in listAsientosFilter" :key="i">
												<td class="text-center">{{ a.numasie }}</td>
												<td>{{ a.usuario }}</td>
												<td class="text-center">{{ (a.agrupado == '0') ? 'NO' : 'SI' }}</td>
												<td class="text-center">{{ a.fecasie }}</td>
												<td class="text-center">{{ a.fecimpu }}</td>
												<td class="text-end">
													<span class="pointer" title="Importar Asiento"
														@click="importarAsiento(i)">
														<i class="fas fa-file-import"></i>
													</span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Cerrar</button>
						<button type="button" class="btn btn-sm btn-primary" @click="searchTypeJournalEntriesLocal"
							:disabled="searchingJournalEntries">
							<span v-show="searchingJournalEntries">Buscando...</span>
							<span v-show="!searchingJournalEntries">Buscar</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="offcanvas offcanvas-end" id="newJourlaItemOffCanvasRight" tabindex="-1"
			ref="newJourlaItemOffCanvasRight" aria-labelledby="newJourlaItemOffCanvasRightLabel">
			<div class="offcanvas-header">
				<h5 id="newJourlaItemOffCanvasRightLabel">Nuevo Item</h5>
				<button class="btn-close text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
					ref="btnCloseNewJourlaItemOffCanvasRight"></button>
			</div>
			<div class="offcanvas-body">
				<div>
					<div class="row mb-2">
						<div class="col-4 text-end">Cuenta:</div>
						<div class="col-8">
							<div class="input-group input-group-sm">
								<input class="form-control mb-1" @keyup="seachDataJournalItemLocal('ACCOUNT')"
									ref="inputAccountCode" @keyup.enter="analyseFieldNewJournalItem('ACCOUNT')"
									v-model="objectJournalItem.account_Code" @focus="$event.target.select()">
							</div>
							<div class="fs--1 text-end">
								<strong v-if="objectJournalItem.account != null">
									{{ objectJournalItem.account.Name }}
								</strong>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 text-end">Sub-Cuenta:</div>
					<div class="col-8">
						<input class="form-control form-control-sm mb-1"
							@keyup="seachDataJournalItemLocal('SUBACCOUNT')" ref="inputSubAccountCode"
							v-model="objectJournalItem.sub_account_Code"
							@keyup.enter="analyseFieldNewJournalItem('SUBACCOUNT')"
							:disabled="listSubAccountsFilter.length == 0" @focus="$event.target.select()">
						<div class="fs--1 text-end">
							<strong v-if="objectJournalItem.sub_account != null"
								:class="(objectJournalItem.sub_account.Code == 0) ? 'text-danger' : ''">
								{{ objectJournalItem.sub_account.Name }}
							</strong>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 text-end">Fondo:</div>
					<div class="col-8">
						<input class="form-control form-control-sm" ref="inputFundCode"
							v-model="objectJournalItem.fund_Code" @keyup.enter="analyseFieldNewJournalItem('FUND')"
							@focus="$event.target.select()">
						<div class="fs--1 text-end">
							<strong v-if="objectJournalItem.fund != null">
								{{ objectJournalItem.fund.Name }}
							</strong>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 text-end">Depto:</div>
					<div class="col-8">
						<input class="form-control form-control-sm" ref="inputFunctionCode"
							v-model="objectJournalItem.function_Code"
							@keyup.enter="analyseFieldNewJournalItem('FUNCTION')" @focus="$event.target.select()">
						<div class="fs--1 text-end">
							<strong v-if="objectJournalItem.function != null">
								{{ objectJournalItem.function.Name }}
							</strong>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 text-end">Restricción:</div>
					<div class="col-8">
						<input class="form-control form-control-sm" ref="inputRestrictionCode"
							v-model="objectJournalItem.restriction_Code"
							@keyup.enter="analyseFieldNewJournalItem('RESTRICTION')" @focus="$event.target.select()"
							:disabled="listRestrictionsFilter.length == 1">
						<div class="fs--1 text-end">
							<strong v-if="objectJournalItem.restriction != null">
								{{ objectJournalItem.restriction.Name }}
							</strong>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 text-end">Valor:</div>
					<div class="col-8">
						<div class="input-group input-group-sm">
							<span class="input-group-text bg-aces text-white">$</span>
							<input class="form-control" ref="inputEntityValue" v-model="objectJournalItem.entity_value"
								@keyup.enter="analyseFieldNewJournalItem('ENTITYVALUE')"
								@focus="$event.target.select()" />
							<span class="input-group-text bg-aces text-white">
								<span
									v-if="!isNaN(this.objectJournalItem.entity_value) && this.objectJournalItem.entity_value != 0 && this.objectJournalItem.entity_value != ''">
									<strong v-if="this.objectJournalItem.entity_value < 0" class="text-danger"
										title="Credito">
										C
									</strong>
									<strong v-else class="text-blank" title="Debito">D</strong>
								</span>
							</span>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 text-end">Descripción:</div>
					<div class="col-87">
						<input class="form-control form-control-sm" ref="inputDescription"
							v-model="objectJournalItem.description"
							@keyup.enter="analyseFieldNewJournalItem('DESCRIPTION')" @focus="$event.target.select()">
					</div>
				</div>
				<hr>
				<div class="mb-2 row justify-content-between align-items-center">
					<div class="col-md">
						<div class="form-check">
							<input class="form-check-input" id="flexCheckIndeterminate" type="checkbox" value=""
								v-model="continueAddingItem" />
							<label class="form-check-label" for="flexCheckIndeterminate">Seguir Agregando</label>
						</div>
					</div>
					<div class="col-auto">
						<button class="btn btn-sm btn-primary" @click="addNewJournalItemLocal"
							:disabled="!validateFormObjectJournalItem">Agregar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import journal from '@/mixins/accounting/journal.js'
import account from '@/mixins/accounting/account.js'
import aasinet from '@/mixins/accounting/aasinet.js'
import genesys from '@/mixins/genesys'
import asiento from '@/mixins/genesys/asiento.js'

export default {
	name: 'JuournalComponent',
	mixins: [mixin, axiosAction, errors, journal, account, aasinet, genesys, asiento],
	props: ['id'],
	data() {
		return {
			typeJournalEntrie: 'SUBSCRIPTIONPAYMENT',
			listTypeJournalEntries: [
				{ code: 'SUBSCRIPTIONPAYMENT', description: 'Pagos de Suscripciones' },
				{ code: 'IMPORTACCOUNTINGENTRIESFROMGENESYS', description: 'Traer Asientos desde Genesys' },
			],
			typeActionAasinet: '',
			fundCodeDefault: 10,
			functionCodeDefault: 131111,
			continueAddingItem: false,
			migrateJournalToAssinet: false
		}
	},
	components: {
	},
	computed: {
		disabledModification() {
			if (this.objectJournal.id > 0) {
				return true
			}
			if (this.loadingJournal == true) {
				return true
			}
			if (this.searchingJournalEntries == true) {
				return true
			}
			return false
		},
		disableBtnSaveJournal() {
			if (this.objectJournal.Description.trim() == '') {
				return true
			}
			if (this.objectJournal.Description.trim().length < 5) {
				return true
			}
			if (this.totalDebit == 0) {
				return true
			}
			if (this.totalDebit != (this.totalCredit * -1)) {
				return true
			}
			if (this.savingJournal == true) {
				return true
			}
			if (this.migrateJournalToAssinet == true) {
				return true
			}
			return false
		},
		postedPeriodLocal() {
			if (this.objectJournal.id > 0) {
				return this.objectJournal.PostedPeriod
			}
			return this.postedPeriod
		},
		listAsientosFilter() {
			return this.listAsientos
		},
		listRestrictionsFilter() {
			let list = []
			if (this.objectJournalItem.account != null && this.objectJournalItem.account.Code != 0) {
				list = this.objectJournalItem.account.restrictions
			}
			return list
		},
		listSubAccountsFilter() {
			let list = []
			if (this.objectJournalItem.account != null && this.objectJournalItem.account.AcceptEntries == 1 && this.objectJournalItem.account.type_sub_account_Code != null) {
				list = this.listSubAccounts.filter(sa => {
					return (sa.type_sub_account_Code == this.objectJournalItem.account.type_sub_account_Code)
				})
			}
			return list
		},
		totalCredit() {
			let total = 0
			total = this.objectJournal.items.reduce((a, b) => {
				let c = 0
				if (b.entity_value <= 0) {
					c = parseFloat(b.entity_value)
				}
				return a + c
			}, 0);
			return total.toFixed(0)
		},
		totalDebit() {
			let total = 0
			total = this.objectJournal.items.reduce((a, b) => {
				let c = 0
				if (b.entity_value > 0) {
					c = parseFloat(b.entity_value)
				}
				return a + c
			}, 0);
			return total.toFixed(0)
		},
		validateFormObjectJournalItem() {
			if (this.objectJournalItem.account == null || this.objectJournalItem.account.Code == 0) {
				return false
			} else if ((this.objectJournalItem.sub_account == null || this.objectJournalItem.account.Code == 0) && this.objectJournalItem.account.type_sub_account_Code != null) {
				console.log('this.objectJournalItem.sub_account')
				return false
			} else if (this.objectJournalItem.fund == null || this.objectJournalItem.fund.Code == 0) {
				console.log('this.objectJournalItem.fund')
				return false
			} else if (this.objectJournalItem.function == null || this.objectJournalItem.function.Code == 0) {
				console.log('this.objectJournalItem.function')
				return false
			} else if (this.objectJournalItem.restriction == null || this.objectJournalItem.restriction.Code == 0) {
				console.log('this.objectJournalItem.restriction')
				return false
			} else if (isNaN(this.objectJournalItem.entity_value)) {
				console.log('this.objectJournalItem.entity_value')
				return false
			} else if (this.objectJournalItem.entity_value == 0) {
				console.log('this.objectJournalItem.entity_value')
				return false
			} else if (this.objectJournalItem.description.trim().length < 5) {
				console.log('this.objectJournalItem.description')
				return false
			} else {
				return true
			}
		}
	},
	methods: {
		addNewJournalItemLocal() {
			if (this.validateFormObjectJournalItem) {
				this.addObjectJournalItems(this.objectJournalItem)
				this.clearObjectJournalItem()
				if (!this.continueAddingItem) {
					this.$refs.btnCloseNewJourlaItemOffCanvasRight.click()
					setTimeout(() => { this.$refs.inputJournalDescription.focus() }, 400)
				} else {
					this.$refs.inputAccountCode.focus()
				}
			}
		},
		analyseFieldNewJournalItem(field) {
			switch (field) {
				case 'ACCOUNT':
					if (this.objectJournalItem.account.Code == 0) {
						this.showMsgErrorHandling('Advertencia!!!', 'La cuenta Nº ' + this.objectJournalItem.account_Code + ' no existe', 'warn', 5000, 'top right')
						this.$refs.inputAccountCode.focus()
					} else if (this.objectJournalItem.account.AcceptEntries == 0) {
						this.showMsgErrorHandling('Advertencia!!!', 'La cuenta Nº ' + this.objectJournalItem.account_Code + ' no Acepta entradas', 'warn', 5000, 'top right')
						this.$refs.inputAccountCode.focus()
					} else {
						if (this.objectJournalItem.account.restrictions.length == 1) {
							this.objectJournalItem.restriction_Code = this.objectJournalItem.account.restrictions[0].Code
							this.objectJournalItem.restriction = this.objectJournalItem.account.restrictions[0]
						} else {
							this.objectJournalItem.restriction_Code = ''
							this.objectJournalItem.restriction = null
						}
						if (this.objectJournalItem.account.type_sub_account_Code == null) {
							setTimeout(() => { this.$refs.inputFundCode.focus() }, 10)
						} else {
							setTimeout(() => { this.$refs.inputSubAccountCode.focus() }, 10)
						}

					}
					break
				case 'SUBACCOUNT':
					this.objectJournalItem.sub_account = this.getAccountByAccountCodeAndSubAccountCode(this.objectJournalItem.account_Code, this.objectJournalItem.sub_account_Code)
					if (this.objectJournalItem.sub_account.Code == 0) {
						this.showMsgErrorHandling('Advertencia!!!', 'La Sub-cuenta Nº ' + this.objectJournalItem.sub_account_Code + ' no existe', 'warn', 5000, 'top right')
						this.$refs.inputSubAccountCode.select()
					} else {
						this.objectJournalItem.fund_Code = this.fundCodeDefault
						this.objectJournalItem.fund = this.getFundByCode(this.objectJournalItem.fund_Code)
						setTimeout(() => { this.$refs.inputFundCode.focus() }, 10)
					}
					break
				case 'FUND':
					this.objectJournalItem.fund = this.getFundByCode(this.objectJournalItem.fund_Code)
					if (this.objectJournalItem.fund.Code == 0) {
						this.showMsgErrorHandling('Advertencia!!!', 'El fondo ' + this.objectJournalItem.fund_Code + ' no existe', 'warn', 5000, 'top right')
						this.$refs.inputFundCode.select()
					} else {
						this.objectJournalItem.function_Code = this.functionCodeDefault
						this.objectJournalItem.function = this.getFunctionByCode(this.objectJournalItem.function_Code)
						setTimeout(() => { this.$refs.inputFunctionCode.focus() }, 10)
					}
					break
				case 'FUNCTION':
					this.objectJournalItem.function = this.getFunctionByCode(this.objectJournalItem.function_Code)
					if (this.objectJournalItem.function.Code == 0) {
						this.showMsgErrorHandling('Advertencia!!!', 'El departamento Nº ' + this.objectJournalItem.function_Code + ' no existe', 'warn', 5000, 'top right')
						this.$refs.inputFunctionCode.select()
					} else if (this.objectJournalItem.function.AcceptEntries == 0) {
						this.showMsgErrorHandling('Advertencia!!!', 'El departamento Nº ' + this.objectJournalItem.function_Code + ' no Acepta entradas', 'warn', 5000, 'top right')
						this.$refs.inputFunctionCode.focus()
					} else {
						if (this.objectJournalItem.account != null && this.objectJournalItem.account.restrictions.length == 1) {
							setTimeout(() => { this.$refs.inputEntityValue.focus() }, 10)
						} else {
							setTimeout(() => { this.$refs.inputRestrictionCode.focus() }, 10)
						}
					}
					break
				case 'RESTRICTION':
					let i = this.objectJournalItem.account.restrictions.map(r => r.Code).indexOf(this.objectJournalItem.restriction_Code)
					if (i > -1) {
						this.objectJournalItem.restriction = this.objectJournalItem.account.restrictions[i]
						setTimeout(() => { this.$refs.inputEntityValue.focus() }, 10)
					} else {
						this.showMsgErrorHandling('Advertencia!!!', 'La restricción ' + this.objectJournalItem.restriction_Code + ' no es validad para esta Cuenta', 'warn', 5000, 'top right')
						setTimeout(() => { this.$refs.inputRestrictionCode.select() }, 10)
					}
					break
				case 'ENTITYVALUE':
					if (!isNaN(this.objectJournalItem.entity_value)) {
						setTimeout(() => { this.$refs.inputDescription.focus() }, 10)
					} else {
						this.showMsgErrorHandling('Advertencia!!!', 'El valor tiene que se numerico ', 'warn', 5000, 'top right')
						setTimeout(() => { this.$refs.inputEntityValue.select() }, 10)
					}
					break
				case 'DESCRIPTION':
					if (this.validateFormObjectJournalItem) {
						this.addNewJournalItemLocal()
					} else {
						this.showMsgErrorHandling('Advertencia!!!', 'La descripción es muy corta', 'warn', 5000, 'top right')
						setTimeout(() => { this.$refs.inputDescription.select() }, 10)
					}
					break
				default:
					break
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getAccountsResult': this.getAccountsResult(response); break;
				case 'getAsientoResult': this.getAsientoResult(response); break;
				case 'getFundsResult': this.getFundsResult(response); break;
				case 'getFunctionsResult': this.getFunctionsResult(response); break;
				case 'getItemAsientoResult': this.getItemAsientoResult(response); break;
				case 'getSubAccountsResult': this.getSubAccountsResult(response); break;
				case 'getTokenAasinetResult': this.getTokenAasinetResult(response); break;
				case 'saveJournalResult': this.saveJournalResult(response); break;
				case 'showJournalResult': this.showJournalResult(response); break;
				case 'unrecordedForJournalEntrySubscriptionResult': this.unrecordedForJournalEntrySubscriptionResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		importarAsiento(index) {
			this.clearObjectJournal()
			this.objectJournal.PostedPeriod = this.listAsientos[index].fecimpu.split("-")[1] + this.listAsientos[index].fecimpu.split("-")[0]
			this.objectJournal.JournalDate = this.listAsientos[index].fecimpu
			this.objectJournal.Description = this.listAsientos[index].observasi
			this.objectJournal.items = []
			this.getItemAsiento(this.listAsientos[index].numasie)
		},
		initModuleLocal() {
			this.setJuournalComponentInit()
		},
		clearJuournalComponent() {
			console.log(this.currentDate())
			this.clearObjectJournal()
			this.objectJournal.JournalDate = this.currentDate()
		},
		getAccountsResultLocal(response) { },
		getAsientoLocal() {
			this.searchingJournalEntries = true
			this.getAsiento(this.dateFromLegalDocuments, this.dateToLegalDocuments)
		},
		getAsientoResultLocal(response) {
			this.searchingJournalEntries = false
		},
		getFundsResultLocal(response) { },
		getFunctionsResultLocal(response) { },
		getItemAsientoResultLocal(response) {
			Object.values(response.data.items).forEach(val => {
				this.clearObjectJournalItem()
				this.objectJournalItem.account_Code = val.ctaconcod
				this.objectJournalItem.account = this.getAccountByCode(val.ctaconcod)
				this.objectJournalItem.sub_account_Code = val.dsactacte
				this.objectJournalItem.sub_account = this.getAccountByAccountCodeAndSubAccountCode(val.ctaconcod, val.dsactacte)
				this.objectJournalItem.fund_Code = 10
				this.objectJournalItem.function_Code = this.getFunctionCodeByDsadpto(val.dsadpto)
				this.objectJournalItem.restriction_Code = this.getAccountRestrictionCode(val.ctaconcod)
				this.objectJournalItem.entity_value = (val.tipoimp == 'D') ? parseInt(val.imputacion) : parseInt(val.imputacion) * -1
				this.objectJournalItem.description = val.observ
				this.objectJournalItem.memo = false
				this.objectJournalItem.send_memo = false
				this.addObjectJournalItems(this.objectJournalItem)
			})
			if (this.objectJournal.items.length > 0) {
				this.$refs.btnCloseTypeJournalEntriesModal.click()
			}
		},
		getSubAccountsResultLocal(response) { },
		getTokenAasinetLocal() {
			this.getTokenAasinet()
		},
		getTokenAasinetResultLocal(response) {
			if (this.token != '') {
				if (this.typeActionAasinet == 'SENDJOURNALAASINET') {
					let componentName = 'ExternalMultipleAccounting'
					let ind1 = this.getIndexComponent(componentName)
					if (ind1 > -1) {
						let component = this.listComponents[ind1]
						let journal = this.createXMLJournal(this.objectJournal.ItemId, this.objectJournal)
						component.xmlParameters = this.replaceKey(component.xmlParameters, 'ExternalSystem', this.externalSystem)
						component.xmlContent = this.replaceKey(component.xmlContent, 'Content', journal)
						console.log(component)
						setTimeout(() => {
							if (confirm('Para avanzar Aceptar, se recomienda ver la consola')) {
								this.payloadServiceLoad(component)
							} else {
								this.migrateJournalToAssinet = false
							}
						}, 500)
					} else {
						console.log('No está el componente ' + componentName)
					}
				} else if (this.typeActionAasinet == 'PERIODSTATUSINFO') {
					setTimeout(() => { this.payloadService(3) }, 10)
				} else {

				}
			} else {
				this.showMsgErrorHandling('PROBLEMA', 'No se ha podido obtener un token desde AasiNET', 'warn', 5000, 'top right')
			}
		},
		listJournalLocal() {
			this.clearObjectJournal()
			this.$emit('listJournal')
		},
		removeItemLocal(i) {
			this.objectJournal.items.splice(i, 1)
		},
		saveJournalLocal() {
			this.objectJournal.items.forEach((item, i) => {
				item.item_id = i + 1
			})
			this.objectJournal.PostedPeriod = this.postedPeriod
			this.saveJournal(this.objectJournal)
		},
		saveJournalResultLocal(response) {
			console.log(response)
			if (response.data.success == true) {
				if (response.data.message == 'CREATED') {
					this.showMsgErrorHandling('Guardado', 'Se guardo con exito al asiento con el Nº ' + this.objectJournal.id, 'success', 5000, 'top right')
				} else if (response.data.message == 'UPDATED') {
					this.showMsgErrorHandling('Actualizado', 'Se ha actualizado con exito el Asiento Nº ' + this.objectJournal.id, 'info', 5000, 'top right')
				}
			}
		},
		seachDataJournalItemLocal(field) {
			switch (field) {
				case 'ACCOUNT':
					this.objectJournalItem.sub_account_Code = ''
					this.objectJournalItem.sub_account = null
					this.objectJournalItem.restriction_Code = ''
					this.objectJournalItem.restriction = null
					this.objectJournalItem.account = this.getAccountByCode(this.objectJournalItem.account_Code)
					break;
				case 'SUBACCOUNT':
					this.objectJournalItem.sub_account = null
					break;
				default:
					break;
			}
		},
		searchTypeJournalEntriesLocal() {
			if (this.typeJournalEntrie == 'SUBSCRIPTIONPAYMENT') {
				this.unrecordedForJournalEntrySubscriptionLocal()
			} else if (this.typeJournalEntrie == 'IMPORTACCOUNTINGENTRIESFROMGENESYS') {
				this.getAsientoLocal()
			}
		},
		sendJournalToAasinetLocal() {
			this.migrateJournalToAssinet = true
			this.typeActionAasinet = 'SENDJOURNALAASINET'
			this.getTokenAasinetLocal()
		},
		sendPayloadServiceResultLocal(response) {
			console.log('sendPayloadServiceResultLocal', response)
			if (response.ExternalMultipleAccountingResult != undefined) {
				this.migrateJournalToAssinet = true
				this.objectJournal.JournalCode = response.ExternalMultipleAccountingResult.ArrayOfJournalInfo.JournalInfo.JournalCode._text
				this.objectJournal.JournalGuid = response.ExternalMultipleAccountingResult.ArrayOfJournalInfo.JournalInfo.JournalGuid._text
				this.objectJournal.JournalTypeCode = response.ExternalMultipleAccountingResult.ArrayOfJournalInfo.JournalInfo.JournalTypeCode._text
				console.log('sendPayloadServiceResultLocal', this.objectJournal)
				this.saveJournal(this.objectJournal)
			} else if (response.PeriodStatusInfoResult != undefined) {
				this.listPeriods = response.PeriodStatusInfoResult.ArrayOfPeriodStatusInfo.PeriodStatusInfo
				this.gettingAccounts = false
			} else {
				console.log(response)
			}

		},
		setJuournalComponentInit() {
			this.clearJuournalComponent()
			this.typeActionAasinet = 'PERIODSTATUSINFO'
			this.getTokenAasinetLocal()
			this.getAccounts()
			this.getFunds()
			this.getFunctions()
			this.getSubAccounts()
		},
		showJournalLocal() {
			this.clearObjectJournal()
			if (this.id > 0) {
				this.showJournal(this.id)
			}
		},
		showJournalResultLocal(response) { },
		showMsgErrorHandlingLocal(err) {
			this.savingJournal = false
		},
		showNewJourlaItemOffCanvasRight() {
			this.clearObjectJournalItem()
			setTimeout(() => { this.$refs.inputAccountCode.focus() }, 400)
		},
		showTypeJournalEntriesModal() { },
		unrecordedForJournalEntrySubscriptionLocal() {
			this.unrecordedForJournalEntrySubscription()
		},
		unrecordedForJournalEntrySubscriptionResultLocal(response) {
			if (response.data.success == true) {
				if (this.objectJournal.items.length > 0) {
					this.objectJournal.items.forEach(i => {
						if (i.account == null) {
							i.account = this.getAccountByCode(i.account_Code)
						}
					});
					this.$refs.btnCloseTypeJournalEntriesModal.click()
				}
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>