<template>
	<div id="inventoryTemplateListComponent">
		<div v-show="loadingListInventoryTemplates == true">
			<mensagge-loading-component class="bg-aasinet text-white" text="Buscando planillas...">
			</mensagge-loading-component>
		</div>
		<div class="row flex-between-center mb-2">
			<div class="col-sm-auto d-flex align-items-center pe-0">
				<div class="row gy-2 gx-3 align-items-center">
					<div class="col-auto">
						<h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Planilla</h5>
					</div>
					<div class="col-auto">
						<div class="input-group">
							<input class="form-control form-control-sm shadow-none search" type="search" placeholder=""
								aria-label="search" ref="searchInventoryTemplates" @focus="$event.target.select()"
								v-model="searchInventoryTemplate" @keyup.enter="getInventoryTemplatesLocal(1)">
							<div class="input-group-text bg-transparent pointer" @click="getInventoryTemplatesLocal(1)">
								<span class="fa fa-search fs--1 text-600"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-8 col-sm-auto ms-auto text-end ps-0">
				<div id="orders-actions">
					<button class="btn btn-falcon-default btn-sm me-2" type="button"
						@click="showInventoryTemplateLocal(0)" ref="btnShowInventoryTemplate" data-bs-toggle="modal"
						data-bs-target="#inventoryTemplateModal">
						<span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span>
						<span class="d-none d-sm-inline-block ms-1">Nuevo</span>
					</button>
				</div>
			</div>
		</div>
		<div class="table-responsive scrollbar">
			<table class="table table-sm table-striped fs--1 mb-0 overflow-hidden table-hover">
				<thead class="bg-200">
					<tr>
						<th class="text-900 text-center">ID</th>
						<th class="text-900">Nombre</th>
						<th class="text-900 text-center">Año</th>
						<th class="text-900 text-center">Creación</th>
						<th class="text-900">Ubicación</th>
						<th class="text-900 text-end pe-4">Asignado a</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(it, i) in listInventoryTemplatesFilter" :key="i">
						<td class="align-middle border-bottom border-muted text-center">
							<router-link :to="'/inventory/' + inventoryId + '/template/' + it.id">
								<span class="text-primary">#</span>{{ it.id }}
							</router-link>
						</td>
						<td class="align-middle border-bottom border-muted">{{ it.name }}</td>
						<td class="align-middle border-bottom border-muted text-center">{{
							it.inventory.year }}</td>
						<td class="align-middle border-bottom border-muted text-center">{{
							it.created_at }}</td>
						<td class="align-middle border-bottom border-muted">
							<span @click="showStoreRackSideLocal(it)" class="pointer" data-bs-toggle="modal"
								data-bs-target="#storeRackSideModalBackdrop">
								<i class="fas fa-table mx-1" title="Ver modulos" :style="'color: ' + colorSide(it)"></i>
							</span>
							<span>{{ nameSide(it) }}</span>
						</td>
						<td class="align-middle border-bottom border-muted">
							<div class="avatar-group justify-content-end">
								<div v-for="(e, ind) in it.employees" :key="ind"
									class="avatar avatar-xl border border-3 border-200 rounded-circle"
									:title="e.name + ' ' + e.surname">
									<img v-if="(e.img != null)" class="rounded-circle" :src="getUrlImagen + e.img"
										alt="">
									<div v-else class="avatar-name rounded-circle" :class="bgColorLocal[ind]">
										<span>
											{{ e.name.substring(0, 1).toUpperCase() +
												e.surname.substring(0, 1).toUpperCase() }}
										</span>
									</div>
								</div>
								<div class="avatar avatar-xl border border-3 border-200 rounded-circle"
									title="Asignar a trabajador" v-show="it.employees.length < 4"
									@click="showEmployeeComponentLocal(it)" data-bs-toggle="modal"
									data-bs-target="#employeeListModal">
									<div class="avatar-name rounded-circle bg-200 text-muted pointer"><span>+</span>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="d-flex align-items-center justify-content-center mt-2">
			<pagination :pagination="paginationListInventoryTemplates" @getAllList="getInventoryTemplatesLocal">
			</pagination>
		</div>

		<!-- Modal -->
		<div class="modal fade" id="storeRackSideModalBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
			tabindex="-1" aria-labelledby="storeRackSideModalBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-body">
						<div>
							<button type="button" class="btn-close float-end" data-bs-dismiss="modal"
								aria-label="Close"></button>
						</div>
						<store-rack-side-component ref="storeRackSideComponent" :id="storeRackSideIdSelected"
							:storeRackSideRowColumnIds="storeRackSideRowColumnIds"></store-rack-side-component>
						<div class="text-end mt-4">
							<button type="button" class="btn btn-sm btn-secondary"
								data-bs-dismiss="modal">Cerrar</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal InventoryTemplateModal-->
		<div class="modal fade" id="inventoryTemplateModal" data-bs-backdrop="static" data-bs-keyboard="false"
			tabindex="-1" aria-labelledby="inventoryTemplateModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-show="false"
						ref="btnCloseInventoryTemplateModal"></button>
					<inventory-template-wizard-component :inventoryId="inventoryId"
						ref="inventoryTemplateWizardComponent" :showBtnClose="true"
						@closeInventoryTemplate="closeInventoryTemplateLocal"
						@saveInventoryTemplateResult="saveInventoryTemplateResultLocal"></inventory-template-wizard-component>
				</div>
			</div>
		</div>

		<!-- Modal InventoryTemplateModal-->
		<div class="modal fade" id="employeeListModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
			aria-labelledby="employeeListModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-show="false"
						ref="btnCloseEmployeeListModal"></button>
					<employee-list-component ref="employeeListComponent" :showNewEmployee="false"
						:actionDblClickParent="true" :disabledInactive="true" :showBtnClose="true"
						@actionDblClick="actionDblClickEmployeeLocal" :hideInactive="true"
						@closeEmployeeList="closeEmployeeListLocal"></employee-list-component>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import axiosAction from '@/mixins/axiosAction.js'
import errors from '@/mixins/errors.js'
import inventoryTemplate from '@/mixins/inventories/inventoryTemplate.js'
import inventoryTemplateEmployee from '@/mixins/inventories/inventoryTemplateEmployee.js'

import Pagination from '@/components/Pagination.vue'
import MessageLoadingComponent from '@/components/MessageLoadingComponent.vue'
import StoreRackSideComponent from '@/components/StoreRackSideComponent.vue'
import InventoryTemplateWizardComponent from '@/components/InventoryTemplateWizardComponent.vue'
import EmployeeListComponent from '@/components/EmployeeListComponent.vue'

export default {
	name: 'InventoryTemplateListComponent',
	mixins: [mixin, axiosAction, errors, inventoryTemplate, inventoryTemplateEmployee],
	props: ['inventoryId'],
	data() {
		return {
			bgColorLocal: ['bg-primary', 'bg-info', 'bg-warning', 'bg-success'],
			storeRackSideIdSelected: 0,
			storeRackSideRowColumnIds: [],
			inventoryTemplateIdSelected: -1
		}
	},
	components: {
		'mensagge-loading-component': MessageLoadingComponent,
		'pagination': Pagination,
		'store-rack-side-component': StoreRackSideComponent,
		'inventory-template-wizard-component': InventoryTemplateWizardComponent,
		'employee-list-component': EmployeeListComponent
	},
	computed: {
		listInventoryTemplatesFilter() {
			return this.listInventoryTemplates
		}
	},
	methods: {
		actionDblClickEmployeeLocal(e) {
			if (this.inventoryTemplateIdSelected > -1) {
				let i = this.listInventoryTemplates.map(it => parseInt(it.id)).indexOf(parseInt(this.inventoryTemplateIdSelected))
				if (i > -1) {
					console.log(this.inventoryTemplateIdSelected, this.listInventoryTemplates[i], e.id)
					let a = this.listInventoryTemplates[i].employees.map(em => parseInt(em.id)).indexOf(parseInt(e.id))
					if (a == -1) {
						let ite = {
							exists: 0,
							id: 0,
							inventory_template_id: this.listInventoryTemplates[i].id,
							employee_id: e.id,
							count_x: this.listInventoryTemplates[i].employees.length + 1
						}
						this.saveInventoryTemplateEmployee(ite)
					} else {
						this.showMsgErrorHandling('NO realizado', e.name + ' ' + e.surname + ' ya está asignado a la planilla', 'warn', 5000, 'top right')
					}
				}
			}
		},
		axiosResultLocal(responseLocal, response) {
			switch (responseLocal) {
				case 'getInventoryTemplatesResult': this.getInventoryTemplatesResult(response); break;
				case 'saveInventoryTemplateEmployeeResult': this.saveInventoryTemplateEmployeeResult(response); break;
				default: console.log('Acción no determinada despues de la consulta (' + responseLocal + ')', this.$options.name); break;
			}
		},
		closeEmployeeListLocal() {
			this.$refs.btnCloseEmployeeListModal.click()
		},
		closeInventoryTemplateLocal() {
			this.$refs.btnCloseInventoryTemplateModal.click()
		},
		colorSide(item) {
			return (item.store_rack_side_row_columns.length > 0) ? item.store_rack_side_row_columns[0].store_rack_side.color : ''
		},
		getInventoryTemplatesLocal(page = 1) {
			this.getInventoryTemplates(this.inventoryId, page)
		},
		getInventoryTemplatesResultLocal(response) { console.log(response) },
		initModuleLocal() {
			this.setInventoryTemplateListInit()
		},
		nameSide(item) {
			let name = 'Sin determinar'
			if (item.store_rack_side_row_columns.length > 0) {
				name = item.store_rack_side_row_columns[0].store.name
				name += ' ' + item.store_rack_side_row_columns[0].store_rack.name
				name += ' ' + item.store_rack_side_row_columns[0].store_rack_side.name
			}
			return name
		},
		saveInventoryTemplateResultLocal(data) {
			this.addItemListInventoryTemplates(data)
			this.closeInventoryTemplateLocal()
		},
		saveInventoryTemplateEmployeeResultLocal(response) {
			if (response.data.success == true) {
				let i = this.listInventoryTemplates.map(it => parseInt(it.id)).indexOf(parseInt(this.inventoryTemplateIdSelected))
				if (i > -1) {
					this.listInventoryTemplates[i].employees.push(response.data.data.employee)
					this.closeEmployeeListLocal()
				}
			}
		},
		setInventoryTemplateListInit() {
			this.getInventoryTemplatesLocal()
		},
		showEmployeeComponentLocal(it) {
			this.inventoryTemplateIdSelected = it.id
			this.$refs.employeeListComponent.$refs.btnGetEmployees.click()
		},
		showInventoryTemplateLocal() {
			this.$refs.inventoryTemplateWizardComponent.$refs.btnClearInventoryTemplateWizardComponent.click()
		},
		showStoreRackSideLocal(it) {
			this.storeRackSideRowColumnIds = []
			if (it.store_rack_side_row_columns.length > 0) {
				this.storeRackSideIdSelected = parseInt(it.store_rack_side_row_columns[0]['store_rack_side_id'])
				it.store_rack_side_row_columns.forEach(c => {
					this.storeRackSideRowColumnIds.push(c.id)
				});

				setTimeout(() => { this.$refs.storeRackSideComponent.$refs.btnShowStoreRackSide.click() }, 50)
			}
		}
	},
	mounted() {
		this.initModule(this.$options.name)
	},
}
</script>